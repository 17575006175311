.edit-card {
    border: 0rem;
    background: $bg-White;
    box-shadow: $shadow-2;
    border-radius: 1.2rem;
    -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    -ms-border-radius: 1.2rem;
    -o-border-radius: 1.2rem;
    margin-bottom: 1.6rem;

    .card-head {
        padding: 2rem;
        border-bottom: 0.1rem solid $border-neutral-solid;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .head-title {
            color: $color_1;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_1;
            letter-spacing: -0.014rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 0.8rem;
            margin-bottom: 0rem;

            span.badge {
                display: flex;
                padding: 0rem 0.8rem;
                justify-content: center;
                align-items: center;
                border-radius: 9.6rem;
                -webkit-border-radius: 9.6rem;
                -moz-border-radius: 9.6rem;
                -ms-border-radius: 9.6rem;
                -o-border-radius: 9.6rem;
                text-align: center;
                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                width: fit-content;
            }

            span.badge.bg-alpha-25 {
                background: $Neutral-Alpha-25;
                color: $text-loud-900;

                svg {
                    circle {
                        fill: $text-loud-900;
                    }
                }
            }
        }

        .form-check-label {
            color: $color_3;

            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
        }
    }

    .order-logo-head {
        padding: 2.4rem 2rem;
        background: $color_9;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 1.6rem;

            .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 3.2rem;
                height: 3.2rem;
                background: $color_5;
                border-radius: 99rem;
                -webkit-border-radius: 99rem;
                -moz-border-radius: 99rem;
                -ms-border-radius: 99rem;
                -o-border-radius: 99rem;
            }
        }

        .title {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
            margin-bottom: 0rem;

            span {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: $lh_1;
                display: block;
            }
        }
    }

    .card-body {
        padding: 2rem;

        .form-check {
            .form-check-input {
                filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
                background: transparent;

                &[type="checkbox"] {
                    border-radius: 0.3rem;
                    -webkit-border-radius: 0.3rem;
                    -moz-border-radius: 0.3rem;
                    -ms-border-radius: 0.3rem;
                    -o-border-radius: 0.3rem;
                }

                &:checked[type="radio"],
                &:checked[type="checkbox"] {
                    background-image: $checkedImage2;
                    background-color: #06273B;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: contain;
                    box-shadow: 0px 1px 2px 0px rgba(1, 9, 14, 0.50), 0px 0px 0px 1px #06273B;
                }
            }
        }
    }

    .form-title {
        color: $color_3;

        font-size: $text_1;
        font-style: $font_style_1;
        font-weight: 600;
        line-height: 133.333%;
        letter-spacing: 0.048rem;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
    }

    .uploader {
        background: $color_8;
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        row-gap: 1.6rem;
        align-items: flex-start;
        justify-content: flex-start;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        margin-bottom: 1.6rem;

        .preview-content {
            background: $bg_linear2;
            box-shadow: $shadow-2;
            padding: 2rem;
            border-radius: 1.2rem;
            width: 100%;
            height: 18rem;

            img {
                object-fit: contain;
                border-radius: 1.2rem;
                width: 100%;
                height: 100%;
            }

            .preview-before {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 0.3rem dashed #dfe2e5;
                border-radius: 1rem;
                -webkit-border-radius: 1rem;
                -moz-border-radius: 1rem;
                -ms-border-radius: 1rem;
                -o-border-radius: 1rem;

                h3 {
                    display: flex;
                    flex-direction: column;
                    font-size: 4rem;
                    color: #dfe2e5;
                    align-items: center;
                }
            }
        }
    }

    .form-group {
        margin-bottom: 1.6rem;

        .form-label {
            color: $color_2;

            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 142.857%;
            margin-bottom: 0.4rem;

            span {
                color: $color_3;

                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 142.857%;
                letter-spacing: -0.014rem;
            }
        }

        .form-check-label {
            @extend .form-label;

            span {
                color: $color_3;

                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 142.857%;
                display: block;
                letter-spacing: -0.014rem;
            }
        }

        .form-text {
            color: $color_3;

            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 133.333%;
            margin: 0.6rem 0rem 0rem;
        }

        ng-select {

            .ng-select-container {

                .ng-value-container {

                    .ng-value {
                        white-space: break-spaces;
                        display: flex;
                        align-items: stretch;
                    }
                }

            }

            &.text-nowrap{

                .ng-select-container {

                    .ng-value-container {
    
                        .ng-value {
                            white-space: nowrap;
                            display: block;
                        }
                    }
    
                }
            }
        }

        &.remaining {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            column-gap: 0.8rem;

            h6 {
                color: $color_3;

                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: $lh_1;
                margin-bottom: 0.7rem;
            }

            span.badge {
                display: flex;
                padding: 0.2rem 0.8rem;
                justify-content: center;
                align-items: center;
                border-radius: 9.6rem;
                -webkit-border-radius: 9.6rem;
                -moz-border-radius: 9.6rem;
                -ms-border-radius: 9.6rem;
                -o-border-radius: 9.6rem;
                text-align: center;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                width: fit-content;
            }

            span.badge.bg-alpha-25 {
                background: $Neutral-Alpha-25;
                color: $text-loud-900;
            }
        }
    }

    .NgxEditor__MenuItem {
        .NgxEditor__MenuItem--Active {
            background-color: #e3f4ff;
            color: $color_1;
        }
    }

    .auths {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0.8rem;

        .form-check {
            padding: 0rem;
            margin-bottom: 0rem;

            .form-check-label {
                color: $color_3;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                margin-bottom: 0rem;
                display: flex;
                align-items: center;
                justify-content: center;
                column-gap: 0.4rem;
                border-radius: 96px;
                border: 0.1rem solid $Neutral-Solid-50;
                background: $bg-White;
                padding: 0.6rem 1.2rem;
                cursor: pointer;

                .form-check-input {
                    float: right;
                    margin: 0rem;
                    width: 2rem;
                    height: 2rem;
                    display: none;

                    &:checked[type="radio"] {
                        background-image: $checkedImage;
                        display: block;
                    }
                }

                &.active {
                    background: $Neutral-Solid-50;
                }
            }
        }

        &.checkout-auth {
            flex-direction: column;
            align-items: flex-start;
            gap: 1.2rem;

            .form-group.form-check {
                width: 100%;

                .form-check-label {
                    justify-content: flex-start;
                    background: $color_5;
                    box-shadow: $shadow-4;
                    border-radius: 0.6rem;
                    -webkit-border-radius: 0.6rem;
                    -moz-border-radius: 0.6rem;
                    -ms-border-radius: 0.6rem;
                    -o-border-radius: 0.6rem;
                    padding: 0.8rem 0.8rem 0.8rem 1rem;
                    color: $color_2;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 142.857%;

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.8rem;
                        color: $color_2;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_bold;
                        line-height: 142.857%;
                    }

                    .form-check-input {
                        display: block;
                    }

                    .balance {
                        background: $color_9;
                        padding: 0.4rem 0.6rem;
                        border-radius: 0.4rem;
                        -webkit-border-radius: 0.4rem;
                        -moz-border-radius: 0.4rem;
                        -ms-border-radius: 0.4rem;
                        -o-border-radius: 0.4rem;
                        color: $color_6;
                    }
                }
            }
        }
    }

    .auth-qr {
        padding: 2rem 0rem;

        .auth-img {
            background: $Neutral-Solid-50;
            padding: 1rem;
            border-radius: 1.6rem;
            width: fit-content;

            img {
                width: 10rem;
                border-radius: 0.8rem;
                -webkit-border-radius: 0.8rem;
                -moz-border-radius: 0.8rem;
                -ms-border-radius: 0.8rem;
                -o-border-radius: 0.8rem;
            }
        }
    }

    .order-add {
        margin-bottom: 1.6rem;

        p {
            color: $color_6;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
            margin-bottom: 0rem;
        }

        span {
            color: $color_3;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: 600;
            line-height: 133.333%;
            letter-spacing: 0.048rem;
            text-transform: uppercase;
            margin-bottom: 0.4rem;
            display: block;
        }
    }

    .payment-detail {
        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 0.8rem;

            span {
                font-weight: $font_weight_normal;
                margin-bottom: 0rem;
            }
        }
    }

    .desc-btns {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 4rem;
        margin-bottom: 2.4rem;

        .btns {
            @extend .desc-btns;
            column-gap: 1.2rem;
            margin-bottom: 0rem;

            .btn-icon {
                padding: 0rem;
                outline: 0;
                box-shadow: none;
            }

            .NgxEditor__Popup {
                border: 0rem;
                background: #ffffff;
                box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
                border-radius: 1.2rem;
                -webkit-border-radius: 1.2rem;
                -moz-border-radius: 1.2rem;
                -ms-border-radius: 1.2rem;
                -o-border-radius: 1.2rem;
                padding: 2rem;

                label {
                    color: $color_2;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 142.857%;
                    margin-bottom: 0.4rem;
                }

                input{
                    @extend .form-control;
                    border: 0rem;
                }

                button{
                    @extend .btn;
                    @extend .btn-primary;
                }
            }
        }
    }

    .reports {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        column-gap: 1.6rem;

        .report-dlt {
            border: 0.1rem solid $border-neutral-solid;
            background: $bg-linear3;
            backdrop-filter: blur(0.25rem);
            padding: 1.2rem;
            border-radius: 99rem;
            -webkit-border-radius: 99rem;
            -moz-border-radius: 99rem;
            -ms-border-radius: 99rem;
            -o-border-radius: 99rem;
        }

        .report-content {
            .title {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 171.429%;
                letter-spacing: -0.014rem;
                margin-bottom: 0.4rem;
                padding: 0rem;
                outline: 0;
                box-shadow: none;
            }

            .desc {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
            }
        }
    }

    .details-card-heading {
        color: $color_6;
        font-size: $text_3;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 150%;
        letter-spacing: -0.032rem;
        margin-bottom: 1.6rem;
    }

    .details-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 0.6rem 1.6rem;


        span,
        p {
            color: $color_3;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
        }

        p {
            margin-top: 0.6rem;
            color: $color_6;
        }
    }

    .employee-detail {

        .card-title {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_3;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 150%;
            letter-spacing: -0.032rem;
            margin-bottom: 2.4rem;
        }

        .titles {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            margin-bottom: 4.8rem;
            position: relative;

            .name-icon {
                background: $bg_linear2;
                box-shadow: $shadow-2;
                width: 6.4rem;
                height: 6.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.8rem;
                color: $color_3;
                text-align: center;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: $lh_1;
                border-radius: 99rem;
                -webkit-border-radius: 99rem;
                -moz-border-radius: 99rem;
                -ms-border-radius: 99rem;
                -o-border-radius: 99rem;
                transform: translate(0%, -50%);
            }

            p {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -18%;
                left: 0%;
                width: 100%;
                height: 0.2rem;
                background: url("../../images/dash-border.svg");
            }

        }

        .activity {
            display: flex;
            align-items: center;
            justify-content: space-around;
            gap: 1.6rem;

            p {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;

                span {
                    color: $color_3;
                    display: block;
                }
            }
        }

        .manageTab {
            ul {
                justify-content: center;
                flex-wrap: nowrap;

                li {
                    width: 100%;

                    button {
                        width: 100%;
                    }
                }
            }
        }

        .employee-detail-content {

            p {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0.6rem;
            }

            .content-text {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
            }

            ul {

                li {
                    display: flex;
                    align-items: flex-start;
                    justify-content: space-between;
                    gap: 2rem;
                }
            }
        }
    }

    &.report-card {
        box-shadow: $shadow-2;
        transition: all 0.2s ease-in;

        &:hover {
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
                0px 1px 2px 0px rgba(164, 172, 185, 0.16),
                0px 12px 24px -12px rgba(54, 57, 74, 0.24);
            backdrop-filter: blur(6px);
        }
    }
}

.card.balance-card {
    background: $color_11;
    border: 0rem;
    border-radius: 1.6rem;
    -webkit-border-radius: 1.6rem;
    -moz-border-radius: 1.6rem;
    -ms-border-radius: 1.6rem;
    -o-border-radius: 1.6rem;

    .card-body {
        padding: 2rem;

        .card-title {
            color: $color_12;
            font-family: $font-inter;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_1;
            letter-spacing: 0.024rem;
            text-transform: uppercase;
            margin-bottom: 1.6rem;
        }

        .desc {
            color: $color_5;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 130%;
            letter-spacing: -0.028rem;
            margin-bottom: 0.8rem;

            &.muted {
                color: #AFB2BB;
                font-size: $text_1;
                letter-spacing: -0.012rem;
            }
        }

        .btn.btn-secondary {
            margin: 1.6rem 0rem;
        }

        .radialProgressBar {
            .overlay {
                background: $color_11;
            }
        }

        .remain {
            color: $color_5;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
            letter-spacing: -0.014rem;
        }

        hr {
            opacity: 0.2;
            background: #DFE1E7;
            margin: 1.6rem 0rem;
            border: 0rem;
            height: 0.1rem;
        }

        .accordion {

            .accordion-item {
                background: transparent;
                border: 0rem;
            }

            .accordion-header {
                .accordion-button {
                    color: $color_5;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.012rem;
                    background: transparent;
                    border: 0rem;
                    padding: 0rem;

                    &::after {
                        background-image: url("../../images/chevron-down.svg");
                        background-origin: border-box;
                        width: 1.6rem;
                        height: 1.6rem;
                        background-position: center;
                        background-size: auto;
                    }
                }
            }
        }
    }
}

.product-list-card.card-body {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    .card-img {
        width: 9.6rem;
        height: 9.6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color_8;
        border-radius: 0.8rem;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        -ms-border-radius: 0.8rem;
        -o-border-radius: 0.8rem;

        img {
            max-width: 5.7rem;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        .title,
        .price {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
            letter-spacing: -0.028rem;
            margin-bottom: 0.8rem;

            &.sale {
                color: #710E21;
            }

            &.main-price {
                text-decoration: line-through;
                color: $color_4;
                font-size: $text_1;
            }
        }

        .btns-card {
            gap: 1.6rem;

            hr {
                border-right: 0.1rem solid #d9d9d9;
                height: 1.2rem;
                opacity: 1;
                border-top: 0rem;
            }

            .btn {
                color: $color_3;
            }
        }

        .order-detail-list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.6rem;

            li {
                @extend .order-detail-list;
                column-gap: 0.6rem;
                position: relative;

                p {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.024rem;
                    margin-bottom: 0rem;
                }

                span {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.024rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 0.6rem;
                    position: relative;

                    .color-dark {
                        width: 1.6rem;
                        height: 1.6rem;
                        background: $color_6;
                        border-radius: 0.4rem;
                        -webkit-border-radius: 0.4rem;
                    }
                }
            }

            li+li::before {
                float: left;
                content: "";
                width: 0.1rem;
                height: 1.1rem;
                background: $border-neutral-solid;
                left: -12%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                -webkit-transform: translateY(-50%);
                -moz-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                -o-transform: translateY(-50%);
            }
        }
    }
}

.product-card {
    border: 0rem;
    border-radius: 0.8rem;
    -webkit-border-radius: 0.8rem;
    -moz-border-radius: 0.8rem;
    -ms-border-radius: 0.8rem;
    -o-border-radius: 0.8rem;

    .card-img {
        background: $color_8;
        border-radius: 1.6rem;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;
        height: 31rem;
        padding: 1rem;
        position: relative;
        margin-bottom: 1.6rem;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            mix-blend-mode: multiply;
        }

        .badges {
            position: absolute;
            top: 4%;
            left: 4%;

            .badge {
                padding: 0.2rem 0.8rem;
            }
        }

        .dlt-top {
            position: absolute;
            top: 4%;
            right: 4%;
        }
    }

    .card-content {
        .title {
            color: $color_6;
            font-size: $text_2;
            font-family: $font-inter;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_1;
            margin-bottom: 0.4rem;
            text-align: start;
        }

        .brand {
            color: $color_3;
            font-size: $text_1;
            font-family: $font-inter;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_1;
            letter-spacing: 0.024rem;
            text-transform: uppercase;
            margin-bottom: 0.8rem;
        }

        .price {
            color: $color_6;
            font-size: $text_2;
            font-family: $font-inter;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_1;
            margin-bottom: 0rem;

            &.sale {
                color: #710E21;
            }

            &.main-price {
                text-decoration: line-through;
                color: $color_4;
                font-size: $text_1;
            }
        }
    }

    &.category-card {

        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            opacity: 0;
            background: $color_5;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.16), 0px 24px 32px -12px rgba(54, 57, 74, 0.24);
            backdrop-filter: blur(0.5rem);
            border-radius: 2rem;
            width: 5%;
            height: 5%;
            z-index: -1;
            transition: all 0.4s linear;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -ms-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            transform: translate(-50%, -50%);
            -webkit-transform: translate(-50%, -50%);
            -moz-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            -o-transform: translate(-50%, -50%);
        }

        .cart-category {
            opacity: 0;
            margin-top: 1.6rem;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;
            transform: translateY(50%);
            -webkit-transform: translateY(50%);
            -moz-transform: translateY(50%);
            -ms-transform: translateY(50%);
            -o-transform: translateY(50%);
            z-index: -2;
            position: relative;
        }

        &:hover {

            &::before {
                width: 102%;
                height: 102%;
                opacity: 1;
                transition: all 0.4s linear;
                -webkit-transition: all 0.4s linear;
                -moz-transition: all 0.4s linear;
                -ms-transition: all 0.4s linear;
                -o-transition: all 0.4s linear;
                z-index: 0;
            }

            .card-content {
                z-index: 1;
            }

            .cart-category {
                opacity: 1;
                transform: translateY(0%);
                -webkit-transform: translateY(0%);
                -moz-transform: translateY(0%);
                -ms-transform: translateY(0%);
                -o-transform: translateY(0%);
                z-index: 2;
            }
        }
    }

}

.cart-total {
    border-radius: 1.2rem;
    -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    -ms-border-radius: 1.2rem;
    -o-border-radius: 1.2rem;

    .card-body {
        padding: 2rem;

        hr.bg-dash {
            background-image: url("../../images/dash-border.svg");
            height: 0.2rem;
            width: 100%;
            background-size: auto;
            border: 0rem;
            opacity: 1;
            margin: 1.6rem 0rem;
        }
    }

    .balance-content {
        background-color: $color_9;
        padding: 1.6rem 2rem;
        border-radius: inherit inherit 0 0;
        -webkit-border-radius: inherit inherit 0 0;
        -moz-border-radius: inherit inherit 0 0;
        -ms-border-radius: inherit inherit 0 0;
        -o-border-radius: inherit inherit 0 0;

        ul {
            display: flex;
            flex-direction: column;
            gap: 0.4rem;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
                color: $color_2;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
            }
        }
    }
}

.manage-cards {
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    margin-bottom: 2.4rem;

    .manage-card {
        background: $color_5;
        box-shadow: $shadow-2;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        border: 0rem;

        .card-body {
            padding: 2rem;

            .card-list {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;
                align-items: stretch;

                li {
                    .manage-grid {
                        display: grid;
                        grid-template-columns: 6.4rem auto;
                        gap: 2.4rem;
                        align-items: flex-start;

                        .head {
                            color: $color_3;
                            font-family: $font-inter;
                            font-size: $text_1;
                            font-weight: $font_weight_normal;
                            line-height: $lh_1;

                            &.imgs {
                                background: $color_8;
                                border-radius: 0.6rem;
                                -webkit-border-radius: 0.6rem;
                                -moz-border-radius: 0.6rem;
                                -ms-border-radius: 0.6rem;
                                -o-border-radius: 0.6rem;
                                width: 6.7rem;
                                height: 7.2rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0.8rem;

                                img {
                                    mix-blend-mode: multiply;
                                }
                            }
                        }

                        .user-name {
                            display: flex;
                            align-items: flex-start;
                            justify-content: flex-start;
                            column-gap: 1.2rem;

                            span {
                                background: $bg_linear2;
                                box-shadow: $shadow-2;
                                border-radius: 99rem;
                                -webkit-border-radius: 99rem;
                                -moz-border-radius: 99rem;
                                -ms-border-radius: 99rem;
                                -o-border-radius: 99rem;
                                color: $color_3;
                                font-size: $text_1;
                                font-style: $font_style_1;
                                font-weight: $font_weight_bold;
                                line-height: $lh_1;
                                width: 3.2rem;
                                height: 3.2rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            h5 {
                                color: $color_7;
                                font-size: $text_2;
                                font-style: $font_style_1;
                                font-weight: $font_weight_bold;
                                line-height: $lh_1;
                            }

                            p {
                                color: $color_7;
                                font-size: $text_2;
                                font-style: $font_style_1;
                                font-weight: $font_weight_normal;
                                line-height: $lh_1;
                                letter-spacing: -0.014rem;
                                max-width: 16rem;
                            }

                        }

                        .table-order {
                            .order-head {
                                display: grid;
                                grid-template-columns: 4.5rem auto;
                                column-gap: 1.2rem;
                                align-items: flex-start;
                                margin-bottom: 1.6rem;

                                .order-img {
                                    background: #f8fafb;
                                    border-radius: 0.8rem;
                                    -webkit-border-radius: 0.8rem;
                                    -moz-border-radius: 0.8rem;
                                    -ms-border-radius: 0.8rem;
                                    -o-border-radius: 0.8rem;
                                    max-width: 8.9rem;
                                    overflow: hidden;
                                    height: 6rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    img {
                                        mix-blend-mode: multiply;
                                    }
                                }

                                .title {
                                    color: $color_6;
                                    font-family: $font-inter;
                                    font-size: $text_2;
                                    font-style: $font_style_1;
                                    font-weight: $font_weight_bold;
                                    line-height: 171.429%;
                                    letter-spacing: -0.014rem;
                                    margin-bottom: 0rem;
                                }

                            }

                            .order-table-content {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;
                                justify-content: center;
                                gap: 1.2rem;



                                .order-detail-list {
                                    display: flex;
                                    align-items: center;
                                    justify-content: flex-start;
                                    gap: 1.2rem;
                                    flex-wrap: wrap;

                                    li {
                                        display: flex;
                                        align-items: flex-start;
                                        justify-content: flex-start;
                                        column-gap: 0.6rem;
                                        position: relative;

                                        p {
                                            color: $color_3;
                                            font-family: $font-inter;
                                            font-size: $text_1;
                                            font-style: $font_style_1;
                                            font-weight: $font_weight_normal;
                                            line-height: $lh_1;
                                            letter-spacing: -0.024rem;
                                            margin-bottom: 0rem;
                                        }

                                        span {
                                            color: $color_6;
                                            font-family: $font-inter;
                                            font-size: $text_1;
                                            font-style: $font_style_1;
                                            font-weight: $font_weight_normal;
                                            line-height: $lh_1;
                                            letter-spacing: -0.024rem;
                                            display: flex;
                                            align-items: center;
                                            justify-content: flex-start;
                                            column-gap: 0.6rem;
                                            position: relative;

                                            .color {
                                                width: 1.6rem;
                                                height: 1.6rem;
                                                background: $color_6;
                                                border-radius: 0.4rem;
                                                -webkit-border-radius: 0.4rem;
                                                -moz-border-radius: 0.4rem;
                                                -ms-border-radius: 0.4rem;
                                                -o-border-radius: 0.4rem;
                                            }
                                        }
                                    }

                                    li+li::before {
                                        float: left;
                                        content: "";
                                        width: 0.1rem;
                                        height: 1.1rem;
                                        background: #eceff3;
                                        left: -12%;
                                        position: absolute;
                                        top: 50%;
                                        transform: translateY(-50%);
                                        -webkit-transform: translateY(-50%);
                                        -moz-transform: translateY(-50%);
                                        -ms-transform: translateY(-50%);
                                        -o-transform: translateY(-50%);
                                    }
                                }
                            }
                        }

                        h5,
                        p {
                            color: $color_2;
                            font-size: $text_2;
                            font-style: $font_style_1;
                            font-weight: $font_weight_normal;
                            line-height: $lh_1;
                            margin-bottom: 0rem;
                            max-width: 22rem;
                        }

                        .employees {
                            display: flex;
                            align-items: center;

                            .btn {
                                border: 0.2rem solid $bg-White;
                                background: $color_8;
                                border-radius: 5.6rem;
                                -webkit-border-radius: 5.6rem;
                                -moz-border-radius: 5.6rem;
                                -ms-border-radius: 5.6rem;
                                -o-border-radius: 5.6rem;
                                padding: 0rem;
                                margin: 0rem;

                                &:last-child {
                                    background: $color_6;
                                    color: $color_10;
                                    font-size: $text_1;
                                    font-style: $font_style_1;
                                    font-weight: $font_weight_bold;
                                    line-height: 166.667%;
                                    padding: 0.8rem 0.7rem;
                                    width: auto;
                                    min-width: 3.8rem;
                                    height: 3.8rem;
                                }
                            }

                            > :not(:first-child) {
                                margin-left: -0.8rem;
                            }
                        }

                        .qty-input {
                            height: 3.7rem;
                            width: 100%;
                            justify-content: center;
                            box-shadow: $shadow-4;
                        }
                    }
                }
            }
        }
    }

    .btn-order[aria-expanded="false"] {
        svg {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
        }
    }

    .btn-order[aria-expanded="true"] {
        svg {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transition: all 0.2s linear;
            -webkit-transition: all 0.2s linear;
            -moz-transition: all 0.2s linear;
            -ms-transition: all 0.2s linear;
            -o-transition: all 0.2s linear;
        }
    }
}

.report-order-detail-content {
    margin-top: 2.4rem;

    .order-lists {
        display: grid;
        grid-template-columns: 1fr;
        gap: 3.2rem;
        max-height: 70.7rem;
        overflow: hidden auto;
        padding-right: 1.2rem;
        scrollbar-color: $Neutral-Solid-50 $bg-White;
        scrollbar-width: thin;

        .order-card {
            position: relative;

            .order-head {
                display: grid;
                grid-template-columns: 11.8rem auto;
                gap: 1.2rem;
                align-items: flex-start;

                .order-img {
                    background: $color_8;
                    border-radius: 1.15rem;
                    -webkit-border-radius: 1.15rem;
                    -moz-border-radius: 1.15rem;
                    -ms-border-radius: 1.15rem;
                    -o-border-radius: 1.15rem;
                    height: 11.5rem;
                    padding: 1rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        mix-blend-mode: multiply;
                    }
                }

                .title {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-weight: $font_weight_bold;
                    line-height: 171.429%;
                    letter-spacing: -0.014rem;
                    margin-bottom: 0rem;
                }

                p {
                    color: var(--Neutral-Solid-500, #666D80);
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-weight: $font_weight_normal;
                    line-height: 166.667%;
                    letter-spacing: -0.024rem;
                    display: flex;
                    align-items: center;
                    gap: 0.4rem;
                }
            }

            hr.bg-dash {
                border: 0rem;
                background: url("../../images/dash-border.svg");
                height: 0.2rem;
                opacity: 1;
                margin: 1.6rem 0rem;
            }

            .order-content {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 1.6rem;

                .desc {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-weight: $font_weight_normal;
                    line-height: 157.143%;
                    letter-spacing: -0.014rem;
                }

                .product-features {
                    display: flex;
                    flex-direction: column;
                    gap: 0.8rem;

                    p {
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-weight: $font_weight_normal;
                        line-height: 157.143%;
                        letter-spacing: -0.014rem;
                        display: flex;
                        flex-direction: column;

                        span {
                            color: $color_3;
                        }
                    }
                }
            }
        }

        >:not(:last-child) {

            &::after {
                position: absolute;
                content: "";
                bottom: -3%;
                left: 0%;
                width: 100%;
                height: 0.2rem;
                background: url("../../images/dash-border.svg");
            }
        }

        &::-webkit-scrollbar-track {
            background-color: $bg-White;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }

        &::-webkit-scrollbar {
            background-color: $bg-White;
            width: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $Neutral-Solid-50;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }
    }
}