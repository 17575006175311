/* =================== */
/* About Banner start */
/* ================= */

section {
  &.aboutbannersec {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(65.8% 62.46% at 97.64% 48.25%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(120.82% 115.83% at 72.19% 74.45%,
          #eef9fd 0%,
          #eef9fd 24.36%,
          rgba(255, 255, 255, 0) 100%),
        #fff;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      margin-bottom: 36rem;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url("../../images/Background-pattern.png");
        z-index: 0;
        background-position: center right;
      }
    }

    .banner-content {
      margin-bottom: 2rem;
    }

    .container {
      position: relative;
      z-index: 1;
    }

    .abhd {
      color: $color_3;
      font-family: $font-inter;
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1.6rem;
      letter-spacing: 0.048rem;
      text-transform: uppercase;
      margin-bottom: 1.6rem;
    }

    h1 {
      line-height: 110%;
    }

    p {
      max-width: 58.8rem;
      color: $color_2;
      font-family: $font-inter;
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      letter-spacing: -0.036rem;
      margin-bottom: 0rem;
    }

    .banner-img {
      margin-bottom: -30rem;
    }
  }
}

section {
  &.our-vision {
    .minhead {
      margin-bottom: 2rem;
    }

    h4 {
      color: $color_11;
      text-align: center;
      font-size: 4rem;
      font-style: normal;
      font-weight: 450;
      line-height: 140%;
      letter-spacing: -0.8px;
    }
  }

  &.ourTeam {
    .max-width-container {
      padding-top: 12rem;
      border-radius: 2.4rem;
      background: $color_8;
      padding-bottom: 9.6rem;

      .top-heading {
        h2 {
          font-size: 5.6rem;
          line-height: 128.571%;
          letter-spacing: -1.12px;
        }

        p {
          line-height: 155.556%;
          font-size: $text_4;
          max-width: 69rem;
          color: $color_3;
          margin: 1.6rem 0 7.2rem 0;
          letter-spacing: -0.36px;
        }
      }

      .contents {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 7.2rem;
        grid-gap: 2.4rem;

        .card {
          border: none;
          background: transparent;

          .card-body {
            padding: 0;

            h4 {
              &.title {
                margin-top: 2.4rem;
                letter-spacing: -0.48px;
                line-height: 150%;
                font-size: 2.4rem;
              }
            }

            span {
              color: $color_3;
              font-size: $text_4;
              line-height: 155.556%;
              letter-spacing: -0.36px;
            }
          }
        }
      }
    }

    .team-slide-content {
      border-radius: 1.6rem;
      padding: 9.6rem 2.4rem;
      background: $color_5;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.24);

      .team-img {
        text-align: center;

        img {
          height: 64px;
          width: 64px;
          border-radius: 50%;
        }
      }

      h4 {
        color: $color_6;
        position: relative;
        z-index: 1;
        font-size: 3.2rem;
        line-height: 150%;
        letter-spacing: -0.64px;
        margin: 1.6rem auto;
        text-align: center;
        max-width: 79.2rem;

        span {
          position: absolute;
          z-index: -1;
          left: -6%;
          top: -28%;
        }
      }

      .bynw {
        text-align: center;

        h5 {
          font-size: 2rem;
          margin-bottom: 0.8rem;
          letter-spacing: -0.4px;
          line-height: 150%;
        }

        span {
          letter-spacing: 0.48px;
          text-transform: uppercase;
          line-height: 133.333%;
          font-size: 1.2rem;
          font-style: normal;
          font-weight: 600;
          color: $color_3;
        }
      }
    }
  }

  &.our-pillars {
    .pillars-content {
      .inner {
        display: grid;
        align-items: center;
        grid-gap: 6.4rem;
        grid-template-columns: 48.7rem 1fr;

        p {
          font-size: 1.8rem;
          color: $color_3;
          line-height: 155.556%;
          letter-spacing: -0.36px;
        }

        .pillars-cards {
          display: grid;
          grid-gap: 16px;
          grid-template-columns: 1fr 1fr;
          text-align: center;

          .card {
            border: none;
            padding: 3.2rem 2rem;
            grid-gap: 1.6rem;
            border-radius: 1.2rem;

            &:nth-child(1) {
              background: linear-gradient(180deg, #e2eaff 0%, #cddbff 100%),
                #fff;
            }

            &:nth-child(2) {
              background: linear-gradient(180deg, #fff4ea 0%, #ffe4cd 100%),
                #fff;
            }

            &:nth-child(3) {
              background: linear-gradient(180deg, #f5f8fb 0%, #e2e6e8 100%),
                linear-gradient(180deg, #fff9f3 0%, #fffdf3 100%), #fff;
            }

            &:nth-child(4) {
              background: linear-gradient(180deg, #eefaff 0%, #dbf4ff 100%),
                linear-gradient(180deg, #fff9f3 0%, #fffdf3 100%), #fff;
            }

            h4 {
              font-size: 20px;
              font-family: $suisse_medium;
              line-height: 2.8rem;
            }
          }
        }
      }
    }

    .hrline {
      margin: 6.4rem 0;
      overflow: hidden;
    }

    .content {
      background: url("../../images/prominent-bg.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      padding: 4rem 0;
      min-height: 44.7rem;

      .prominent-img {
        text-align: center;
        height: 7.2rem;
        width: 7.2rem;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 4.8rem;
        border-radius: 50%;
        border: 1px solid $border-neutral-solid;
        background: linear-gradient(180deg,
            #f8fafb 0%,
            rgba(248, 250, 251, 0) 120%);
        backdrop-filter: blur(2.5px);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        span {
          border-radius: 50%;
          box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
            0px 1px 2px 0px rgba(164, 172, 185, 0.24),
            0px 9px 16px -4px rgba(0, 0, 0, 0.13),
            0px -7px 10px -5px rgba(0, 0, 0, 0.06) inset;
          display: flex;
          width: 4.8rem;
          height: 4.8rem;
          padding: 8px;
          justify-content: center;
          align-items: center;
          background: $color_5;
        }
      }
    }

    .prominent-card {
      p {
        text-align: center;
        font-size: 32px;
        font-weight: $font_weight_bold;
        max-width: 792px;
        line-height: 112.5%;
        letter-spacing: -0.64px;
        margin: auto;
      }
    }
  }

  &.social-responsibility {
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 3.2rem;

      .item {
        .img-box {
          height: 24rem;
          border-radius: 1.6rem;
          background: #f6f8fa;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        h5 {
          font-size: 20px;
          margin-top: 2.4rem;
          font-family: $suisse_medium;
          line-height: 140%;
          text-transform: capitalize;
        }

        p {
          color: $color_3;
          font-size: $text_4;
          line-height: 155.556%;
          letter-spacing: -0.36px;
        }
      }
    }

    h2 {
      text-align: center;
      font-size: 5.6rem;
      line-height: 7.2rem;
      letter-spacing: -1.12px;
    }

    .hdde {
      text-align: center;

      span {
        color: $color_3;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        margin: 2.4rem;
        letter-spacing: 0.48px;
        text-transform: uppercase;
        display: inline-block;
      }
    }
  }

  &.ourLocation {
    .contents {
      display: grid;
      grid-template-columns: auto auto;

      h2 {
        margin-top: 3.2rem;
      }
    }

    .max-width-container {
      border-radius: 2.4rem;
      padding-top: 12rem;
      padding-bottom: 0;
      background: $color_8;
    }
  }
}

/* =================== */
/* About Banner end */
/* ================= */

@media only screen and (max-width: 992px) {
  section {
    &.aboutbannersec {
      .max-width-container {
        padding-top: 4rem;
        margin-bottom: 0;
      }

      .banner-img {
        margin-bottom: 0;
      }

      h1 {
        font-size: 5.6rem;
      }
    }

    &.our-vision {
      h4 {
        font-size: 2.4rem;
      }
    }

    &.ourTeam {

      .max-width-container {

        .team-slide-content {
          h4 {
            span {
              left: 0;
            }
          }
        }

        .contents {

          .card {

            .card-body {

              h4 {
                &.title {
                  font-size: 1.8rem;
                }
              }

              span {
                font-size: $text_2;
              }
            }
          }
        }

      }
    }

    &.our-pillars {
      .pillars-content {
        .inner {
          grid-gap: 4rem;
          grid-template-columns: 1fr;
        }
      }
    }

    &.ourLocation {
      .contents {
        grid-template-columns: 1fr;
        grid-gap: 4rem;
      }

      .max-width-container {
        padding-top: 4rem;
      }
    }

    &.social-responsibility {
      .content {
        grid-template-columns: 1fr 1fr;
        grid-gap: 3.2rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  section {
    &.aboutbannersec {
      .banner-img {
        margin-left: -2.2rem;
        margin-right: -2.2rem;
        margin-bottom: -2.2rem;
      }

      .banner-content {
        margin-bottom: 4.8rem;
      }
    }

    &.our-vision {
      .max-width-container {
        padding: 0;
      }

      h4 {
        text-align: left;
      }

      .minhead {
        margin-top: 2rem;
      }
    }

    &.ourTeam {
      padding-bottom: 0;

      .max-width-container {
        padding-top: 4.8rem;
        padding-bottom: 4.8rem;

        .top-heading {
          h2 {
            font-size: 4rem;
          }

          p {
            margin: 1.6rem 0 4.8rem 0;
          }
        }

        .contents {
          grid-template-columns: 1fr 1fr;
        }
      }

      .team-slide-content {
        padding: 6.4rem 2.4rem;

        h4 {
          font-size: 2rem;

          span {
            top: -14%;
            left: -4%;

            svg {
              width: 7.8rem;
              height: 6.9rem;
            }
          }
        }
      }
    }

    &.our-pillars {
      h2 {
        font-size: 4rem;
      }

      .max-width-container {
        padding: 0;
      }

      .pillars-content {
        .inner {
          grid-gap: 2.4rem;
        }
      }

      .hrline {
        margin: 2.4rem 0;
      }

      .prominent-card {
        p {
          font-size: 2rem;
        }
      }
    }

    &.ourLocation {
      .contents {
        grid-gap: 1.8rem;

        h2 {
          font-size: 4rem;
          margin-top: 0;
        }
      }

      .max-width-container {
        padding-top: 2.4rem;
      }
    }

    &.social-responsibility {
      h2 {
        font-size: 4rem;
        line-height: 125%;
      }
    }
  }
}

@media only screen and (max-width: 576px) {

  section {

    &.ourTeam {
      .max-width-container {
        .contents {
          grid-template-columns: 1fr;
        }
      }
    }

    &.social-responsibility {
      .content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 3.2rem;
      }
    }

    &.our-pillars {

      .pillars-content {
        .inner {
          grid-gap: 2.4rem;

          .pillars-cards {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
}