.modal {
  &.deleteModal {
    .modal-content {
      margin: 0rem;
      border: 0.2rem solid $bg-White;
      background-color: $bg-White;
      box-shadow: 0rem 0.7rem 2rem -0.3rem rgba(0, 0, 0, 0.07);
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      background-image: url("../../images/Background-pattern.png");
      background-origin: border-box;
      background-position: bottom;
      background-repeat: no-repeat;
      background-size: 80rem;
      position: relative;

      .modal-body {
        padding: 2.4rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2.4rem;

        .btn-cancel {
          position: absolute;
          top: 5%;
          right: 3%;
        }

        .delete-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 1.6rem;
          text-align: center;

          .dlt-icon {
            border: 0.1rem solid $border-neutral-solid;
            background: $bg-linear3;
            backdrop-filter: blur(0.25rem);
            border-radius: 999rem;
            -webkit-border-radius: 999rem;
            -moz-border-radius: 999rem;
            -ms-border-radius: 999rem;
            -o-border-radius: 999rem;
            padding: 1.2rem;

            span {
              background: $bg_linear2;
              box-shadow: $shadow-6;
              border-radius: 99rem;
              -webkit-border-radius: 99rem;
              -moz-border-radius: 99rem;
              -ms-border-radius: 99rem;
              -o-border-radius: 99rem;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 3.6rem;
              height: 3.6rem;
            }
          }

          .title {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_4;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 133.333%;
            letter-spacing: -0.036rem;
            margin-bottom: 0.4rem;
          }

          .desc {
            color: $color_3;
            text-align: center;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
            margin-bottom: 0rem;

            span {
              color: $color_6;
            }
          }
        }

        .btns {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1.6rem;
          width: 100%;

          .btn {
            width: 100%;
          }
        }
      }

      &::before {
        content: "";
        width: 100%;
        height: 100%;
        opacity: 0.3;
        background: radial-gradient(86.35% 86.35% at 50% 0%,
            rgba(225, 42, 77, 0.3) 0%,
            rgba(255, 255, 255, 0) 100%);
        position: absolute;
        top: 0%;
        right: 0%;
        bottom: 0%;
        left: 0%;
      }
    }
  }

  &.bulk-order {
    .modal-content {
      background: $color_5;
      box-shadow: $shadow-2;
      border-radius: 1.6rem;
      -webkit-border-radius: 1.6rem;
      -moz-border-radius: 1.6rem;
      -ms-border-radius: 1.6rem;
      -o-border-radius: 1.6rem;
      border: 0rem;

      .modal-header {
        padding: 2.4rem;
        align-items: flex-end;
        border-bottom: 0.1rem solid $border-neutral-solid;
        justify-content: space-between;

        .modal-title {
          color: $color_1;
          font-family: $suisse_medium;
          font-size: $text_5;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 140%;
        }

        .btn-secondary-icon {
          padding: 0.55rem;
        }
      }

      .modal-body {
        padding: 2.4rem;

        .order-bulk {
          display: grid;
          grid-template-columns: 28.2rem auto;
          align-items: flex-start;
          gap: 1.2rem;
          position: relative;

          .order-img {
            // width: 28.2rem;
            border-radius: 1.6rem;
            background: $color_8;
            display: grid;
            place-items: center;
            padding: 0.3rem;

            img {
              max-width: 28.2rem;
              max-height: 27.2rem;
              mix-blend-mode: multiply;
            }
          }

          .order-content {

            .title {
              color: $color_6;
              font-family: $font-inter;
              font-size: $text_3;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: 150%;
              letter-spacing: -0.032rem;
              margin-bottom: 0.4rem;
            }

            .price {
              @extend .title;
              font-weight: $font_weight_normal;
              letter-spacing: -0.016rem;
              margin-bottom: 0.8rem;
            }

            .form-group {
              margin-bottom: 1.2rem;

              .choosed {
                color: $color_3;
                font-family: $font-inter;
                font-size: 1.2rem;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 94%;
                letter-spacing: -0.014rem;
              }
            }

            .order-size-qty {
              display: flex;
              flex-wrap: wrap;
              gap: 0.8rem;

              .form-check {

                .form-check-label {
                  background: $color_9;
                  border-radius: 0.8rem;
                  -webkit-border-radius: 0.8rem;
                  -moz-border-radius: 0.8rem;
                  -ms-border-radius: 0.8rem;
                  -o-border-radius: 0.8rem;
                  padding: 0.4rem;
                  display: flex;
                  align-items: center;
                  flex-direction: column;
                  gap: 0.6rem;
                  width: fit-content;
                  color: $color_3;
                  font-family: $font-inter;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_normal;
                  line-height: 157.143%;
                  letter-spacing: -0.014rem;
                  cursor: pointer;

                  .qty-input {
                    background: $color_5;
                    box-shadow: $shadow-2;
                    border: 0rem;
                    padding: 0.7rem 0.8rem;
                    border-radius: 0.6rem;
                    -webkit-border-radius: 0.6rem;
                    -moz-border-radius: 0.6rem;
                    -ms-border-radius: 0.6rem;
                    -o-border-radius: 0.6rem;
                    height: 3.6rem;
                  }

                  .qty-count {
                    max-width: 2.2rem;
                  }
                }

                .form-check-input {

                  &:checked,
                  &[checked] {
                    + {
                      .form-check-label {
                        background: $bg-proccess;
                        color: $color_5;
                      }
                    }
                  }
                }
              }

              .btn-size-more {
                display: flex;
                flex-direction: column;
                gap: 0.6rem;
                background: $bg_linear2;
                box-shadow: $shadow-2;
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
                max-width: 10.4rem;
                width: 100%;
              }
            }

            .size-qty-select-after {
              display: flex;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .form-select {
                width: 24.8rem;
              }

              .qty-input {
                background: $color_5;
                box-shadow: $shadow-2;
                border: 0rem;
                padding: 0.7rem 0.8rem;
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
              }
            }
          }


          .dlt-bulk {
            position: absolute;
            top: 0%;
            right: 0%;
          }

        }

        >div:not(:last-child) {
          padding-bottom: 2.4rem;
          margin-bottom: 2.4rem;

          &::after {
            content: "";
            position: absolute;
            bottom: 0%;
            left: 0%;
            background-image: url("../../images/dash-border.svg");
            height: 0.2rem;
            width: 100%;
            background-size: auto;
            border: 0rem;
            opacity: 1;
          }
        }

        .size-chart-img {
          display: grid;
          grid-template-columns: 28.2rem auto;
          align-items: flex-start;
          gap: 3.2rem;

          .img-text {

            .img {
              background: $color_8;
              border-radius: 1.6rem;
              -webkit-border-radius: 1.6rem;
              -moz-border-radius: 1.6rem;
              -ms-border-radius: 1.6rem;
              -o-border-radius: 1.6rem;
              margin-bottom: 1.6rem;
              overflow: hidden;
            }

            h4 {
              color: $color_6;
              font-family: $font-inter;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: 157.143%;
              letter-spacing: -0.014rem;
            }
          }

          .main-table {

            table {
              box-shadow: none;

              thead {
                tr {
                  th {
                    border-bottom: 0rem;
                    width: 33.3%;

                    &:first-child {
                      border-radius: 0.6rem 0rem 0rem 0.6rem;
                      -webkit-border-radius: 0.6rem 0rem 0rem 0.6rem;
                      -moz-border-radius: 0.6rem 0rem 0rem 0.6rem;
                      -ms-border-radius: 0.6rem 0rem 0rem 0.6rem;
                      -o-border-radius: 0.6rem 0rem 0rem 0.6rem;
                    }

                    &:last-child {
                      border-radius: 0rem 0.6rem 0.6rem 0rem;
                      -webkit-border-radius: 0rem 0.6rem 0.6rem 0rem;
                      -moz-border-radius: 0rem 0.6rem 0.6rem 0rem;
                      -ms-border-radius: 0rem 0.6rem 0.6rem 0rem;
                      -o-border-radius: 0rem 0.6rem 0.6rem 0rem;
                    }
                  }

                  td {
                    width: 33.3%;
                    color: $color_2;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    max-height: 6.4rem;
                  }
                }
              }
            }
          }
        }

        .decoration-collapse {
          padding: 2rem;

          ul {
            li {
              margin-bottom: 0.8rem;

              p {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: $lh_1;
                letter-spacing: -0.014rem;
                display: flex;
                align-items: flex-start;
                gap: 0.6rem;
                margin-bottom: 0.8rem;
              }
            }
          }
        }
      }

      .modal-footer {
        padding: 1.6rem 2.4rem;
        margin: 0.4rem;
        background: $color_9;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        border: 0rem;

        .added-item {
          color: $color_3;
          font-family: $font-inter;
          font-size: $text_1;
          font-style: $font_style_1;
          font-weight: 600;
          line-height: 200%;
          letter-spacing: 0.048rem;
          text-transform: uppercase;
          display: flex;
          align-items: center;
          gap: 0.6rem;
          margin-bottom: 0.8rem;

          span {
            width: 2.6rem;
            height: 2.6rem;
            color: $color_2;
            font-family: $font-inter;
            font-size: $text_1;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0.012rem;
            text-transform: uppercase;
            padding: 0rem;
          }
        }

        .add-product {

          .img-content {
            display: grid;
            grid-template-columns: 8.4rem auto;
            gap: 1.2rem;
            align-items: flex-start;

            .card-img {
              background: $bg-White;
              border: 0.1rem solid $color_17;
              width: 8.4rem;
              height: 8.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.1rem 1.6rem;
              border-radius: 1.6rem;
              -webkit-border-radius: 1.6rem;
              -moz-border-radius: 1.6rem;
              -ms-border-radius: 1.6rem;
              -o-border-radius: 1.6rem;

              img {
                max-height: 8.1rem;
                mix-blend-mode: multiply;
              }
            }

            .content {

              .title {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 171.429%;
                letter-spacing: -0.014rem;
              }

              .price {
                @extend .title;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                margin-bottom: 0.4rem;
              }

              .color {
                color: var(--Neutral-Solid-600, #36394A);
                font-family: $font-inter;
                font-size: 1rem;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 160%;
                letter-spacing: -0.01rem;
                display: flex;
                align-items: center;
                gap: 0.6rem;
                margin-bottom: 0.8rem;

                span {
                  width: 1rem;
                  height: 1rem;
                  border-radius: 9rem;
                  -webkit-border-radius: 9rem;
                  -moz-border-radius: 9rem;
                  -ms-border-radius: 9rem;
                  -o-border-radius: 9rem;

                  &.orange-navy {
                    background: linear-gradient(0deg, #090D22 50%, #EE821E 50%);
                  }

                  &.yellow-navy {
                    background: linear-gradient(0deg, #090D22 50%, #FCED6C 50%);
                  }
                }
              }

              .sizes {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 0.4rem;

                h5 {
                  border: 0.1rem solid $border-neutral-solid;
                  background: $color_5;
                  border-radius: 0.6rem;
                  -webkit-border-radius: 0.6rem;
                  -moz-border-radius: 0.6rem;
                  -ms-border-radius: 0.6rem;
                  -o-border-radius: 0.6rem;
                  padding: 0.2rem 0.8rem;
                  display: flex;
                  gap: 0.4rem;
                  align-items: center;
                  color: $color_2;
                  font-family: $font-inter;
                  font-size: $text_1;
                  font-style: $font_style_1;
                  font-weight: $font_weight_normal;
                  line-height: 166.667%;
                  letter-spacing: -0.024rem;

                  span {
                    color: $color_2;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 166.667%;

                    &.color {
                      width: 1rem;
                      height: 1rem;
                      border-radius: 9rem;
                      -webkit-border-radius: 9rem;
                      -moz-border-radius: 9rem;
                      -ms-border-radius: 9rem;
                      -o-border-radius: 9rem;
                    }

                    &.orange-navy {
                      background: linear-gradient(0deg, #090D22 50%, #EE821E 50%);
                    }

                    &.yellow-navy {
                      background: linear-gradient(0deg, #090D22 50%, #FCED6C 50%);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.searchpopup {
    p {
      font-size: 1.4rem;
    }

    span {
      font-size: 1.4rem;
    }

    li {
      font-size: 1.4rem;
    }

    .modal-body {
      padding: 0;
      box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25), 0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04), 0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
      background: $color_8;
      border-radius: 1.6rem;
      -webkit-border-radius: 1.6rem;
      -moz-border-radius: 1.6rem;
      -ms-border-radius: 1.6rem;
      -o-border-radius: 1.6rem;

      .badge {
        border: 0.1rem solid $border-neutral-solid;
        font-weight: $font_weight_bold;
        padding: 0.6rem 1.2rem;
        cursor: pointer;
      }

      .srctop {
        padding: 1.6rem 2.4rem;
      }

      .srchin {
        padding: 0.8rem 2.4rem 2.4rem;
        margin: 0.8rem;
        background: $color_5;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;

        ul.ulb {
          max-height: 285px;
          overflow: auto;
          scrollbar-color: $Neutral-Solid-50 $bg-White;
          scrollbar-width: thin;

          &::-webkit-scrollbar-track {
            background-color: $bg-White;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
          }

          &::-webkit-scrollbar {
            background-color: $bg-White;
            width: 0.8rem;
          }

          &::-webkit-scrollbar-thumb {
            background-color: $Neutral-Solid-50;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
          }

          li {
            display: grid;
            align-items: center;
            grid-gap: 0.8rem;
            margin-bottom: 0.8rem;
            grid-template-columns: 6.6rem 1fr;

            &:last-child {
              margin-bottom: 0;
            }

            .img-box {
              text-align: center;
              border-radius: 0.8rem;
              width: 6.6rem;
              height: 6.4rem;
              padding: 0.2rem 1.334rem;
              border-radius: 0.8rem;
              background: #F8FAFB;
              -webkit-border-radius: 0.8rem;
              -moz-border-radius: 0.8rem;
              -ms-border-radius: 0.8rem;
              -o-border-radius: 0.8rem;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                mix-blend-mode: multiply;
                object-fit: contain;
              }
            }
          }
        }
      }

      .heading {
        margin: 1.6rem 0 0.8rem;
      }

      ul {
        li {
          margin-bottom: 0.8rem;
        }
      }

      .form-control {
        background: url("../../images/search.svg");
        background-repeat: no-repeat;
        background-position: center left 0.8rem;
        padding: 0.8rem 1rem 0.8rem 3.2rem;
        background-color: $color_5;

        &::placeholder {
          font-size: $text_2;
        }
      }
    }
  }

  .tabview {
    display: none;
  }

}

.more-offcanvas {
  background: $Toast-BG-Default;
  box-shadow: $shadow-7;
  backdrop-filter: blur(0.5rem);
  border-radius: 1rem 1rem 0rem 0rem;
  -webkit-border-radius: 1rem 1rem 0rem 0rem;
  -moz-border-radius: 1rem 1rem 0rem 0rem;
  -ms-border-radius: 1rem 1rem 0rem 0rem;
  -o-border-radius: 1rem 1rem 0rem 0rem;

  .offcanvas-body {
    padding: 1.2rem 0.4rem;

    .more-menu {
      .drop-head {
        padding: 0.6rem 1.6rem 0.6rem 1.2rem;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 1.2rem;

        span {
          width: 3.6rem;
          height: 3.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0.8rem;
          color: $color_3;
          font-size: $text_1;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: $lh_1;
          background: $bg_linear2;
          box-shadow: $shadow-2;
          border-radius: 99rem;
          -webkit-border-radius: 99rem;
          -moz-border-radius: 99rem;
          -ms-border-radius: 99rem;
          -o-border-radius: 99rem;
        }

        .title {
          color: $color_2;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          margin-bottom: 0rem;
        }

        .desc {
          color: $color_3;
          font-size: $text_2;
          font-style: $font_style_1;
          line-height: 142.857%;
          margin-bottom: 0rem;
          font-weight: $font_weight_normal;
        }

        .btn {
          position: absolute;
          top: 2%;
          right: 2%;
        }
      }

      .drop-list-menu {

        li {
          .dropdown-link {
            padding: 0.8rem 1.2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.2rem;
            color: $color_2;
          }

          h6 {
            color: $color_3;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 133.333%;
            padding: 0.8rem 1.2rem;
            margin-bottom: 0rem;
          }

          span.badge.bg-alpha-25 {
            background: $Neutral-Alpha-25;
            color: $color_7;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 142.857%;
            padding: 0.2rem 0.8rem;
            margin: 0rem 1.2rem;
          }
        }
      }

      .drop-footer {

        .btn {
          color: $color_2;
        }
      }

      .solid-line {
        height: 0.1rem;
        width: 100%;
        background: rgba(18, 55, 105, 0.08);
      }
    }
  }

  &.offcanvas-bottom {
    height: fit-content;
  }
}

@media (min-width: 576px) {
  .modal-sm {
    --bs-modal-width: 400px;
  }
}

@media screen and (max-width: 991px) {
  .modal {

    &.bulk-order {
      .modal-dialog {
        margin: 0rem auto;

        .modal-content {
          border-radius: 0rem;
          -webkit-border-radius: 0rem;
          -moz-border-radius: 0rem;
          -ms-border-radius: 0rem;
          -o-border-radius: 0rem;

          .modal-body {
            padding: 1.6rem;

            .order-bulk {
              grid-template-columns: 1fr;

              .order-img {
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;

                img {
                  max-width: 11.7rem;
                  max-height: 11.3rem;
                  mix-blend-mode: multiply;
                }
              }

              .order-content {

                .order-size-qty {
                  gap: 1.2rem 0rem;
                  justify-content: space-between;

                  .form-check.ps-0 {
                    width: 48%;

                    .form-check-label {
                      width: 100%;

                      .qty-input {
                        width: 100%;
                        justify-content: space-between;
                      }
                    }
                  }

                  .btn-size-more {
                    max-width: 50%;
                  }

                  .size-qty-select-after {
                    gap: 2.4rem;

                    .form-group {
                      width: 100%;

                      .form-select,
                      .qty-input {
                        width: 100%;
                      }

                      .qty-input {
                        height: 3.6rem;
                        justify-content: space-between;
                      }
                    }
                  }
                }

              }

              .order-md-img-content {
                display: grid;
                gap: 1.2rem;
                align-items: flex-start;
                grid-template-columns: 11.7rem auto;

                .content {
                  .title {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_3;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 150%;
                    letter-spacing: -0.032rem;
                    margin-bottom: 0.4rem;
                    max-width: 80%;
                  }

                  .price {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_3;
                    font-style: $font_style_1;
                    line-height: 150%;
                    font-weight: $font_weight_normal;
                    letter-spacing: -0.016rem;
                    margin-bottom: 0rem;
                  }
                }
              }
            }

            .size-chart-img {
              grid-template-columns: 1fr;
              gap: 2.4rem;
            }
          }

          .modal-footer {
            margin: 1.6rem;
            padding: 1.6rem;

            .add-product {

              .img-content {

                .card-img {
                  border-radius: 1.2rem;
                  -webkit-border-radius: 1.2rem;
                  -moz-border-radius: 1.2rem;
                  -ms-border-radius: 1.2rem;
                  -o-border-radius: 1.2rem;
                }
              }
            }

            .btns {
              flex-direction: column;
              width: 100%;

              .btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .deleteModal {

    .modal-dialog-centered {
      align-items: flex-end;
      height: 100%;
      margin: 0 auto;

      .modal-content {
        border: 2px solid $color_5;
        background: $bg-White;
        box-shadow: 0rem 0.7rem 2rem -0.03rem rgba(0, 0, 0, 0.07);
        border-radius: 1.2rem 1.2rem 0rem 0rem;
        -webkit-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -moz-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -ms-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -o-border-radius: 1.2rem 1.2rem 0rem 0rem;
      }
    }
  }
}