.cart-content {

    table {
        thead {
            th {
                &:first-child {
                    border-radius: 0.6rem 0rem 0rem 0.6rem;
                    -webkit-border-radius: 0.6rem 0rem 0rem 0.6rem;
                    -moz-border-radius: 0.6rem 0rem 0rem 0.6rem;
                    -ms-border-radius: 0.6rem 0rem 0rem 0.6rem;
                    -o-border-radius: 0.6rem 0rem 0rem 0.6rem;
                }

                &:last-child {
                    border-radius: 0rem 0.6rem 0.6rem 0rem;
                    -webkit-border-radius: 0rem 0.6rem 0.6rem 0rem;
                    -moz-border-radius: 0rem 0.6rem 0.6rem 0rem;
                    -ms-border-radius: 0rem 0.6rem 0.6rem 0rem;
                    -o-border-radius: 0rem 0.6rem 0.6rem 0rem;
                }
            }
        }

        tbody {

            >:not(:first-child) {
                margin-top: 1.6rem;
            }

            >:not(:last-child) {
                border-bottom: 0.1rem dashed $border-neutral-solid;
            }
        }
    }
}

.checkout-fill-content {

    .brand-title {
        color: $color_7;
        font-family: $font-inter;
        font-size: 1.68rem;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 142.857%;
        display: flex;
        align-items: center;
        gap: 0.96rem;
        margin-bottom: 2.4rem;
    }

    hr.bg-dash-gap {
        background-image: url("../../images/dash-border.svg");
        height: 0.2rem;
        width: 100%;
        background-size: auto;
        border: 0rem;
        opacity: 1;
        margin: 2.4rem 0rem;
    }

    .edit-card {
        gap: 2.4rem;
        background: transparent;

        .form-group {

            .country-phn {
                background-color: #ffffff;
                box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08);
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
                display: flex;
                align-items: center;
                justify-content: start;
                gap: 0rem;

                .sorted {
                    .dropdown {

                        .btn {
                            display: flex;
                            align-items: center;
                            column-gap: 0.6rem;
                            color: $color_4;
                            font-family: $font-inter;
                            font-size: $text_2;
                            font-style: $font_style_1;
                            font-weight: $font_weight_normal;
                            line-height: 142.857%;
                            letter-spacing: -0.014rem;
                            padding: 0rem;
                            border: 0;
                            margin-left: 1rem;

                            span {
                                width: 2rem;
                                height: 2rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }

                            &::after {
                                content: none;
                            }

                            &:focus,
                            &:focus-visible {
                                outline: 0;
                                border: 0;
                                box-shadow: none;
                            }
                        }

                        .dropdown-menu {
                            max-height: 21rem;
                            overflow: hidden auto;
                            scrollbar-color: $Neutral-Solid-50 $bg-White;
                            scrollbar-width: thin;

                            &::-webkit-scrollbar-track {
                                background-color: $bg-White;
                                border-radius: 9rem;
                                -webkit-border-radius: 9rem;
                                -moz-border-radius: 9rem;
                                -ms-border-radius: 9rem;
                                -o-border-radius: 9rem;
                            }

                            &::-webkit-scrollbar {
                                background-color: $bg-White;
                                width: 0.8rem;
                            }

                            &::-webkit-scrollbar-thumb {
                                background-color: $Neutral-Solid-50;
                                border-radius: 9rem;
                                -webkit-border-radius: 9rem;
                                -moz-border-radius: 9rem;
                                -ms-border-radius: 9rem;
                                -o-border-radius: 9rem;
                            }
                        }
                    }
                }
            }
        }

        .ship-btn[aria-expanded="false"] {
            svg {
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transition: all 0.3s ease-in;
                -webkit-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                -ms-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;

                .path-opacity {
                    opacity: 1;

                }
            }
        }

        .ship-btn[aria-expanded="true"] {
            svg {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transition: all 0.3s ease-in;
                -webkit-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                -ms-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;

                .path-opacity {
                    opacity: 0;
                }
            }
        }
    }

    .checkout-address {

        .collapse {

            .edit-card {
                .card-body {
                    &>:not(:last-child) {
                        margin-bottom: 1.6rem;
                    }
                }
            }
        }

        .address {
            display: flex;
            align-items: flex-start;
            width: 100%;
            position: relative;
            padding-left: 3rem;

            .title {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 171.429%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
                cursor: pointer;
            }

            p {
                color: $color_2;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
                cursor: pointer;
            }

            .form-check-input {
                position: absolute;
                top: 0%;
                left: 0%;
                cursor: pointer;


                &:checked[type="radio"] {
                    background-image: $checkedImage;
                    display: block;
                }
            }
        }

        .btn.btn-secondary-icon[aria-expanded="false"] {
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
        }

        .btn.btn-secondary-icon[aria-expanded="true"] {
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
        }
    }

}

.checkout-content {
    display: grid;
    grid-template-columns: auto 44.8rem;
    align-items: flex-start;
    gap: 6.4rem;

    .checkout-items {
        max-height: 40rem;
        overflow: hidden auto;
        scrollbar-color: $Neutral-Solid-50 $bg-White;
        scrollbar-width: auto;

        &::-webkit-scrollbar-track {
            background-color: $bg-White;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }

        &::-webkit-scrollbar {
            background-color: $bg-White;
            width: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $Neutral-Solid-50;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }

        .product-order {
            display: grid;
            grid-template-columns: 6.7rem auto;
            align-items: flex-start;
            gap: 1.6rem;

            .order-img {
                background: $color_9;
                border-radius: 0.6rem;
                height: 6.7rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
                padding: 0.8rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    mix-blend-mode: multiply;
                }
            }

            .order-table-content {
                display: flex;
                flex-direction: column;
                gap: 0.8rem;

                .title {
                    color: $color_1;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    margin-bottom: 0.4rem;
                }

                .price {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    letter-spacing: -0.028rem;
                }

                .order-detail-list {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 1.6rem;

                    li {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        column-gap: 0.6rem;
                        position: relative;

                        p {
                            color: $color_3;
                            font-family: $font-inter;
                            font-size: $text_1;
                            font-style: $font_style_1;
                            font-weight: $font_weight_normal;
                            line-height: $lh_1;
                            letter-spacing: -0.024rem;
                            margin-bottom: 0rem;
                        }

                        span {
                            color: $color_6;
                            font-family: $font-inter;
                            font-size: $text_1;
                            font-style: $font_style_1;
                            font-weight: $font_weight_normal;
                            line-height: $lh_1;
                            letter-spacing: -0.024rem;
                            display: flex;
                            align-items: center;
                            justify-content: flex-start;
                            column-gap: 0.6rem;
                            position: relative;

                            .color {
                                width: 1.6rem;
                                height: 1.6rem;
                                background: $color_6;
                                border-radius: 0.4rem;
                                -webkit-border-radius: 0.4rem;
                                -moz-border-radius: 0.4rem;
                                -ms-border-radius: 0.4rem;
                                -o-border-radius: 0.4rem;
                            }
                        }
                    }

                    li+li::before {
                        float: left;
                        content: "";
                        width: 0.1rem;
                        height: 1.1rem;
                        background: #eceff3;
                        left: -12%;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                    }
                }
            }
        }

        >:not(:last-child) {
            border-bottom: 0.1rem solid $border-neutral-solid;
            margin-bottom: 1.6rem;
            padding-bottom: 1.6rem;
        }
    }

    &.success-order {
        grid-template-columns: auto 48.6rem;
        gap: 2.4rem;

        .brand-name {
            color: $color_13;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
        }

        .checkout-detail {
            display: flex;
            flex-direction: column;
            gap: 1.6rem;

            p {
                color: $color_7;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 142.857%;
                letter-spacing: -0.014rem;

                span {
                    color: $color_3;
                    margin-bottom: 0.4rem;
                }
            }

            .other-address {

                h5 {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 171.429%;
                    letter-spacing: -0.014rem;
                }

                p {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: 157.143%;
                    letter-spacing: -0.014rem;
                }
            }
        }
    }
}

.success-order-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;

    .success-icon {
        border: 0.1rem solid $border-neutral-solid;
        background: $bg-linear3;
        border-radius: 99rem;
        -webkit-border-radius: 99rem;
        -moz-border-radius: 99rem;
        -ms-border-radius: 99rem;
        -o-border-radius: 99rem;
        backdrop-filter: blur(0.25rem);
        padding: 1.2rem;

        span {
            width: 3.6rem;
            height: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 999px;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF;
            box-shadow: $shadow-6;
        }
    }

    .title {
        color: $color_6;
        font-size: $text_7;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 133.333%;
        letter-spacing: -0.024rem;
    }

    .order-no {
        color: $color_3;
        font-family: $font-inter;
        font-size: $text_3;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: 150%;
        letter-spacing: -0.016rem;
    }


}

@media only screen and (max-width: 991px) {

    .checkout-content {
        grid-template-columns: 1fr;

        .checkout-address {

            .address {
                gap: 0.8rem;
            }
        }
    }
}

@media only screen and (max-width: 767px) {

    .success-order-head {
        flex-direction: column;
        gap: 2.4rem;
        margin-bottom: 2.4rem;

        .btn-secondary {
            width: 100%;
        }
    }

    .checkout-content {

        .checkout-address {

            .address {
                flex-direction: column;

                .w-50 {
                    width: 100% !important;
                }
            }
        }

        .edit-card {
            .checkout-auth {
                .form-group.form-check {
                    .form-check-label {
                        flex-direction: column;
                        align-items: flex-start;
                        gap: 0.8rem;
                    }
                }
            }
        }

        .checkout-items {
            .product-order {
                .order-table-content {
                    .order-detail-list {
                        flex-wrap: wrap;
                        row-gap: 0.8rem;
                    }
                }
            }
        }

        &.success-order {
            grid-template-columns: 1fr;
            gap: 2.4rem;
        }
    }
}