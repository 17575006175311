.product-slide-content {
    display: grid;
    grid-template-columns: 69rem auto;
    gap: 2.4rem;

    .product-details {
        display: flex;
        flex-direction: column;
        gap: 1.6rem;
    }

    .card.product-card {
        background: $bg-White;
        box-shadow: $shadow-5;
        backdrop-filter: blur(0.5rem);
        border-radius: 1.6rem;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;
        position: relative;

        .card-body {
            padding: 5.5rem 2.4rem 2.4rem 2.4rem;
            display: flex;
            flex-direction: column;
            gap: 2.4rem;

            .edit-card.decoration-collapse {
                padding: 2.4rem;

                .form-group{

                    ng-select{

                        .ng-select-container{
                            max-width: 100%;
                        }
                    }
                }
            }

            .product-detail-content {

                .uni-type {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: $lh_1;
                    letter-spacing: 0.024rem;
                    text-transform: uppercase;
                }

                .title {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_4;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: $lh_1;
                    letter-spacing: -0.036rem;
                }

                p {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.014rem;
                }

                .multi-wish {
                    position: absolute;
                    right: 5%;
                    top: 2%;

                    .dropdown-menu.more-menu {
                        background: $Toast-BG-Default;
                        box-shadow: $shadow-5;
                        backdrop-filter: blur(0.5rem);
                        padding: 0.4rem;
                        border: 0rem;
                        border-radius: 1rem;
                        -webkit-border-radius: 1rem;
                        -moz-border-radius: 1rem;
                        -ms-border-radius: 1rem;
                        -o-border-radius: 1rem;
                        min-width: 14rem;
                        width: 100%;

                        li {
                            a {
                                color: $color_3;
                                font-size: $text_1;
                                font-style: $font_style_1;
                                font-weight: $font_weight_bold;
                                line-height: 133.333%;
                                padding: 0.8rem 1.2rem;
                                margin-bottom: 0rem;
                                display: block;

                                .form-check-label{
                                    max-width: 9rem;
                                    cursor: pointer;
                                }
                            }
                        }
                    }
                }

                .btn.btn-wishlist {
                    p {
                        color: $color_3;
                        font-family: $font-inter;
                        font-size: $text_1;
                        font-style: $font_style_1;
                        font-weight: $font_weight_bold;
                        line-height: $lh_1;
                        letter-spacing: 0.024rem;
                        text-transform: uppercase;
                    }

                    svg {
                        path {
                            stroke: #A4ACB9;
                        }
                    }

                    &:active,
                    &.active {
                        p {
                            color: #DF1C41;
                        }

                        svg {
                            path {
                                stroke: #DF1C41;
                                fill: #DF1C41;
                            }
                        }
                    }

                    &::after {
                        content: none;
                    }
                }

                .price {
                    display: flex;
                    align-items: flex-end;
                    gap: 0.8rem;
                    margin-top: 1.6rem;

                    .real-price {
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_4;
                        font-style: $font_style_1;
                        font-weight: $font_weight_bold;
                        line-height: $lh_1;
                        letter-spacing: -0.036rem;
                    }

                    .price-gst {
                        color: $color_3;
                        font-family: $font-inter;
                        font-size: $text_1;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: $lh_1;
                        letter-spacing: -0.012rem;
                    }
                }
            }

            .product-size-color {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;

                .head {
                    margin-bottom: 1.2rem;

                    h4 {
                        color: $color_3;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: $lh_1;
                        letter-spacing: -0.028rem;
                        display: flex;
                        align-items: center;
                        gap: 0.8rem;

                        span {
                            color: $color_6;
                            text-transform: capitalize;
                        }

                        .divider {
                            display: inline-block;
                            width: 1.6rem;
                            height: 0.1rem;
                            background: #DFE1E7;
                        }
                    }

                    .btn {
                        color: $color_2;
                        line-height: normal;
                        letter-spacing: -0.014rem;
                        text-decoration-line: underline;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .color-size {
                    display: flex;
                    align-items: center;
                    gap: 0.8rem;
                    flex-wrap: wrap;

                    .form-check {
                        padding-left: 0rem;
                        margin-bottom: 0rem;
                        position: relative;

                        .form-check-label {
                            background: $color_9;
                            min-width: 4.8rem;
                            padding: 0rem 0.8rem;
                            height: 4.8rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border-radius: 5.6rem;
                            -webkit-border-radius: 5.6rem;
                            -moz-border-radius: 5.6rem;
                            -ms-border-radius: 5.6rem;
                            -o-border-radius: 5.6rem;
                            cursor: pointer;

                            &.color-label {
                                img {
                                    width: 2.4rem;
                                    height: 3rem;
                                    mix-blend-mode: multiply;
                                }
                            }
                        }

                        .form-check-input {
                            &:checked,
                            &.active {
                                + {
                                    .form-check-label {
                                        background: $color_11;
                                        color: $color_5;
                                    }
                                }
                            }

                            &.color-input {
                                position: absolute;
                                top: -6%;
                                right: -6%;
                                opacity: 0;

                                &:checked {
                                    opacity: 1;
                                    background: url("../../images/checkimgright.svg");
                                    border: 0rem;

                                    + {
                                        .form-check-label {
                                            background: $color_9;
                                            border: 0.1rem solid $color_11;

                                            &.color-label {
                                                img {
                                                    width: 2.4rem;
                                                    height: 3rem;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            &:focus {
                                box-shadow: none;
                            }
                        }
                    }
                }
            }

            .product-quantity {
                @extend .product-size-color;

                .decorations {
                    display: flex;
                    align-items: flex-start;
                    gap: 1.6rem;

                    .btn.btn-secondary-icon {
                        padding: 0.8rem 1rem;
                        border-radius: 0.8rem;
                        -webkit-border-radius: 0.8rem;
                        -moz-border-radius: 0.8rem;
                        -ms-border-radius: 0.8rem;
                        -o-border-radius: 0.8rem;

                        &[aria-expanded="false"] {
                            svg {
                                path.plus {
                                    display: block;
                                }

                                path.minus {
                                    display: none;
                                }
                            }
                        }

                        &[aria-expanded="true"] {
                            svg {
                                path.plus {
                                    display: none;
                                }

                                path.minus {
                                    display: block;
                                }
                            }
                        }
                    }

                    h4 {
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: $lh_1;
                        letter-spacing: -0.028rem;
                        display: flex;
                        align-items: center;
                        gap: 0.4rem;
                        margin-bottom: 0.4rem;
                    }

                    p {
                        color: $color_3;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: $lh_1;
                        letter-spacing: -0.014rem;
                    }
                }

                .decoration-collapse {
                    ul {
                        li {
                            margin-bottom: 0.8rem;

                            p {
                                color: $color_3;
                                font-family: $font-inter;
                                font-size: $text_2;
                                font-style: $font_style_1;
                                font-weight: $font_weight_normal;
                                line-height: $lh_1;
                                letter-spacing: -0.014rem;
                                display: flex;
                                align-items: flex-start;
                                justify-content: space-between;
                                gap: 0.6rem;
                                margin-bottom: 0.8rem;
                            }
                        }
                    }
                }

                .quantity {
                    @extend .decorations;
                    align-items: stretch;
                }
            }
        }

        .card-footer {
            background: $color_9;
            border: 0rem;
            margin: 0.4rem;
            padding: 2.4rem;
            border-radius: 1.2rem;
            -webkit-border-radius: 1.2rem;
            -moz-border-radius: 1.2rem;
            -ms-border-radius: 1.2rem;
            -o-border-radius: 1.2rem;
            display: flex;
            flex-direction: column;
            gap: 0.8rem;

            p {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0.8rem;
            }

            .btn {
                padding: 1.2rem;
            }
        }
    }
}

.remaining-garments {
    background: $bg-proccess;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1.6rem;
    padding: 0.8rem;
    border-radius: 6.4rem;

    .icon {
        background: rgba(255, 255, 255, 0.20);
        width: 4.8rem;
        height: 4.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4.8rem;
    }

    p {
        color: $bg-White;
        font-family: $font-inter;
        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: $lh_1;
        letter-spacing: -0.014rem;
        margin-bottom: 0.4rem;

        span {
            font-weight: $font_weight_bold;
        }
    }

    .small {
        color: $bg-White;
        font-family: $font-inter;
        font-size: $text_1;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: 166.667%;
        letter-spacing: -0.024rem;
    }

    &.no-payment {
        padding: 1.6rem;
        border-radius: 1.6rem;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;
        box-shadow: 0px 0px 0px 1px #F68C1E, 0px 1px 2px 0px rgba(174, 95, 12, 0.50);

        .icon {
            width: 3.6rem;
            height: 3.6rem;
        }

        p {
            font-weight: $font_weight_bold;
            line-height: 171.429%;
            margin-bottom: 0rem;
        }

        .small {
            font-weight: $font_weight_normal;
            line-height: 157.143%;
            letter-spacing: -0.014rem;
        }
    }
}

.descriptions {
    margin: 5rem 0rem 8rem;

    .title {
        color: $color_11;
        font-family: $suisse_regular;
        font-size: $text_7;
        font-style: $font_style_1;
        font-weight: $font_weight_middle_bold;
        line-height: 233.333%;
        letter-spacing: -0.048rem;
        margin-bottom: 1.6rem;
    }

    .description-type {
        display: grid;
        grid-template-columns: 69rem auto;
        gap: 2.4rem;

        .desc-1 {

            .title {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_3;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: $lh_1;
                letter-spacing: -0.032rem;
                margin-bottom: 2.4rem;
            }

            ul {
                display: flex;
                align-items: flex-start;
                flex-direction: column;
                gap: 1.6rem;

                li {
                    display: flex;
                    align-items: center;
                    gap: 1.6rem;

                    .desc-icon {
                        min-width: 5.6rem;
                        height: 5.6rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 64px;
                        border: 0.1rem solid $border-neutral-solid;
                        background: $color_10;
                    }

                    h6 {
                        color: $color_3;
                        font-family: $font-inter;
                        font-size: $text_1;
                        font-style: $font_style_1;
                        font-weight: $font_weight_bold;
                        line-height: $lh_1;
                        letter-spacing: 0.024rem;
                        text-transform: uppercase;
                        margin-bottom: 0.4rem;
                    }

                    p {
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: $lh_1;
                        gap: 0.6rem;

                        .color {
                            width: 1.6rem;
                            height: 1.6rem;
                            border-radius: 1.4rem;
                            -webkit-border-radius: 1.4rem;
                            -moz-border-radius: 1.4rem;
                            -ms-border-radius: 1.4rem;
                            -o-border-radius: 1.4rem;
                            display: block;

                            &.navy {
                                background: $color_15;
                            }

                            &.blue {
                                background: $color_16;
                            }
                        }
                    }
                }
            }
        }
    }
}

.uniform-kit {

    .title {
        color: $color_11;
        font-family: $suisse_regular;
        font-size: $text_7;
        font-style: $font_style_1;
        font-weight: $font_weight_middle_bold;
        line-height: 233.333%;
        letter-spacing: -0.048rem;
        margin-bottom: 3.2rem;
    }

    .uniform-content {
        display: grid;
        grid-template-columns: 28.2rem 1fr;
        align-items: flex-start;
        gap: 2.4rem;

        .edit-card {
            overflow: hidden;

            .card-body {
                max-height: 47rem;
                overflow: hidden auto;
                scrollbar-color: $Neutral-Solid-50 $bg-White;
                scrollbar-width: thin;

                &::-webkit-scrollbar-track {
                    background-color: $bg-White;
                    border-radius: 9rem;
                    -webkit-border-radius: 9rem;
                    -moz-border-radius: 9rem;
                    -ms-border-radius: 9rem;
                    -o-border-radius: 9rem;
                }

                &::-webkit-scrollbar {
                    background-color: $bg-White;
                    width: 0.8rem;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: $Neutral-Solid-50;
                    border-radius: 9rem;
                    -webkit-border-radius: 9rem;
                    -moz-border-radius: 9rem;
                    -ms-border-radius: 9rem;
                    -o-border-radius: 9rem;
                }

                .product-list-card.card-body {

                    .card-img {
                        min-width: 9.6rem;
                        max-width: 9.6rem;
                        padding: 2rem;

                        img {
                            mix-blend-mode: multiply;
                        }
                    }

                }
            }
        }
    }
}

.detail-row {
    border-top: 0.1rem solid $border-neutral-solid;
    opacity: 1;
    margin: 2.4rem 0rem;
}

.product-slides {
    padding: 0rem 0rem 8rem;

    h4.title {
        color: $color_11;
        font-family: $suisse_regular;
        font-size: 4rem;
        font-style: $font_style_1;
        font-weight: $font_weight_middle_bold;
        line-height: 140%;
        letter-spacing: -0.08rem;
        margin-bottom: 3.2rem;
    }

    .product-card {
        // max-width: 28.2rem;
        margin-right: 2.4rem;
    }

    .slick-list.draggable {
        transition: transform 0.6s linear;
        -webkit-transition: transform 0.6s linear;
        -moz-transition: transform 0.6s linear;
        -ms-transition: transform 0.6s linear;
        -o-transition: transform 0.6s linear;
    }

    .slick-arrow {
        width: 3.8rem;
        height: 3.8rem;
        background: $bg_linear2;
        box-shadow: $shadow-2;
        border-radius: 0.6rem;
        -webkit-border-radius: 0.6rem;
        -moz-border-radius: 0.6rem;
        -ms-border-radius: 0.6rem;
        -o-border-radius: 0.6rem;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 1;

        &.slick-next {
            top: -23%;
            right: 10.5%;

            &::before {
                background: url("../../images/slick-arrow-left.svg");
                content: "";
                width: 2rem;
                height: 2rem;
                display: flex;
                background-position: center;
                background-size: auto;
                background-repeat: no-repeat;
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
            }
        }

        &.slick-prev {
            top: -23%;
            left: inherit;
            right: 14%;

            &:before {
                background: url("../../images/slick-arrow-left.svg");
                content: "";
                width: 2rem;
                height: 2rem;
                display: flex;
                background-position: center;
                background-size: auto;
                background-repeat: no-repeat;
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
            }
        }
    }
}

@media screen and (max-width : 1299px) {

    .product-slides {

        .slick-arrow {

            &.slick-next {
                top: -17%;
                right: 10.5%;
            }

            &.slick-prev {
                top: -17%;
                left: inherit;
                right: 15%;
            }
        }
    }

}

@media screen and (max-width : 1199px) {

    .product-slide-content {
        grid-template-columns: 55% auto;
    }

    .descriptions {
        .description-type {
            grid-template-columns: 55% auto;
        }
    }
}

@media screen and (max-width : 991px) {

    .product-slide-content {
        grid-template-columns: 1fr;

        .card.product-card {
            .card-body {
                padding: 5.5rem 2rem 2rem 2rem;

                .product-size-color {
                    .color-size {
                        gap: 1.4rem;
                    }
                }

                .product-quantity {
                    .quantity {
                        .qty-input {
                            width: 100%;
                            justify-content: space-between;
                        }
                    }
                }
            }
        }

        .cart-position {
            border-radius: 1.6rem 1.6rem 0rem 0rem;
            background: $bg-White;
            box-shadow: $shadow-8;
            backdrop-filter: blur(0.5rem);
            padding: 1.6rem;
            position: fixed;
            bottom: 0%;
            width: 100%;
            left: 0%;
            z-index: 99;
            transform: translateY(100px);
            /* Initially position it below the viewport */
            transition: transform 0.3s ease;
            /* Add smooth transition */
        }
    }

    .descriptions {
        .description-type {
            grid-template-columns: 1fr;
        }
    }

    .uniform-kit {
        .uniform-content {
            grid-template-columns: 1fr;
        }
    }

    .remaining-garments {
        .icon {
            width: 5.8rem;
            min-width: 5.8rem;
            height: 5.8rem;
        }

        p {
            margin-right: 1.6rem;
        }
    }

    .product-slides {

        h4.title {
            font-size: 3.2rem;
            margin-bottom: 0rem;
            font-weight: $font_weight_bold;
            line-height: 175%;
            letter-spacing: -0.064rem;
        }

        .product-card {
            margin-right: 0.8rem;

            .card-img {
                height: 18.3rem;
                padding: 3rem;
            }
        }

        .slick-arrow {

            &.slick-next {
                right: 2.5%;
            }

            &.slick-prev {
                right: 9.5%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .uniform-kit {
        .product-list-card.card-body {
            flex-direction: column;
            align-items: flex-start;

            .uniform-md-content {
                display: flex;
                align-self: flex-start;
                gap: 1.2rem;

                .title,
                .price {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.028rem;
                    margin-bottom: 0.8rem;

                    &.sale {
                        color: #710E21;
                    }

                    &.main-price {
                        text-decoration: line-through;
                        color: $color_4;
                        font-size: $text_1;
                    }
                }
            }

            .content {
                flex-direction: row-reverse;
                justify-content: space-between;
                align-items: center;
            }
        }
    }

    .product-slides {

        .slick-arrow {

            &.slick-next {
                right: 1.5rem;
            }

            &.slick-prev {
                right: 6.5rem;
            }
        }
    }
}

@media screen and (max-width: 488px) {
    .uniform-kit {
        .product-list-card.card-body {
            .content {
                flex-direction: column-reverse;
                align-items: flex-start;
            }
        }
    }
}