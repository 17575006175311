@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");

// 1. Include header scss
@import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "./components/base";
@import "./components/table";
@import "./components/header";
@import "./components/aboutPage";
@import "./components/embroidered";
@import "./components/industriesPage";
@import "./components/solutions";
@import "./components/supplyChain";
@import "./components/inHouseCustomisation";
@import "./components/inHouseDecoration";
@import "./components/contactUs";
@import "./components/loginPage";
@import "./components/sideBar";
@import "./components/dashboard";
@import "./components/manageUser-main";
@import "./components/manageTab";
@import "./components/userCard";
@import "./components/modal";
@import "./components/home";
@import "./components/footer";
@import "./components/responsive";
@import "./components/productSlider";
@import "./components/productDetail";
@import "./components/category";
@import "./components/productList";
@import "./components/cart";