section {

  .max-width-container {

    h2 {
      font-size: 4.8rem;
    }

    h3 {
      font-size: 3.2rem;
      line-height: 137.5%;
    }

  }

  .comfort-card {
    background-color: $color_18;

    .card-body {
      padding: 2.4rem;

      span {
        width: 4rem;
        height: 4rem;
        border: 1px solid #9CD1C7;
        background: linear-gradient(180deg, #DDF3EF 0%, rgba(221, 243, 239, 0.00) 120%);
      }

      h4 {
        font-family: $font-inter;
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 155.556%;
      }
    }
  }

  &.transforming {

    .max-width-container {

      .transback {
        &.embroidered {
          min-height: 70rem !important;

          h1 {
            font-family: "suisse_intlregular";
            font-size: 6.4rem;
            letter-spacing: -0.3rem;
            line-height: 8.5rem;
          }
        }
      }
    }
  }

  &.comfort-durable {
    padding: 10rem 0;

    .comfort {

      .head {
        max-width: 95.8rem;
        margin: 0 auto;
        margin-bottom: 4.8rem;

        p {
          line-height: 162.5%;
          letter-spacing: -0.16px;
          color: $color_3;
        }
      }
    }
  }

  &.embroidered-uniform {
    padding: 8rem 0rem;

    .embroid-img {
      border-radius: 1.6rem;
      -webkit-border-radius: 1.6rem;
      -moz-border-radius: 1.6rem;
      -ms-border-radius: 1.6rem;
      -o-border-radius: 1.6rem;
      background: radial-gradient(71.1% 67.06% at 21.45% 100%, rgba(12, 29, 55, 0.20) 0%, rgba(12, 29, 55, 0.00) 100%), url("../../images/embroidered-uniform.png") lightgray 50% / cover no-repeat;
      background-blend-mode: hard-light, normal;
      height: 40rem;
    }

    .text {
      font-size: 1.8rem;
      line-height: 144.444%;
      color: $color_3;
      font-family: $suisse_regular;
      margin-bottom: 2.4rem;
    }

    ul {

      &>:not(:last-child) {
        margin-bottom: 2rem;
      }

      li {

        p {
          font-size: 1.8rem;
          line-height: 155.556%;
          color: #475467;

          span {
            font-size: 1.4rem;
          }
        }
      }
    }
  }

  .workwear-cards {

    .workwear-card {
      background-color: lightgrey;
      border: 0.5rem solid $color_5;
      border-radius: 1rem;
      -webkit-border-radius: 1rem;
      -moz-border-radius: 1rem;
      -ms-border-radius: 1rem;
      -o-border-radius: 1rem;
      box-shadow: 0px 4px 54px 0px rgba(0, 0, 0, 0.25);
      transition: all 0.3s linear;

      img {
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
      }

      p {
        position: absolute;
        bottom: 0;
        background-color: $color_5;
        width: 100%;
        margin: 8px 0;
        width: 98%;
        border-radius: 1rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        -ms-border-radius: 1rem;
        -o-border-radius: 1rem;
        letter-spacing: -0.96px;
        color: rgba(27, 27, 27, 0.85);
        transition: all 0.3s linear;
        padding: 1.6rem 0rem;
        transform: translateX(-50%);
        left: 50%;

        &.big-p {
          font-size: 3.2rem;
          padding: 2rem 0rem;
          line-height: 125%;
        }
      }

      &.active {
        border-color: #F68C1E;

        p {
          background-color: #F68C1E;
          color: $color_5;
        }
      }
    }
  }

  &.weKnow {

    &.embroied {
      background-color: #06273b;

      .contents {
        grid-template-columns: repeat(4, 1fr);
        gap: 3.2rem;
      }

      .shild {
        grid-template-columns: 1fr;
      }
    }
  }

  &.client-say {

    .content {
      font-size: 2.8rem;
      line-height: 164.286%;
      letter-spacing: -0.56px;
      margin-bottom: 1.6rem;
    }

    .title {
      font-size: 2.3rem;
      line-height: 200%;
    }
  }

  &.reqst-quote {

    .max-width-container {
      padding: 9.2rem 0 4rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(36.52% 34.86% at 82.15% 31.8%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);
    }

    .rqst-content {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 6.4rem;
    }
  }

  &.freq-ask {

    .accord-content {
      max-width: 76.8rem;
      margin: 0 auto;
      margin-bottom: 6.4rem;

      .accordion {
        font-family: $font-inter;

        .accordion-item {

          .accordion-header {
            line-height: normal;

            .accordion-button {
              font-size: 1.8rem;
              color: #101828;
              line-height: 155.556%;

              &[aria-expanded="false"] {

                &::after {
                  background-image: url("../../images/plus.svg");
                  width: 2.4rem;
                  height: 2.4rem;
                  background-position: center;
                  background-size: cover;
                }
              }

              &[aria-expanded="true"] {

                &::after {
                  background-image: url("../../images/minus.svg");
                  width: 2.4rem;
                  height: 2.4rem;
                  background-position: center;
                  background-size: cover;
                }
              }
            }
          }

          .accordion-body {
            font-size: 1.6rem;
            color: #475467;
            line-height: 150%;
          }
        }
      }
    }

    .get-touch {
      background: $color_18;
      border-radius: 1.6rem;
      -webkit-border-radius: 1.6rem;
      -moz-border-radius: 1.6rem;
      -ms-border-radius: 1.6rem;
      -o-border-radius: 1.6rem;
      padding: 3.2rem;

      h3{
        font-size: 3.2rem;
        line-height: 171.875%;
        margin-bottom: 3.2rem;
      }
    }
  }
}