.manageTab {
  display: inline-block;
  background: $color_9;
  padding: 0.2rem;
  border-radius: 99rem;
  -webkit-border-radius: 99rem;
  -moz-border-radius: 99rem;
  -ms-border-radius: 99rem;
  -o-border-radius: 99rem;
  margin-bottom: 2.4rem;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    list-style: none;
    margin-bottom: 0rem;
    padding: 0rem;

    li {
      .nav-link {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 0.6rem;
        padding: 0.9rem 1.6rem;
        border: 0rem;
        color: $color_2;
        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: $lh_1;
        border-radius: 99rem;
        -webkit-border-radius: 99rem;
        -moz-border-radius: 99rem;
        -ms-border-radius: 99rem;
        -o-border-radius: 99rem;

        span {
          color: $color_7;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          width: 2.5rem;
          height: 2.5rem;
          display: flex;
          align-items: center;
          justify-content: center;
          background: $Neutral-Alpha-25;
          border-radius: 9.6rem;
          -webkit-border-radius: 9.6rem;
          -moz-border-radius: 9.6rem;
          -ms-border-radius: 9.6rem;
          -o-border-radius: 9.6rem;
        }

        &:hover {
          background: $bg_linear2;
          box-shadow: $shadow-3;
          color: $color_6;
          border-radius: 99rem;
          -webkit-border-radius: 99rem;
          -moz-border-radius: 99rem;
          -ms-border-radius: 99rem;
          -o-border-radius: 99rem;
        }

        &.active {
          background: $bg_linear2;
          box-shadow: $shadow-3;
          color: $color_6;
          border-radius: 99rem;
          -webkit-border-radius: 99rem;
          -moz-border-radius: 99rem;
          -ms-border-radius: 99rem;
          -o-border-radius: 99rem;
        }
      }
    }
  }
}

.employee-tab {
  .manageTab {
    display: block;
   
    ul {
      white-space: nowrap;
      flex-wrap: nowrap;
      overflow: auto hidden;
      scrollbar-color: $Neutral-Solid-50 $bg-White;
      scrollbar-width: none;

      &::-webkit-scrollbar-track {
        background-color: $bg-White;
        border-radius: 9rem;
        -webkit-border-radius: 9rem;
        -moz-border-radius: 9rem;
        -ms-border-radius: 9rem;
        -o-border-radius: 9rem;
      }

      &::-webkit-scrollbar {
        background-color: $bg-White;
        width: 0.8rem;
      }

      &::-webkit-scrollbar-thumb {
        background-color: $Neutral-Solid-50;
        border-radius: 9rem;
        -webkit-border-radius: 9rem;
        -moz-border-radius: 9rem;
        -ms-border-radius: 9rem;
        -o-border-radius: 9rem;
      }
    }
  }

}

@media screen and (max-width: 991px) {
  .manageTab {
    display: block;

    ul {
      flex-wrap: nowrap;
      overflow: auto hidden;

      li {
        width: 100%;

        .nav-link {
          width: 100%;
          white-space: nowrap;
        }
      }
    }
  }
}