section {
  &.solutsec {
    padding-top: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(65.8% 62.46% at 97.64% 48.25%, #ffddba 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(120.82% 115.83% at 72.19% 74.45%, #eef9fd 0%, #eef9fd 24.36%, rgba(255, 255, 255, 0) 100%), #fff;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      margin-bottom: 11rem;

      .sheads {
        h1 {
          color: $color_6;
          font-size: 80px;
          line-height: 110%;
          letter-spacing: -1.6px;
        }

        p {
          max-width: 67.8rem;
          color: $color_2;
          font-size: 1.8rem;
          font-weight: 400;
          line-height: 155.556%;
          letter-spacing: -0.36px;
        }
      }

      .content {
        padding: 7.2rem 8rem;
        align-items: center;
        position: relative;
        border-radius: 16px;
        z-index: 1;
        display: grid;
        grid-template-columns: 54.6rem 34.6rem;
        margin-top: 8.8rem;
        background: $color_5;
        box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
        margin-bottom: -11rem;
        justify-content: space-between;

        h2 {
          font-family: $suisse_medium;
          font-size: 4rem;
          line-height: 110%;
          letter-spacing: -0.8px;
          margin-bottom: 1.6rem;
        }

        p {
          color: $color_2;
          font-size: 1.6rem;
          line-height: 150%;
          letter-spacing: -0.16px;
        }

        .mainbox {
          border-radius: 16px;
          position: relative;
          border: 1px solid var(--Neutral-Solid-50, #ECEFF3);
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: 20.5rem;
          background-image: url('../../images/managepartabg.svg');
          background-repeat: no-repeat;
          background-position: center;

          button {
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.12) 0%, rgba(255, 255, 255, 0.00) 100%), #FF6B18;
            box-shadow: 0px 0px 0px 0.749px #FF6B18, 0px 0.749px 1.497px 0px rgba(161, 65, 11, 0.50);
            height: 3.6rem;
            width: 3.6rem;
            border-radius: 12px;
            border: 1px solid #ECEFF3;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .img-boxa {
            position: absolute;
            width: 6.4rem;
            top: 4.7rem;
            height: 6.4rem;
            border-radius: 50%;
            border: 2px solid rgba(175, 175, 175, 0.10);
            left: 0;
            transform: translateX(-50%);
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .img-boxb {
            position: absolute;
            width: 6.4rem;
            height: 6.4rem;
            transform: translateY(-50%);
            top: 0;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid rgba(175, 175, 175, 0.10);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .img-boxc {
            position: absolute;
            width: 6.4rem;
            height: 6.4rem;
            right: 0;
            bottom: 3rem;
            transform: translateX(50%);
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid rgba(175, 175, 175, 0.10);

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .credbox {
            position: absolute;
            bottom: 0;
            display: flex;
            align-items: center;
            border-radius: 12px;
            background: $color_5;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
            padding: 1.6rem;
            transform: translateY(50%);

            .radialProgressBar.progress-70 {
              padding: .2rem;
            }

            .infos {
              margin-left: 0.612rem;

              .blance {
                color: $color_6;
                font-family: Inter;
                font-size: 12px;
                font-style: normal;
                opacity: 0.7;
                font-weight: 400;
                line-height: normal;
                letter-spacing: -0.24px;
              }

              .qgar {
                color: $color_6;
                font-size: 1.2rem;
                font-weight: $font_weight_bold;
                line-height: normal;
                letter-spacing: -0.24px;
              }
            }
          }
        }
      }

      &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('../../images/Background-pattern.png');
        z-index: 0;
        background-position: center right;
      }
    }
  }

  &.solutsecabs {
    .minhead {
      margin-bottom: 2.4rem;
    }

    h4 {
      max-width: 103.6rem;
      color: $color_11;
      text-align: center;
      font-size: 3.2rem;
      font-style: normal;
      margin: auto;
      font-weight: 450;
      line-height: 150%;
      letter-spacing: -0.64px;
    }
  }

  &.stget {
    .container {
      &>.content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.4rem;
      }

      .content {
        .infoleft {
          padding: 5.6rem 4.8rem;
          border-radius: 24px;
          background: linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          &>p {
            color: $color_2;
            font-size: 1.6rem;
            line-height: 150%;
            letter-spacing: -0.16px;
            padding-bottom: 3.2rem;
          }

          .imginfo {
            min-height: 32.4rem;
            border: 1px solid transparent;

            .topimgbox {
              margin-top: 8rem;
              border: 1px solid transparent;
              position: relative;
              z-index: 2;
              min-height: 6.3rem;
              max-width: 38.5rem;
              margin-left: 4.8rem;
              margin-bottom: 7.9rem;

              &::after {
                content: '';
                width: 100%;
                height: 100%;
                background: url('../../images/stream1bg.svg');
                z-index: -1;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                top: 0;
                left: -12px;
                position: absolute;
              }

              .imgsa {
                height: 6.4rem;
                position: absolute;
                border-radius: 50%;
                top: 0;
                transform: translate(-50%, -100%);
                left: 0;
                overflow: hidden;
                border: 2px solid rgba(175, 175, 175, 0.10);
                width: 6.4rem;
                display: inline-block;

                img {
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }
              }

              button {
                &.btn {
                  border-radius: 6px;
                  background: $color_5;
                  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
                  margin: auto;
                  position: relative;
                  padding: .7rem 1.2rem;
                  transform: translate(25%, -50%);
                  color: $color_2;
                  font-style: normal;
                  line-height: 142.857%;

                  &:hover {
                    background: #d3d4d5;
                  }
                }
              }

              .buttonaddcart {
                border-radius: 16px;
                background: $color_5;
                cursor: pointer;
                box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
                position: absolute;
                width: 6.4rem;
                height: 6.4rem;
                right: -4.4rem;
                bottom: .8rem;
                z-index: 1;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                  background: #d3d4d5;
                }

                .cartqty {
                  position: absolute;
                  height: 2.4rem;
                  width: 2.4rem;
                  top: -8px;
                  right: -8px;
                  border-radius: 50%;
                  background: #FF6B18;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .qty {
                    color: #FFF;
                    font-size: 1.2rem;
                    line-height: normal;
                    letter-spacing: -0.24px;
                  }
                }
              }
            }

            .bottomimgbox {
              position: relative;

              .infobox {
                position: relative;
                max-width: 31.2rem;
                margin: 0 3.3rem 0 auto;
                z-index: 1;

                .content {
                  display: flex;
                  justify-content: space-between;
                  margin-bottom: .8rem;

                  p {
                    &.sono {
                      color: #0D0D12;
                      font-size: 1.4rem;
                      font-weight: $font_weight_bold;
                      line-height: normal;
                    }
                  }
                }

                .pros {
                  display: flex;
                  justify-content: space-between;

                  p {
                    &.sotime {
                      color: $color_2;
                      font-size: 1.4rem;
                      line-height: normal;
                    }
                  }

                  .amount {
                    color: $color_2;
                    font-size: 1.4rem;
                    line-height: normal;
                  }
                }

                .backcards {
                  position: absolute;
                  height: 100%;
                  width: 100%;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                  max-width: 27.2rem;
                  min-height: 10.2rem;
                  border-radius: 13.949px;
                  opacity: 0.8;
                  background: $color_5;
                  padding: 1.74rem;
                  box-shadow: 0px 0px 0px 0.872px rgba(18, 55, 105, 0.12), 0px 10.462px 12.205px -6.103px rgba(164, 172, 185, 0.16);
                  z-index: -1;
                }

                .maincards {
                  padding: 2rem;
                  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
                  max-width: 31.2rem;
                  background: $color_5;
                  border-radius: 1.6rem;
                }
              }

              &::after {
                content: '';
                position: absolute;
                top: -81px;
                left: 56px;
                width: 95px;
                height: 129px;
                background: url('../../images/stream2bg.svg');
                background-position: center -2px;
                background-repeat: no-repeat;
                background-size: contain;
              }
            }
          }
        }

        .inforight {
          padding: 5.6rem 4.8rem;
          border-radius: 24px;
          overflow: hidden;
          background: linear-gradient(180deg, #EEF5FF 0%, #D9E8FF 100%), linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);
          position: relative;

          h4 {
            margin-right: 5.3rem;
          }

          &>p {
            margin-right: 5.3rem;
            color: $color_2;
            font-size: 1.6rem;
            line-height: 150%;
            letter-spacing: -0.16px;
            padding-bottom: 3.2rem;
          }

          .imginfo {
            border: 1px solid transparent;
            min-height: 32rem;
            position: relative;

            .top-img-box {
              margin-top: 44px;
              min-height: 13.8rem;
              margin-left: 3.2rem;
              position: relative;
              margin-right: 7.5rem;
              z-index: 2;

              &::after {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: -1;
                top: -7px;
                left: -13px;
                background: url('../../images/getinforbg1.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }

              &::before {
                content: '';
                position: absolute;
                width: 353px;
                height: 66.5px;
                z-index: -1;
                top: 7px;
                left: 240px;
                background: url('../../images/getinforbg3.svg');
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
              }

              button {
                &.btn {
                  &.btn-primary {
                    margin: auto;
                    transform: translateY(-50%);
                    position: relative;
                    z-index: 1;
                  }
                }
              }

              .card-contents {
                position: absolute;
                display: grid;
                top: 4rem;
                grid-gap: 2.4rem;
                grid-template-columns: 1fr 1fr;
                padding-left: 2.4rem;

                &::after {
                  content: "";
                  position: absolute;
                  left: 6rem;
                  top: 15rem;
                  z-index: -1;
                  width: 38.5rem;
                  height: 8.1rem;
                  background: url(../../images/getinforbg4.svg);
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                }

                .cardnew {
                  min-height: 18.4rem;
                  min-width: 32.9rem;
                  padding: 2rem;
                  border-radius: 16px;
                  background: $color_5;
                  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);

                  .roundicon {
                    border-radius: 99999px;
                    border: 1px solid #ECEFF3;
                    background: linear-gradient(180deg, #F8FAFB 0%, rgba(248, 250, 251, 0.00) 120%);
                    backdrop-filter: blur(2.5px);
                    display: flex;
                    height: 6rem;
                    width: 6rem;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 1.6rem;

                    span {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      height: 3.6rem;
                      border-radius: 999px;
                      background: var(--Form-Button-Secondary-Normal, linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF);
                      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24), 0px 9px 16px -4px rgba(0, 0, 0, 0.13), 0px -7px 10px -5px rgba(0, 0, 0, 0.06) inset;
                      width: 3.6rem;
                    }
                  }

                  .conhead {
                    p {
                      color: $color_6;
                      font-size: 1.4rem;
                      font-weight: $font_weight_bold;
                      line-height: 171.429%;
                      letter-spacing: -0.14px;
                      margin-bottom: .4rem;
                    }

                    .lineloader {
                      div {
                        &:nth-child(1) {
                          border-radius: 32px;
                          background: linear-gradient(90deg, #EDEDF0 0%, rgba(237, 237, 240, 0.00) 100%);
                          height: .8rem;
                          max-width: 26.1rem;
                          margin-bottom: .8rem;
                        }

                        &:nth-child(2) {
                          border-radius: 32px;
                          background: linear-gradient(90deg, #EDEDF0 0%, rgba(237, 237, 240, 0.00) 100%);
                          height: 0.8rem;
                          max-width: 15.4rem;
                          margin-bottom: 0.8rem;
                        }

                        &:nth-child(3) {
                          border-radius: 32px;
                          background: linear-gradient(90deg, #EDEDF0 0%, rgba(237, 237, 240, 0.00) 100%);
                          height: .8rem;
                          max-width: 19.2rem;
                        }
                      }
                    }
                  }
                }

                .bottom-card-contents {
                  position: absolute;
                  top: 110%;
                  left: 11.4rem;
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                  grid-gap: 2.4rem;
                }
              }
            }
          }

          &::after {
            content: '';
            position: absolute;
            bottom: -1.8rem;
            right: 0;
            transform: rotate(90deg);
            height: 32.8rem;
            width: 32.8rem;
            z-index: 11;
            background: linear-gradient(180deg, #EDEDFF 0%, rgba(237, 237, 255, 0.00) 100%);
          }
        }

        h4 {
          font-family: $suisse_medium;
          color: $color_6;
          font-size: 4rem;
          line-height: 110%;
          letter-spacing: -0.8px;
          margin-bottom: 1.6rem;
        }
      }
    }
  }

  &.solslid {
    .mdslide {
      padding: 4rem 0;
      background: url('../../images/Background-pattern.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: inherit;

      .sliding {
        display: flex;
        align-items: center;
        justify-content: center;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;

          li {
            height: 8rem;
            width: 8rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            border: 1px solid #FFF;
            background: $color_5;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
          }

          &.leftimgs {
            li {
              margin-right: -15px;
            }
          }

          &.rightimgs {
            li {
              margin-left: -15px;
              z-index: revert-layer;
            }
          }
        }

        span {
          z-index: 1;
          display: flex;
          width: 11.2rem;
          height: 10.8rem;
          align-items: center;
          justify-content: center;
        }
      }

      .btmsbr {
        text-align: center;
        margin-top: 2.4rem;
      }
    }

    .container {
      &>p {
        color: $color_2;
        text-align: center;
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.16px;
        max-width: 77.9rem;
        margin: 1.6rem auto;
      }

      h2 {
        color: $color_6;
        text-align: center;
        font-family: $suisse_medium;
        font-size: 4rem;
        font-style: normal;
        line-height: 110%;
        letter-spacing: -0.08rem;
      }
    }
  }

  &.taylorsec {
    .max-width-container {
      .container {
        display: grid;
        background: linear-gradient(180deg, #E9F3FD 0%, #EBFCF9 100%), linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);
        border-radius: 24px;
        grid-gap: 3.2rem;
        grid-template-columns: 1fr 60.1rem;
        padding: 7.2rem 8rem;

        .content {
          h2 {
            color: $color_6;
            font-family: $suisse_medium;
            font-size: 4rem;
            line-height: 110%;
            letter-spacing: -0.8px;
            margin-bottom: 1.6rem;
          }

          p {
            color: var(--Neutral-Solid-600, #36394A);
            line-height: 150%;
            letter-spacing: -0.16px;
          }
        }

        .imgboxinfo {
          .plsec {
            position: relative;
            margin: 8.35rem 12.4rem 4.75rem 12.2rem;
            min-height: 24rem;
            border-radius: 1.6rem;
            border: 1px solid #DEEBEF;

            .plusbbtn {
              border-radius: 12px;
              background: linear-gradient(180deg, #E9F3FD 0%, #EBFCF9 100%), #FFF;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;
              min-height: 7.9rem;
              z-index: 2;

              &::after {
                content: '';
                position: absolute;
                height: 100%;
                width: 100%;
                z-index: -1;
                left: 0;
                top: 0;
                background: url('../../images/tailobg.svg');
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
              }
            }

            .clientinfo {
              padding: 0 1.6rem 1.6rem 1.6rem;
              margin-top: -3.2rem;
              position: relative;
              z-index: 2;

              .img-box {
                background: $color_5;
                display: inline-block;
                padding: 4px;
                border-radius: 50%;
                margin-bottom: .8rem;

                img {
                  width: 6.4rem;
                  height: 6.4rem;
                  object-fit: cover;
                  border-radius: 50%;
                  border: 2px solid rgba(175, 175, 175, 0.10);
                }
              }

              .clname {
                color: $color_2;
                font-size: 1.6rem;
                font-weight: $font_weight_bold;
                line-height: 150%;
                letter-spacing: -0.32px;
                margin-bottom: .1rem;
              }

              .clposition {
                color: $color_2;
                font-size: $text_1;
                line-height: 157.143%;
                letter-spacing: -0.14px;
              }
            }

            .departinnerbox {
              position: absolute;
              width: 20.8rem;
              left: 50%;
              min-height: 12.7rem;
              padding: 0.4rem;
              z-index: 1;
              top: 2.5rem;
              transform: translate(-50%, 0%);
              border-radius: 1.6rem;
              background: $color_5;
              z-index: 1;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);

              .nmesw {
                display: grid;
                grid-template-columns: 5rem auto;

                .innames {
                  .nmde {
                    color: $color_2;
                    font-size: 1.34rem;
                    font-weight: $font_weight_bold;
                    line-height: 150%;
                    letter-spacing: -0.268px;
                  }

                  .nmpro {
                    color: $color_2;
                    font-size: 1.17rem;
                    font-weight: 400;
                    line-height: 157.143%;
                    letter-spacing: -0.117px;
                  }
                }

                .img-box {
                  background: $color_5;
                  display: inline-block;
                  padding: 4px;
                  border-radius: 50%;

                  img {
                    width: 5rem;
                    height: 5rem;
                    object-fit: cover;
                    border-radius: 50%;
                    border: 2px solid rgba(175, 175, 175, 0.10);
                  }
                }
              }
            }

            .departde {
              position: absolute;
              width: 24.8rem;
              left: 50%;
              min-height: 19.8rem;
              padding: .4rem;
              z-index: 11;
              top: -5.5rem;
              transform: translate(-50%, 0%);
              border-radius: 1.6rem;
              background: $color_5;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
            }

            .poabsolutebox {
              .otherimg {
                position: absolute;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;

                .img-box {
                  height: 6.6rem;
                  width: 6.6rem;
                  border-radius: 50%;
                  background: $color_5;
                  box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25), 0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04), 0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
                  backdrop-filter: blur(3.743654727935791px);
                  padding: 0.8rem;
                  margin: auto;

                  img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                    object-fit: cover;
                  }
                }

                span {
                  border-radius: 16px;
                  background: $color_5;
                  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
                  display: inline-block;
                  padding: .4rem .8rem;
                  color: $color_2;
                  font-size: 1.2rem;
                  font-weight: $font_weight_bold;
                  line-height: 166.667%;
                  margin-top: -11px;
                  position: relative;
                }
              }

              .imgposa {
                bottom: 3.3rem;
                left: -6.1rem;
              }

              .imgposb {
                right: -5.2rem;
                top: -2.2rem;
              }

              .imgposc {
                bottom: 0;
                left: 50%;
                transform: translate(-50%, 50%);
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1199px) {
  section {
    &.solutsec {
      .max-width-container {
        .content {
          padding: 3.2rem 5.4rem;
          grid-template-columns: 1fr 1fr;
          grid-gap: 7rem;
        }
      }
    }

    &.stget {
      .container {
        .content {
          .infoleft {
            .imginfo {
              .bottomimgbox {
                .infobox {
                  margin: 0px 3.3rem 0 15rem;
                }
              }
            }
          }
        }
      }
    }

    &.taylorsec {
      .max-width-container {
        .container {
          grid-template-columns: 1fr;
        }
      }
    }
  }


}

@media only screen and (max-width: 1024px) {
  section {
    &.solutsec {
      .max-width-container {
        padding-top: 4rem;

        .content {
          grid-template-columns: 1fr;
          padding: 3.2rem 2.4rem 6rem;
          margin-top: 4rem;
          margin-right: -2.2rem;
          margin-left: -2.2rem;

          h2 {
            font-size: 3.2rem;
          }

          .items {
            &:last-child {
              padding: 0 4rem;
              max-width: 425px;
              width: 100%;
              margin: auto;
            }
          }
        }

        .sheads {
          h1 {
            font-size: 4rem;
          }
        }
      }
    }

    &.taylorsec {
      .max-width-container {
        .container {
          padding: 5.6rem 4.8rem;

          .imgboxinfo {
            .plsec {
              margin: 8.35rem 5.4rem 4.75rem 5.2rem;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 991px) {
  section {
    &.stget {
      .container {
        &>.content {
          grid-template-columns: 1fr;
        }

        .content {
          .infoleft {
            .imginfo {
              max-width: 80%;
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:767px) {
  section {
    &.solutsec {
      .max-width-container {
        .sheads {
          h1 {
            margin-bottom: .8rem;
          }
        }

        .content {
          .mainbox {
            min-height: 16.4rem;
            background-size: contain;

            .img-boxb {
              width: 5.1rem;
              height: 5.1rem;
            }

            .img-boxa {
              width: 5.1rem;
              height: 5.1rem;
            }

            .img-boxc {
              width: 5.1rem;
              height: 5.1rem;
            }
          }

          .items {
            &:last-child {
              padding: 0 2rem;
            }
          }
        }
      }
    }

    &.solutsecabs {
      padding-top: 0;

      .max-width-container {
        padding-right: 0;
        padding-left: 0;
        padding-bottom: 0;
      }

      h4 {
        text-align: left;
        font-size: 2.4rem;
        line-height: 133.333%;
      }
    }

    &.stget {
      .container {
        .content {
          h4 {
            font-size: 3.2rem;
          }

          .infoleft {
            padding: 3.2rem 2.4rem;

            .imginfo {
              min-height: auto;
              max-width: 27.8rem;

              .topimgbox {
                max-width: 100%;
                min-height: 6.3rem;
                margin-left: 2.7rem;
                margin-bottom: 0;
                margin-right: 3.53rem;

                .imgsa {
                  height: 3.8rem;
                  width: 3.8rem;
                }

                button {
                  &.btn {
                    font-size: .83rem;
                    padding: .42rem .72rem;
                    line-height: 142.857%;
                  }
                }

                .buttonaddcart {
                  height: 3.8rem;
                  width: 3.8rem;
                  border-radius: .95rem;
                  right: -2.6rem;
                  bottom: 2.6rem;

                  svg {
                    width: 1.4rem;
                    height: 1.4rem;
                  }

                  .cartqty {
                    height: 1.4rem;
                    width: 1.4rem;

                    .qty {
                      font-size: .71rem;
                    }
                  }
                }

                &::after {
                  left: -4px;
                  height: 3.7rem;
                }
              }

              .bottomimgbox {
                text-align: right;
                margin-top: 2.5rem;

                .infobox {
                  display: inline-block;
                  min-width: 18.7rem;
                  margin: auto;

                  .maincards {
                    padding: 1.2rem;
                    max-width: 18.7rem;
                    margin: 0;
                  }

                  .backcards {
                    padding: 1.04rem;
                    max-width: 16.3rem;
                    min-height: 6.1rem;
                  }

                  .content {
                    margin-bottom: .48rem;

                    p {
                      &.sono {
                        font-size: .83rem;
                        line-height: normal;
                      }
                    }

                    span {
                      &.badge {
                        &.bg-aqua-25 {
                          font-size: .71rem;
                          line-height: 133.333%;

                          svg {
                            width: 1.2rem;
                            height: 1.2rem;
                          }
                        }
                      }
                    }
                  }

                  .pros {
                    p {
                      &.sotime {
                        font-size: .83rem;
                        line-height: normal;
                      }
                    }

                    .amount {
                      font-size: .83rem;
                      line-height: normal;
                    }
                  }
                }

                &::after {
                  top: -51px;
                  left: 35px;
                  width: 45px;
                  height: 82px;
                }
              }
            }
          }

          .inforight {
            padding: 3.2rem 2.4rem;
            padding-bottom: 2rem;

            &>p {
              margin-right: 0;
            }

            h4 {
              margin-right: 0;
            }

            &::after {
              width: 23.6rem;
              height: 23.6rem;
              bottom: -2.8rem;
            }

            .imginfo {
              min-height: 26rem;

              .top-img-box {
                margin-top: 44px;
                min-height: 13.8rem;
                margin-left: 0.2rem;
                margin-right: 0;

                .card-contents {
                  .cardnew {
                    padding: 1.2rem;
                    min-height: 11rem;

                    .roundicon {
                      width: 3.6rem;
                      height: 3.6rem;
                      margin-bottom: 1rem;

                      span {
                        width: 2.15rem;
                        height: 2.15rem;

                        svg {
                          width: 1.2rem;
                        }
                      }
                    }

                    .conhead {
                      p {
                        margin-bottom: .25rem;
                      }

                      .lineloader {
                        div {
                          &:nth-child(1) {
                            margin-bottom: .5rem;
                          }

                          &:nth-child(2) {
                            margin-bottom: .5rem;
                          }
                        }
                      }
                    }
                  }
                }

                &::after {
                  top: -23px;
                  left: -12px;
                  width: 268px;
                }
              }
            }
          }
        }
      }

      .max-width-container {
        padding: 0;
      }
    }

    &.solslid {
      .mdslide {
        .sliding {
          display: grid;
          grid-template-columns: 1fr 7rem 1fr;
          gap: 0.5rem;

          ul {
            li {
              height: 5rem;
              width: 5rem;

              img {
                width: 2.5rem;
              }
            }
          }

          span {
            width: 7rem;
            display: block;
            height: 7rem;
          }
        }
      }

      .max-width-container {
        padding: 0;
      }

      .container {
        h2 {
          font-size: 3.2rem;
        }
      }
    }

    &.taylorsec {
      .max-width-container {
        padding: 0;

        .container {
          padding: 3.2rem 2.4rem;

          .content {
            h2 {
              font-size: 3.2rem;
            }
          }

          .imgboxinfo {
            .plsec {
              margin: 5.2rem 4rem 2.9rem 3.8rem;
              min-height: 14.6rem;

              .departde {
                width: 15.2rem;
                min-height: auto;
                padding: .25rem;
              }

              .clientinfo {
                padding: 1rem;

                .img-box {
                  padding: 2px;
                  margin-bottom: .5rem;

                  img {
                    width: 4rem;
                    height: 4rem;
                  }
                }

                .clname {
                  font-size: 1rem;
                  line-height: 150%;
                }

                .clposition {
                  font-size: .85rem;
                  line-height: 157.143%;
                }
              }

              .poabsolutebox {
                .otherimg {
                  .img-box {
                    width: 4rem;
                    height: 4rem;
                    padding: .5rem;
                  }

                  span {
                    font-size: .73rem;
                    padding: .24rem .45rem;
                  }
                }

                .imgposb {
                  right: -3.2rem;
                }

                .imgposa {
                  left: -3.1rem;
                }
              }

              .plusbbtn {
                min-height: 4.83rem;

                img {
                  width: 2.7rem;
                  height: 2.7rem;
                }
              }

              .departinnerbox {
                width: 12.7rem;
                min-height: 7.7rem;
                top: -0.5rem;

                .nmesw {
                  grid-template-columns: 4rem auto;

                  .img-box {
                    img {
                      width: 3rem;
                      height: 3rem;
                    }
                  }

                  .innames {
                    .nmde {
                      font-size: 1rem;
                      line-height: 150%;
                    }

                    .nmpro {
                      font-size: 0.85rem;
                      line-height: 157.143%;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 576px) {

  section {

    &.stget {
      .container {
        .content {
          .inforight {
            padding: 3.2rem 2.4rem;
            padding-bottom: 2rem;

            &::after {
              bottom: 0rem;
            }

            .imginfo {
              min-height: 30rem;

              .top-img-box {

                .card-contents {
                  grid-gap: 1.4rem;
                  padding-left: 0rem;

                  .cardnew {
                    min-width: 20rem;
                  }

                  .bottom-card-contents {
                    left: 4.4rem;
                    grid-gap: 1.4rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

}