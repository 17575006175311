.main-content {
  margin-top: 8.38rem;
  position: relative;

  .main-right-content {
    padding: 3.2rem;
    margin-left: 22rem;

    .slogon {
      color: $color_3;

      font-size: $text_2;
      font-style: $font_style_1;
      font-weight: $font_weight_normal;
      line-height: $lh_1;
      letter-spacing: -0.014rem;
    }

    .userName {
      color: $color_1;

      font-size: $text_5;
      font-style: $font_style_1;
      font-weight: $font_weight_bold;
      line-height: $lh_1;
      margin-bottom: 2.4rem;
    }

    .progress-garments {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1.2rem;

      p {
        color: $color_6;
        text-align: center;
        font-family: $font-inter;
        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: $lh_1;
        letter-spacing: -0.014rem;
        margin-bottom: 0rem;

        span {
          color: $color_3;
          margin-right: 0.4rem;
          font-weight: $font_weight_normal;
        }
      }
    }

    .dash-card {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 1.6rem;
      margin-bottom: 1.6rem;

      .card {
        background: $bg_linear2;
        box-shadow: $shadow-2;
        border-radius: $radius5;
        -webkit-border-radius: $radius5;
        -moz-border-radius: $radius5;
        -ms-border-radius: $radius5;
        -o-border-radius: $radius5;

        .card-body {
          padding: $padding-24;
          row-gap: 1.6rem;

          .card-logo {
            width: 2rem;
            height: 2rem;
          }

          .title {
            @extend .slogon;
            margin-bottom: 0.8rem;
          }

          .counts {
            @extend .userName;
            letter-spacing: -0.02rem;
            margin-bottom: 0rem;
          }

          .badge.bg-green-25 {
            background-color: $Secondary-Green-25;
            border-radius: 9.6rem;
            -webkit-border-radius: 9.6rem;
            -moz-border-radius: 9.6rem;
            -ms-border-radius: 9.6rem;
            -o-border-radius: 9.6rem;
            color: $Secondary-Green-300;
            text-align: center;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh_2;
            padding: 0.2rem 0.8rem;
          }

          .growth {
            @extend .slogon;
            font-size: $text_2;
            letter-spacing: -0.012rem;
            margin: 0rem 0rem 0rem 0.4rem;
          }

          .btn.dash-btn {
            background: $bg_linear2;
            box-shadow: $shadow-2;
            border-radius: 0.6rem;
            -webkit-border-radius: 0.6rem;
            -moz-border-radius: 0.6rem;
            -ms-border-radius: 0.6rem;
            -o-border-radius: 0.6rem;
            color: $color_2;
            text-align: center;

            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh-3;
          }
        }
      }
    }

    .product-order {
      @extend .dash-card;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 4.8rem;

      .card-head {
        padding: $padding-20;
        border-bottom: 1px solid $border-neutral-solid;

        .head-gap {
          column-gap: 1.6rem;

          span {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2rem;
            height: 2rem;
          }

          h4 {
            @extend .slogon;
            color: $color_1;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0rem;
            font-weight: $font_weight_bold;

            p {
              color: $color_7;
              text-align: center;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh-3;
              background: $bg_light;
              border-radius: 9.6rem;
              -webkit-border-radius: 9.6rem;
              -moz-border-radius: 9.6rem;
              -ms-border-radius: 9.6rem;
              -o-border-radius: 9.6rem;
              padding: 0.2rem 0.8rem;
              margin: 0rem 0rem 0rem 0.4rem;
            }
          }

          .btn {
            border-radius: 0.6rem;
            -webkit-border-radius: 0.6rem;
            -moz-border-radius: 0.6rem;
            -ms-border-radius: 0.6rem;
            -o-border-radius: 0.6rem;
            color: $color_2;
            text-align: center;

            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: $lh-3;
            padding: 0.6rem 0.8rem;
          }

          .btn.dash-btn {
            @extend .btn;
            background: $bg_linear2;
            box-shadow: $shadow-2;
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.6rem;
          }

          .dropdown.top-product {
            position: relative;

            .btn.dash-btn {
              &::after {
                content: none;
              }

            }

            .btn.dash-btn[aria-expanded="false"] {
              span {
                svg {
                  transform: rotate(0deg);
                  -webkit-transform: rotate(0deg);
                  -moz-transform: rotate(0deg);
                  -ms-transform: rotate(0deg);
                  -o-transform: rotate(0deg);
                  transition: all 0.3s ease-in;
                  -webkit-transition: all 0.3s ease-in;
                  -moz-transition: all 0.3s ease-in;
                  -ms-transition: all 0.3s ease-in;
                  -o-transition: all 0.3s ease-in;
                }
              }
            }

            .btn.dash-btn[aria-expanded="true"] {
              span {
                svg {
                  transform: rotate(180deg);
                  -webkit-transform: rotate(180deg);
                  -moz-transform: rotate(180deg);
                  -ms-transform: rotate(180deg);
                  -o-transform: rotate(180deg);
                  transition: all 0.3s ease-in;
                  -webkit-transition: all 0.3s ease-in;
                  -moz-transition: all 0.3s ease-in;
                  -ms-transition: all 0.3s ease-in;
                  -o-transition: all 0.3s ease-in;
                }
              }
            }

            .dropdown-menu {
              background: $Toast-BG-Default;
              box-shadow: $shadow-5;
              backdrop-filter: blur(0.5rem);
              padding: 0.8rem 0rem;
              border: 0rem;
              border-radius: 1rem;
              -webkit-border-radius: 1rem;
              -moz-border-radius: 1rem;
              -ms-border-radius: 1rem;
              -o-border-radius: 1rem;
              min-width: 8rem;


              li {
                width: 100%;

                .dropdown-link {
                  color: $color_3;
                  font-size: $text_1;
                  font-style: $font_style_1;
                  font-weight: $font_weight_bold;
                  line-height: 133.333%;
                  padding: 0.8rem 1.2rem;
                  margin-bottom: 0rem;
                  display: block;

                  &:hover,
                  &:active {
                    color: $color_5;
                    background: #f68c1e;
                  }
                }
              }
            }
          }
        }
      }

      .card-body {
        padding: $padding-20;
        max-height: 60rem;
        overflow: hidden auto;
        scrollbar-color: $Neutral-Solid-50 $bg-White;
        scrollbar-width: none;

        &::-webkit-scrollbar {
          width: 0px;
        }

        .order-approval {
          margin-bottom: 1.6rem;
          padding-bottom: 1.6rem;
          border-bottom: 1px solid $border-neutral-solid;

          &:last-child {
            margin-bottom: 0rem;
            padding-bottom: 0rem;
            border-bottom: 0rem;
          }

          .approval-content {
            h4 {
              @extend .slogon;
              color: $color_1;
              letter-spacing: 0rem;
              margin-bottom: 0.8rem;
            }

            h5 {
              color: $color_3;

              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: $lh_1;
              margin-bottom: 0.8rem;
            }
          }

          .approval-btns {
            row-gap: 1.6rem;
            z-index: 2;

            p {
              color: $color_3;

              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
            }

            .btn {
              background: $bg_linear2;
              box-shadow: $shadow-2;
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              padding: 0.6rem;
            }
          }
        }

        .orders {
          @extend .order-approval;
          margin-bottom: 0.8rem;
          display: flex;
          align-items: center;
          column-gap: 1.6rem;

          &:last-child {
            margin-bottom: 0rem;
            padding-bottom: 0rem;
            border-bottom: 0rem;
          }

          .order-img {
            padding: 0.44rem 1.5rem;
            background: $color_8;
            border-radius: 0.6rem;
            -webkit-border-radius: 0.6rem;
            -moz-border-radius: 0.6rem;
            -ms-border-radius: 0.6rem;
            -o-border-radius: 0.6rem;
            max-width: 9rem;

            img {
              mix-blend-mode: multiply;
            }
          }

          .order-content {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
              color: $color_1;

              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh_1;
              margin-bottom: 0.4rem;
            }

            p {
              color: $color_3;

              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh_1;
              letter-spacing: 0.024rem;
              text-transform: uppercase;
            }

            .order-price {
              span {
                background: $bg_light;
                padding: 0.2rem 0.8rem;
                border-radius: 9.6rem;
                -webkit-border-radius: 9.6rem;
                -moz-border-radius: 9.6rem;
                -ms-border-radius: 9.6rem;
                -o-border-radius: 9.6rem;
                color: $color_7;
                text-align: center;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: $lh-3;
              }
            }
          }
        }
      }
    }

    .chart-card {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
      row-gap: 2.4rem;
      margin-bottom: 4.8rem;

      .chart-card-head {
        .title {
          color: $color_1;

          font-size: $text_4;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: $lh_1;
          letter-spacing: -0.018rem;
          margin-bottom: 0.8rem;
        }

        .price {
          @extend .title;
          font-size: $text_7;
          letter-spacing: -0.024rem;
          margin-bottom: 0rem;
        }

        .growth-wise {
          background: $color_9;
          padding: 0.2rem;
          border-radius: 99rem;
          -webkit-border-radius: 99rem;
          -moz-border-radius: 99rem;
          -ms-border-radius: 99rem;
          -o-border-radius: 99rem;

          ul {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            list-style: none;
            margin-bottom: 0rem;
            padding: 0rem;

            li {
              a {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.6rem 1.6rem;
                border: 0rem;
                color: $color_2;

                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: $lh_1;

                &:hover {
                  background: $bg_linear2;
                  box-shadow: $shadow-3;
                  color: $color_6;
                  border-radius: 99rem;
                  -webkit-border-radius: 99rem;
                  -moz-border-radius: 99rem;
                  -ms-border-radius: 99rem;
                  -o-border-radius: 99rem;
                }

                &.active {
                  background: $bg_linear2;
                  box-shadow: $shadow-3;
                  color: $color_6;
                  border-radius: 99rem;
                  -webkit-border-radius: 99rem;
                  -moz-border-radius: 99rem;
                  -ms-border-radius: 99rem;
                  -o-border-radius: 99rem;
                }
              }
            }
          }
        }
      }
    }

    .recent-orders {
      .title {
        color: $color_1;

        font-size: $text_4;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: $lh_1;
        letter-spacing: -0.018rem;
        margin-bottom: 2.4rem;
      }

      .recent-order-table {
        table {
          thead {
            th {
              background: $color_9;
              padding: 0.8rem 1.2rem;
              color: $color_3;

              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: $lh_1;
            }
          }

          tbody {
            tr {
              border-bottom: 1px solid $border-neutral-solid;

              td {
                padding: 0.8rem 1.2rem;
                vertical-align: middle;
                height: 8.8rem;

                p {
                  color: $color_2;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_normal;
                  line-height: $lh_1;
                  margin-bottom: 0.5rem;
                }

                h5 {
                  color: $color_7;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_bold;
                  line-height: $lh_1;
                  margin-bottom: 0rem;
                }

                span.badge {
                  display: flex;
                  padding: 0rem 0.8rem 0rem 0rem;
                  justify-content: center;
                  align-items: center;
                  border-radius: 9.6rem;
                  -webkit-border-radius: 9.6rem;
                  -moz-border-radius: 9.6rem;
                  -ms-border-radius: 9.6rem;
                  -o-border-radius: 9.6rem;
                  text-align: center;
                  font-size: $text_1;
                  font-style: $font_style_1;
                  font-weight: $font_weight_bold;
                  line-height: $lh-2;
                  width: fit-content;
                }

                span.badge.bg-aqua-25 {
                  background: $Accent-Aqua-25;
                  color: $Accent-Aqua-300;
                }

                span.badge.bg-red-25 {
                  background: $Secondary-Red-25;
                  color: $Secondary-Red-300;
                }

                span.badge.bg-green-25 {
                  background: $Secondary-Green-25;
                  color: $Secondary-Green-300;
                }
              }
            }
          }
        }
      }
    }

    .dash-footer {
      border-top: 0.1rem solid $border-neutral-solid;
      margin-top: 2rem;
      padding: 2rem 0rem 0rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      gap: 1.6rem;

      .flinks {

        h6 {
          color: $color_4;
          font-family: $font-inter;
          font-size: $text_1;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 133.333%;
          letter-spacing: 0.048rem;
          text-transform: uppercase;
          margin-bottom: 1.6rem;
        }

        ul {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 1.6rem;

          li {
            .footer-link {
              color: $color_6;
              font-family: $font-inter;
              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: $lh_1;
              position: relative;

              &:hover,
              &:active,
              &.active {
                color: #000000;
              }

              &::before {
                content: "";
                position: absolute;
                width: 100%;
                left: 0;
                background-color: $color_6;
                height: 0.01rem;
                top: 100%;
                z-index: 1;
                transform-origin: 100% 50%;
                transform: scaleX(0);
                transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
              }

              &:hover,
              &:active,
              &.active {
                &::before {
                  transform-origin: 0% 50%;
                  transform: scaleX(1);
                }
              }
            }
          }
        }
      }

      .copy-right {

        p {
          color: $color_3;
          font-family: $font-inter;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: 157.143%;
          letter-spacing: -0.014rem;
          margin-bottom: 0rem;
        }
      }
    }
  }

  @media screen and (max-width: 1299px) {
    .main-right-content {
      .dash-card {
        grid-template-columns: 1fr 1fr;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .main-right-content {
      padding: 1.6rem;
      margin-left: 0rem;

      .dash-card {
        grid-template-columns: 1fr;
        margin-bottom: 2.4rem;
      }

      .product-order {
        .card-head {
          padding: 2rem;
          border-bottom: 1px solid #eceff3;
          row-gap: 2.4rem;

          .head-gap {
            width: 100%;
          }
        }

        .card {
          .card-body {
            padding: 2rem;
          }
        }
      }

      .chart-card {
        margin-bottom: 2.4rem;

        .chart-card-head {
          flex-direction: column;
          row-gap: 1.6rem;

          .growth-wise {
            width: 100%;

            ul {
              justify-content: space-between;

              li {
                a {
                  padding: 0.6rem 1rem;
                  border: 0rem;
                  font-size: $text_1;
                  color: $color_6;
                  border-radius: 99rem;
                  -webkit-border-radius: 99rem;
                  -moz-border-radius: 99rem;
                  -ms-border-radius: 99rem;
                  -o-border-radius: 99rem;
                }
              }
            }
          }
        }

        .chart-img {
          img {
            height: 27.9rem;
            object-fit: cover;
            object-position: left;
          }
        }
      }

      .recent-orders {
        .recent-order-table {
          table {
            thead {
              th {
                white-space: nowrap;
              }
            }

            tbody {
              tr {
                border-bottom: 1px solid $border-neutral-solid;

                td {
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }
  }

  &::after {
    content: "";
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    z-index: -1;
    background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%),
      radial-gradient(36.52% 34.86% at 82.15% 31.8%,
        #ffddba 0%,
        rgba(255, 255, 255, 0) 100%),
      linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);
  }

  @media screen and (max-width: 767px) {

    .main-right-content {

      .dash-footer {
        display: flex;
        justify-content: flex-start;
        gap: 1.6rem;
        flex-wrap: wrap;

        .flinks {

          ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            justify-content: flex-start;
            gap: 0.8rem 2rem;
          }
        }
      }

      .chart-img {
        apx-chart {
          width: 60rem;
          display: block;
        }
      }
    }
  }
}