.table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  .search-icon {
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 3%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }

  .form-control.search-control {
    padding-left: 3.8rem;
    min-width: 30rem;
  }
}


.main-table {
  padding: 0rem;
  background: $bg_linear2;
  box-shadow: $shadow-2;
  border-radius: 1.2rem;
  -webkit-border-radius: 1.2rem;
  -moz-border-radius: 1.2rem;
  -ms-border-radius: 1.2rem;
  -o-border-radius: 1.2rem;

  table {
    border-radius: 1.2rem;
    -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    -ms-border-radius: 1.2rem;
    -o-border-radius: 1.2rem;
    overflow: visible;
    margin-bottom: 0;

    th,
    td {
      padding: 0.8rem 1.2rem;
      text-align: left;
      vertical-align: middle;
    }

    thead {
      th {
        color: $color_3;
        font-family: $font-inter;
        font-size: $text_1;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: $lh_1;
        background: $color_9;
        height: 3.6rem;
      }

      th:first-child {
        border-radius: 1.2rem 0rem 0rem 0rem;
        -webkit-border-radius: 1.2rem 0rem 0rem 0rem;
        -moz-border-radius: 1.2rem 0rem 0rem 0rem;
        -ms-border-radius: 1.2rem 0rem 0rem 0rem;
        -o-border-radius: 1.2rem 0rem 0rem 0rem;
      }

      th:last-child {
        border-radius: 0rem 1.2rem 0rem 0rem;
        -webkit-border-radius: 0rem 1.2rem 0rem 0rem;
        -moz-border-radius: 0rem 1.2rem 0rem 0rem;
        -ms-border-radius: 0rem 1.2rem 0rem 0rem;
        -o-border-radius: 0rem 1.2rem 0rem 0rem;
      }
    }

    tbody {
      tr {
        td {
          height: 8.8rem;

          .user-name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.2rem;

            span {
              background: $bg_linear2;
              box-shadow: $shadow-2;
              border-radius: 99rem;
              -webkit-border-radius: 99rem;
              -moz-border-radius: 99rem;
              -ms-border-radius: 99rem;
              -o-border-radius: 99rem;
              color: $color_3;
              text-align: center;
              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh_1;
              width: 3.2rem;
              height: 3.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            h5 {
              color: $color_7;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh_1;
            }

            p {
              color: $color_3;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: 157.143%;
              letter-spacing: -0.014rem;
            }

            .btn-table.btn-order[aria-expanded="false"] {
              svg {
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transition: all 0.2s linear;
                -webkit-transition: all 0.2s linear;
                -moz-transition: all 0.2s linear;
                -ms-transition: all 0.2s linear;
                -o-transition: all 0.2s linear;
              }
            }

            .btn-table.btn-order[aria-expanded="true"] {
              svg {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
                transition: all 0.2s linear;
                -webkit-transition: all 0.2s linear;
                -moz-transition: all 0.2s linear;
                -ms-transition: all 0.2s linear;
                -o-transition: all 0.2s linear;
              }
            }
          }

          .product-name {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.6rem;

            span.imgs {
              background: $color_8;
              border-radius: 0.6rem;
              -webkit-border-radius: 0.6rem;
              -moz-border-radius: 0.6rem;
              -ms-border-radius: 0.6rem;
              -o-border-radius: 0.6rem;
              width: 6.7rem;
              height: 7.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 0.8rem;

              img {
                max-height: 100%;
                mix-blend-mode: multiply;
              }
            }

            &.table-order {
              max-width: 30rem;

              span.imgs {
                min-width: 6.7rem;
              }
            }
          }

          .table-order {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.6rem;

            .order-img {
              background: #f8fafb;
              border-radius: 0.8rem;
              -webkit-border-radius: 0.8rem;
              -moz-border-radius: 0.8rem;
              -ms-border-radius: 0.8rem;
              -o-border-radius: 0.8rem;
              max-width: 8.9rem;
              overflow: hidden;
              padding: 0.8rem;

              img {
                mix-blend-mode: multiply;
              }
            }

            .order-table-content {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
              row-gap: 0.8rem;

              .title {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 171.429%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
                text-wrap: nowrap;
              }

              .order-detail-list {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 1.6rem;

                li {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 0.6rem;
                  position: relative;

                  p {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.024rem;
                    margin-bottom: 0rem;
                  }

                  span {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: $lh_1;
                    letter-spacing: -0.024rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    column-gap: 0.6rem;
                    position: relative;

                    .color {
                      width: 1.6rem;
                      height: 1.6rem;
                      background: $color_6;
                      border-radius: 0.4rem;
                      -webkit-border-radius: 0.4rem;
                      -moz-border-radius: 0.4rem;
                      -ms-border-radius: 0.4rem;
                      -o-border-radius: 0.4rem;
                    }
                  }
                }

                li+li::before {
                  float: left;
                  content: "";
                  width: 0.1rem;
                  height: 1.1rem;
                  background: #eceff3;
                  left: -12%;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                  -webkit-transform: translateY(-50%);
                  -moz-transform: translateY(-50%);
                  -ms-transform: translateY(-50%);
                  -o-transform: translateY(-50%);
                }
              }
            }
          }

          p {
            span {
              color: #818898;
            }
          }

          .employees {
            display: flex;
            align-items: center;

            .btn {
              border: 0.2rem solid $bg-White;
              background: $color_8;
              border-radius: 5.6rem;
              -webkit-border-radius: 5.6rem;
              -moz-border-radius: 5.6rem;
              -ms-border-radius: 5.6rem;
              -o-border-radius: 5.6rem;
              padding: 0rem;

              &:last-child {
                background: $color_6;
                color: $color_10;
                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 166.667%;
                padding: 0.8rem 0.7rem;
                width: auto;
                min-width: 3.8rem;
                height: 3.8rem;
              }
            }

            > :not(:first-child) {
              margin-left: -0.8rem;
            }
          }

          .mx-100 {
            max-width: 100%;
          }

          h5,
          p {
            color: $color_2;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
            max-width: 22rem;
            overflow-wrap: break-word;
            word-wrap: break-word;
            margin-bottom: 0rem;
          }

          p.logs-para {
            max-width: 50rem;
          }

          .btn.btn-delete,
          .btn.btn-edit,
          .btn.btn-more {
            padding: 0.6rem;
          }

          .btn.btn-more {
            &::after {
              content: none;
            }
          }

          .dropdown-menu.more-menu {
            background: $Toast-BG-Default;
            box-shadow: $shadow-5;
            backdrop-filter: blur(0.5rem);
            padding: 0.4rem;
            border: 0rem;
            border-radius: 1rem;
            -webkit-border-radius: 1rem;
            -moz-border-radius: 1rem;
            -ms-border-radius: 1rem;
            -o-border-radius: 1rem;
            min-width: 21rem;

            .drop-head {
              padding: 0.6rem 1.2rem 1rem 1.2rem;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              column-gap: 1.6rem;
              border-bottom: 0.1rem solid $Neutral-Solid-50;

              span {
                width: 3.6rem;
                height: 3.6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 0.8rem;
                color: $color_3;
                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: $lh_1;
                background: $bg_linear2;
                box-shadow: $shadow-2;
                border-radius: 99rem;
                -webkit-border-radius: 99rem;
                -moz-border-radius: 99rem;
                -ms-border-radius: 99rem;
                -o-border-radius: 99rem;
              }

              .title {
                color: $color_2;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                margin-bottom: 0rem;
              }

              .desc {
                color: $color_3;
                text-align: justify;
                font-size: $text_2;
                font-style: $font_style_1;
                line-height: $lh_1;
                margin-bottom: 0rem;
                font-weight: $font_weight_normal;
              }
            }

            .drop-list-menu {
              padding: 0.8rem 0rem;
              border-bottom: 0.1rem solid $Neutral-Solid-50;

              li {
                .dropdown-link {
                  padding: 0.8rem 1.2rem;
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                  column-gap: 1.2rem;
                  color: $color_2;
                  font-family: $font-inter;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_normal;
                  line-height: 142.857%;
                  letter-spacing: -0.014rem;
                }

                h6 {
                  color: $color_3;
                  font-size: $text_1;
                  font-style: $font_style_1;
                  font-weight: $font_weight_bold;
                  line-height: 133.333%;
                  padding: 0.8rem 1.2rem;
                  margin-bottom: 0rem;
                }

                span.badge.bg-alpha-25 {
                  background: $Neutral-Alpha-25;
                  color: $color_7;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_bold;
                  line-height: 142.857%;
                  padding: 0.2rem 0.8rem;
                  margin: 0rem 1.2rem;
                }
              }
            }

            .drop-footer {
              @extend .drop-head;
              border-bottom: 0rem;
              padding: 0.8rem 0rem 0rem;

              a {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                column-gap: 1.2rem;
                color: $color_2;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 142.857%;
                letter-spacing: -0.014rem;
                width: 100%;

                span {
                  box-shadow: none;
                  width: 2rem;
                  height: 2rem;
                  padding: 0rem;
                }
              }
            }
          }

          &:first-child {
            border-radius: 0rem 0rem 0rem 1.2rem;
            -webkit-border-radius: 0rem 0rem 0rem 1.2rem;
            -moz-border-radius: 0rem 0rem 0rem 1.2rem;
            -ms-border-radius: 0rem 0rem 0rem 1.2rem;
            -o-border-radius: 0rem 0rem 0rem 1.2rem;
          }

          &:last-child {
            border-radius: 0rem 0rem 1.2rem 0rem;
            -webkit-border-radius: 0rem 0rem 1.2rem 0rem;
            -moz-border-radius: 0rem 0rem 1.2rem 0rem;
            -ms-border-radius: 0rem 0rem 1.2rem 0rem;
            -o-border-radius: 0rem 0rem 1.2rem 0rem;
          }

          .order-report-detail-content {
            padding: 1.2rem;
            display: grid;
            grid-template-columns: 1fr 2fr;
            align-items: flex-start;
            gap: 4rem;

            .order-detail {
              padding: 2rem;
              border: 0.1rem solid $border-neutral-solid;
              border-radius: 1.2rem;
              -webkit-border-radius: 1.2rem;
              -moz-border-radius: 1.2rem;
              -ms-border-radius: 1.2rem;
              -o-border-radius: 1.2rem;

              ul {
                li {
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  gap: 1.2rem;

                  p {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: 157.143%;
                    letter-spacing: -0.014rem;
                  }

                  .head {
                    color: $color_3;
                    min-width: 10.4rem;
                  }
                }

                > :not(:last-child) {
                  margin-bottom: 1.2rem;
                }
              }
            }

            .order-lists {
              max-height: 56.2rem;
              overflow: hidden auto;
              padding-right: 1.2rem;
              scrollbar-color: $Neutral-Solid-50 $bg-White;
              scrollbar-width: thin;

              .order-card {
                display: grid;
                grid-template-columns: 1fr 3fr;
                align-items: flex-start;
                gap: 1.6rem;

                .order-img {
                  background: $color_8;
                  border-radius: 1.6rem;
                  -webkit-border-radius: 1.6rem;
                  -moz-border-radius: 1.6rem;
                  -ms-border-radius: 1.6rem;
                  -o-border-radius: 1.6rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  padding: 0.2rem;
                  height: 16rem;

                  img {
                    max-width: 12.8rem;
                    mix-blend-mode: multiply;
                  }
                }

                .order-content {
                  .title {
                    color: var(--Neutral-Solid-700, #272835);
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: 171.429%;
                    letter-spacing: -0.014rem;
                    margin-bottom: 0rem;
                  }

                  .order-badges {
                    display: flex;
                    align-items: flex-start;
                    gap: 1.6rem;

                    p {
                      color: $color_3;
                      font-family: $font-inter;
                      font-size: $text_1;
                      font-style: $font_style_1;
                      font-weight: $font_weight_normal;
                      line-height: 166.667%;
                      letter-spacing: -0.024rem;
                      margin-bottom: 0rem;
                      display: flex;
                      align-items: flex-start;
                      column-gap: 0.4rem;
                    }
                  }

                  .desc {
                    color: $color_3;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: 157.143%;
                    letter-spacing: -0.014rem;
                    max-width: 100%;
                    margin-bottom: 0.8rem;
                  }

                  .proudct-features {
                    padding-top: 0.8rem;
                    border-top: 0.1rem dashed $border-neutral-solid;
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    gap: 0.8rem;

                    li {
                      p {
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: 157.143%;
                        letter-spacing: -0.014rem;
                        margin-bottom: 0rem;

                        span {
                          display: block;
                          color: $color_3;
                        }
                      }
                    }
                  }
                }
              }

              > :not(:last-child) {
                margin-bottom: 3.2rem;
              }

              &::-webkit-scrollbar-track {
                background-color: $bg-White;
                border-radius: 9rem;
                -webkit-border-radius: 9rem;
                -moz-border-radius: 9rem;
                -ms-border-radius: 9rem;
                -o-border-radius: 9rem;
              }

              &::-webkit-scrollbar {
                background-color: $bg-White;
                width: 0.8rem;
              }

              &::-webkit-scrollbar-thumb {
                background-color: $Neutral-Solid-50;
                border-radius: 9rem;
                -webkit-border-radius: 9rem;
                -moz-border-radius: 9rem;
                -ms-border-radius: 9rem;
                -o-border-radius: 9rem;
              }
            }
          }

          .qty-input {
            height: 3.7rem;
          }
        }

        &.collapse-tr {
          .collapse-td {
            padding: 0rem;
          }
        }
      }
    }

    &.table-in-child {
      box-shadow: none;
      border-radius: 0rem;
      margin: 0rem;
    }

    &.order-report-table {

      thead {
        tr {
          text-align: center;

          th {

            &:last-child {
              width: 5%;
            }

            // &:nth-child(5) {
            //   width: 27.2rem;
            //   min-width: 27.2rem;
            //   text-align: center;
            // }
          }
        }
      }

      th,
      td {
        white-space: nowrap;
      }

    }
  }
}

.paggination-nav {
  .paggination {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.4rem;

    .page-item {
      .page-link {
        padding: 0.8rem;
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color_3;

        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 171.429%;
        letter-spacing: -0.014rem;
      }

      .page-link.active {
        border-radius: var(--radius-full, 9999px);
        background: $bg-White;
        box-shadow: $shadow-2;
      }

      &:first-child {
        margin-right: auto;
      }

      &:last-child {
        margin-left: auto;
      }
    }
  }
}

.table-footer {
  @extend .table-head;
  margin-bottom: 0rem;

  .sorted {
    @extend .table-head;
    justify-content: center;
    margin-bottom: 0rem;

    h5 {
      color: $color_3;

      font-size: $text_2;
      font-style: $font_style_1;
      font-weight: $font_weight_normal;
      line-height: $lh_1;
      margin-bottom: 0rem;
    }

    .dropdown {
      .btn {
        color: $color_2;

        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 142.857%;
        padding: 0.8rem 1rem;
        border-radius: 0.6rem;
        -webkit-border-radius: 0.6rem;
        -moz-border-radius: 0.6rem;
        -ms-border-radius: 0.6rem;
        -o-border-radius: 0.6rem;
        display: flex;
        align-items: center;
        column-gap: 0.6rem;

        &::after {
          content: none;
        }

        span {
          width: 2rem;
          height: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}

@media screen and (max-height: 768px) {

  .main-table {
    table {
      tbody {
        tr {
          td {
            .dropdown-menu.more-menu {
              max-height: 20rem;
              overflow: auto;
              scrollbar-width: none;
            }
          }
        }
      }
    }
  }

}


@media screen and (max-width: 991px) {
  .table-head {
    flex-direction: column;
    gap: 0.8rem;
    margin-bottom: 1.6rem;

    .search-icon {
      left: 1%;
    }

    .position-relative {
      width: 100%;
    }

    .form-control.search-control {
      min-width: 100%;
    }

    .sorted {
      width: 100%;
      justify-content: space-between;
    }
  }

  .table-footer {
    align-items: stretch;

    .sorted {
      flex-direction: row;
    }
  }

  .paggination-nav {
    .paggination {
      justify-content: space-between;
      column-gap: 0.4rem;
    }
  }
}

@media screen and (max-width: 576px) {
  .table-head {
    .search-icon {
      left: 2%;
    }
  }

  .paggination-nav {
    .paggination {

      .page-item {
        .page-link {
          width: 3rem;
          height: 3rem;

        }
      }
    }
  }
}