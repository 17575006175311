section {
  &.supchainsec {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0) 80.25%), radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(65.8% 62.46% at 97.64% 48.25%, #FFDDBA 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(120.82% 115.83% at 72.19% 74.45%, #EEF9FD 0%, #EEF9FD 24.36%, rgba(255, 255, 255, 0) 100%), #FFF;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      padding-bottom: 27.2rem;

      .container {
        h1 {
          color: $color_6;
          line-height: 110%;
          letter-spacing: -1.6px;
          margin-bottom: 0.8rem;
        }

        p {
          color: $color_2;
          font-size: 1.8rem;
          font-weight: 400;
          line-height: 155.556%;
          letter-spacing: -0.36px;
          max-width: 67.8rem;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url("../../images/Background-pattern.png");
        z-index: 0;
        background-position: center right;
      }
    }
  }

  &.supbanner {
    margin-top: -18.2rem;
    position: relative;
    padding-top: 0;

    .max-width-container {
      .container {
        .img-box {
          img {
            border-radius: 1.6rem;
          }
        }
      }
    }
  }

  &.supmid {
    .max-width-container {
      .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 2.4rem;

        .item {
          padding: 5.4rem 4.8rem 0;
        }

        .sec-a {
          border-radius: 2.4rem;
          background: linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          .img-box {
            margin-right: -4.8rem;
            max-height: 37.8rem;
            overflow: hidden;
            height: 100%;

            img {
              width: 100%;
              // transform: scale(1.8) translate(12.5rem, 6rem);
            }
          }
        }

        .sec-b {
          border-radius: 2.4rem;
          background: linear-gradient(180deg, #EEF5FF 0%, #D9E8FF 100%), linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          .img-box {
            text-align: center;

            img {
              max-height: 38rem;
            }
          }
        }

        h2 {
          color: $color_6;
          font-family: $suisse_medium;
          font-size: 4rem;
          line-height: 120%;
          letter-spacing: -0.4px;
        }

        p {
          margin: 1.6rem 0 3.2rem 0;
          color: $color_2;
          font-family: Inter;
          font-size: 1.6rem;
          line-height: 150%;
          letter-spacing: -0.16px;
        }
      }
    }
  }

  &.supbottomsec {
    .max-width-container {
      .container {
        display: grid;
        grid-template-columns: 3fr 5fr;
        align-items: center;
        grid-gap: 3.2rem;
        padding: 5.6rem 4.8rem;
        border-radius: 24px;
        background: $color_5;
        box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);

        .itembox-a {
          h2 {
            color: $color_6;
            font-family: $suisse_medium;
            font-size: 4rem;
            line-height: 120%;
            letter-spacing: -0.4px;
            margin-bottom: 1.6rem;
          }

          p {
            color: $color_2;
            font-size: 1.8rem;
            line-height: 155.556%;
            letter-spacing: -0.36px;
          }
        }

        .itembox-b {
          padding: 5rem 0rem 7rem 0;
          position: relative;

          .topmdse {
            text-align: center;
            position: relative;

            .brndname {
              position: absolute;
              top: 1.2rem;
              left: 4.2rem;
              min-width: 10.5rem;

              .topbgimg {
                width: 8.6rem;
                height: 5.7rem;
                margin-left: auto;
              }

              .brandimage {
                text-align: left;
                margin-top: .4rem;

                img {
                  max-width: 5.7rem;
                  width: 100%;
                  max-height: 2.1rem;
                  height: 100%;
                  object-fit: contain;
                }
              }
            }

            .manageTab {
              overflow: hidden;
            }
          }

          .midscbg {
            margin-top: -2.5rem;
            margin-bottom: .8rem;

            .img {
              width: 29.7rem;
              height: 7.3rem;
              margin-left: auto;
              margin-right: 1.85rem;
            }
          }

          .supplymaincard {
            max-width: 39.6rem;
            margin: auto;
            position: relative;
            z-index: 1;

            .supcard {
              padding: 1.68rem;
              border-radius: 1.34rem;
              background: $color_5;
              box-shadow: 0px 0px 0px 0.843px rgba(18, 55, 105, 0.12), 0px 10.119px 11.806px -5.903px rgba(164, 172, 185, 0.16);
              display: grid;
              grid-template-columns: 10.7rem auto;
              grid-gap: 1.34rem;
              position: relative;

              .img-box {
                background-color: $color_9;
                border-radius: 0.67rem;
                overflow: hidden;

                img {
                  max-width: 10.7rem;
                  max-height: 10.7rem;
                  object-fit: cover;
                  mix-blend-mode: multiply;
                }
              }

              .supcdetail {
                .sda {
                  display: flex;
                  align-items: center;
                  grid-gap: .6rem;

                  .text-loader {
                    height: .9rem;
                    width: 4.8rem;
                    border-radius: 8431.895px;
                    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
                    background-size: 500px 100px;
                    animation-name: supplypagerow;
                    animation-duration: 1.5s;
                    display: inline-block;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;
                  }
                }

                p {
                  color: #0D0D12;
                  font-family: Inter;
                  font-size: 11.806px;
                  margin: .7rem 0;
                  font-style: normal;
                  font-weight: $font_weight_bold;
                  line-height: normal;
                }

                .spacat {
                  color: $color_3;
                  font-size: 1.019rem;
                  font-weight: $font_weight_bold;
                  line-height: normal;
                  letter-spacing: 0.202px;
                  text-transform: uppercase;
                }

                .pricesec {
                  display: flex;
                  margin-top: 1.34rem;
                  align-items: center;
                  grid-gap: .64rem;

                  .price {
                    color: $color_2;
                    font-family: Inter;
                    font-size: 1.18rem;
                    font-weight: 400;
                    line-height: normal;
                  }

                  .qty {
                    height: 0.9rem;
                    width: 4.8rem;
                    border-radius: 8431.895px;
                    background: linear-gradient(to right, #eee 20%, #ddd 50%, #eee 80%);
                    background-size: 500px 100px;
                    animation-name: supplypagerow;
                    animation-duration: 1.5s;
                    display: inline-block;
                    animation-iteration-count: infinite;
                    animation-timing-function: linear;
                    animation-fill-mode: forwards;
                  }
                }
              }

              .dumcard {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                z-index: -1;
                bottom: -1rem;
                width: 100%;
                padding: 0 20px;
              }
            }
          }

          .btmscbg {
            position: absolute;
            bottom: -7rem;
            left: 35%;

            img {
              width: 11.2rem;
              height: 14.7rem;
              display: inline-block;
              object-fit: contain;
            }
          }

          .consetbox {
            max-width: 62rem;
            margin-left: auto;
          }
        }
      }
    }
  }
}

@keyframes supplypagerow {
  0% {
    background-position: -250px 0;
  }

  100% {
    background-position: 250px 0;
  }
}

@media only screen and (max-width: 1399px) {
  section {
    &.supbottomsec {
      .max-width-container {
        .container {
          .itembox-b {
            .btmscbg {
              left: 39%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1299px) {
  section {
    &.supbottomsec {
      .max-width-container {
        .container {
          .itembox-b {
            .btmscbg {
              left: 34%;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 1199px) {
  section {
    &.supbottomsec {
      .max-width-container {
        .container {
          padding: 3rem 2.2rem;

          .itembox-b {
            .topmdse {
              .brndname {
                left: 0;
              }
            }

            .btmscbg {
              left: 26%;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  section {
    &.supchainsec {
      .max-width-container {
        .container {
          h1 {
            font-size: 5.6rem;
          }
        }
      }
    }

    &.supbottomsec {
      .max-width-container {
        .container {
          grid-template-columns: 1fr;
          padding: 5.4rem 4.8rem;

          .itembox-b {
            .consetbox {
              margin: auto;
              max-width: 58rem;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  section {
    &.supchainsec {
      .max-width-container {
        padding-top: 4rem;
        padding-bottom: 16rem;
      }
    }

    &.supbanner {
      margin-top: -12.2rem;

      .max-width-container {
        padding: 0;
      }
    }

    &.supmid {
      .max-width-container {
        padding: 0;

        .container {
          grid-template-columns: 1fr;

          .item {
            padding: 4rem 2.2rem;
            padding-bottom: 0;
          }

          h2 {
            font-size: 3.2rem;
          }

          .sec-a {
            .img-box {
              margin-right: 0rem;
              max-height: 20rem;

              img {
                width: 100%;
              }
            }
          }
        }
      }
    }

    &.supbottomsec {
      .max-width-container {
        padding: 0;

        .container {
          padding: 4rem 2.2rem;
          grid-gap: 0;

          .itembox-b {
            .btmscbg {
              left: 18%;
            }

            .supplymaincard {
              .supcard {
                grid-template-columns: 5rem auto;
                align-items: flex-start;

                .img-box {
                  padding: 0.4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  img {
                    max-width: 5rem;
                    max-height: 5rem;
                  }
                }
              }
            }

            .topmdse {
              .brndname {
                min-width: 5.5rem;
                left: -1rem;
                top: 1.6rem;

                .topbgimg {
                  width: 5.6rem;
                  height: 3.7rem;
                }

                .brandimage {
                  margin-top: 0;

                  img {
                    max-width: 3rem;
                  }
                }
              }

              .manageTab {
                position: relative;
                z-index: 1;
              }
            }

            .midscbg {
              margin-top: -2rem;
              margin-bottom: 1.8rem;

              .img {
                width: 28rem;
                max-width: 100%;
                margin-right: 0;
              }
            }
          }

          .itembox-a {
            h2 {
              font-size: 3.2rem;
            }
          }
        }
      }
    }
  }

}