.manage-category {
    background: $bg_linear2;
    box-shadow: $shadow-2;
    border-radius: 1.2rem;
    -webkit-border-radius: 1.2rem;
    -moz-border-radius: 1.2rem;
    -ms-border-radius: 1.2rem;
    -o-border-radius: 1.2rem;
    margin-bottom: 2.4rem;

    .category-head {
        background: $color_9;
        border-radius: 1.2rem 1.2rem 0rem 0rem;
        -webkit-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -moz-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -ms-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -o-border-radius: 1.2rem 1.2rem 0rem 0rem;
        display: grid;
        grid-template-columns: auto 19.5rem 19.5rem;
        height: 3.6rem;
        border-bottom: 0.1rem solid $border-neutral-solid;

        p {
            color: $color_3;
            font-family: $font-inter;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
            margin-bottom: 0rem;
            padding: 0.8rem 1.2rem;
        }
    }

    .category-container {

        .first-list,
        .second-list,
        .third-list {
            display: grid;
            grid-template-columns: auto 19.5rem 19.5rem;
            align-items: center;
            height: 8.8rem;
            border-bottom: 0.1rem solid $border-neutral-solid;

            .user-name,
            .level,
            .edit {
                padding: 0.8rem 1.2rem;
            }

            .user-name {

                h5 {
                    color: $color_7;
                    font-family: $font-inter;
                    font-size: $text_2;
                    font-style: $font_style_1;
                    font-weight: $font_weight_bold;
                    line-height: $lh_1;
                }
            }

            .btn-table[aria-expanded="false"] {
                svg {
                    transform: rotate(0deg);
                    -webkit-transform: rotate(0deg);
                    -moz-transform: rotate(0deg);
                    -ms-transform: rotate(0deg);
                    -o-transform: rotate(0deg);
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -ms-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                }
            }

            .btn-table[aria-expanded="true"] {
                svg {
                    transform: rotate(90deg);
                    -webkit-transform: rotate(90deg);
                    -moz-transform: rotate(90deg);
                    -ms-transform: rotate(90deg);
                    -o-transform: rotate(90deg);
                    transition: all 0.3s linear;
                    -webkit-transition: all 0.3s linear;
                    -moz-transition: all 0.3s linear;
                    -ms-transition: all 0.3s linear;
                    -o-transition: all 0.3s linear;
                }
            }
        }
    }
}

@media screen and (max-width: 991px) {

    .manage-category {

        .category-head {
            grid-template-columns: auto 10.5rem 16.5rem;
        }

        .category-container {

            .first-list,
            .second-list,
            .third-list {
                grid-template-columns: auto 10.5rem 16.5rem;
                align-items: center;
            }
        }
    }

}