.product-item__gallery {
    display: grid;
    grid-template-columns: 10.6rem auto;
    gap: 0.8rem;

    .product-item__slider-content-left {

        .product-slider-nav {
            position: relative;

            .product-slider-nav__item {
                background: $color_8;
                border-radius: 1.6rem;
                -webkit-border-radius: 1.6rem;
                -moz-border-radius: 1.6rem;
                -ms-border-radius: 1.6rem;
                -o-border-radius: 1.6rem;
                overflow: hidden;
                cursor: pointer;
                margin: 0.5rem 0.4rem;
                box-shadow: 0px 0px 0px 2px #ffffff, 0px 0px 0px 2px #FFF;
                max-height: 12.6rem;
                min-height: 12.6rem;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    mix-blend-mode: multiply;
                }

                &:last-child {
                    margin-bottom: 0rem;
                }

                &:active,
                &:focus,
                &:focus-visible,
                &.slick-current {
                    box-shadow: 0px 0px 0px 2px #F68C1E, 0px 0px 0px 2px #FFF;
                }
            }

            &::after {
                content: "";
                position: absolute;
                bottom: -0.5%;
                width: 10.5rem;
                height: 14rem;
                background: linear-gradient(360deg, #FFF 4.15%, rgba(255, 255, 255, 0.00) 100%);
            }

            .slick-arrow {
                width: 3.8rem;
                height: 3.8rem;
                background: $bg_linear2;
                box-shadow: $shadow-2;
                border-radius: 99rem;
                -webkit-border-radius: 99rem;
                -moz-border-radius: 99rem;
                -ms-border-radius: 99rem;
                -o-border-radius: 99rem;
                display: flex !important;
                align-items: center;
                justify-content: center;

                &.slick-next {
                    bottom: 0%;
                    top: inherit;
                    right: 50%;
                    z-index: 1;
                    transform: translateX(50%) rotate(90deg);
                    -webkit-transform: translateX(50%) rotate(90deg);
                    -moz-transform: translateX(50%) rotate(90deg);
                    -ms-transform: translateX(50%) rotate(90deg);
                    -o-transform: translateX(50%) rotate(90deg);

                    &::before {
                        background: url("../../images/arrow.svg");
                        content: "";
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        background-position: center;
                        background-size: auto;
                        background-repeat: no-repeat;
                        transform: rotate(270deg);
                    }
                }

                &.slick-prev {
                    top: -2%;
                    left: 50%;
                    transform: translateX(-50%);
                    -webkit-transform: translateX(-50%);
                    -moz-transform: translateX(-50%);
                    -ms-transform: translateX(-50%);
                    -o-transform: translateX(-50%);
                    z-index: -1;

                    &:before {
                        background: url("../../images/arrow.svg");
                        content: "";
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        background-position: center;
                        background-size: auto;
                        background-repeat: no-repeat;
                        transform: rotate(180deg);
                        -webkit-transform: rotate(180deg);
                        -moz-transform: rotate(180deg);
                        -ms-transform: rotate(180deg);
                        -o-transform: rotate(180deg);
                    }
                }
            }

        }
    }


    .product-item__slider-content-right {

        .product-slider-for {
            max-width: 100%;

            .product-item__slider-item {
                background: $color_8;
                border-radius: 1.6rem;
                -webkit-border-radius: 1.6rem;
                -moz-border-radius: 1.6rem;
                -ms-border-radius: 1.6rem;
                -o-border-radius: 1.6rem;
                overflow: hidden;
                cursor: pointer;
                width: 100% !important;
                max-width: 58.8rem;
                height: 78.6rem;

                figure {
                    margin: 0rem;
                    height: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                        mix-blend-mode: multiply;
                    }
                }
            }

            .slick-list,
            .slick-track {
                max-width: 100%;
            }
        }
    }
}

@media screen and (max-width: 991px) {

    .product-item__gallery {
        display: grid;
        grid-template-columns: 1fr;
        gap: 0.8rem;

        .product-item__slider-content-left {
            order: 2;
            max-width: 95.8vw;

            .product-slider-nav {

                .slick-list.draggable{

                    .slick-track{
                        margin-left: 0rem;
                    }
                }

                &::after {
                    content: none;
                }

                .product-slider-nav__item {
                    min-height: 15rem;
                    max-height: 15rem;

                    &:last-child {
                        margin-bottom: 0.5rem;
                    }
                }
            }
        }

        .product-item__slider-content-right {
            order: 1;
            max-width: 95.8vw;

            .product-slider-for {

                .product-item__slider-item {
                    width: 100% !important;
                    max-width: 100%;
                    height: 45.8rem;
                }
            }
        }
    }
}

@media screen and (max-width: 576px) {

    .product-item__gallery {

        .product-item__slider-content-left {

            .product-slider-nav {

                .product-slider-nav__item {
                    min-height: 9rem;
                    max-height: 9rem;
                }
            }
        }
    }
}