/* ========================================================================================================================= */
/*=================================================== LoginPage css start ==================================================*/
/* ======================================================================================================================= */

section {
  &.loginBanner {
    .max-width-container {
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(36.52% 34.86% at 82.15% 31.8%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);
      height: calc(100vh - 10rem);
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .bg-design {
        background-image: url("../../images/Background-pattern.png");
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }
  }
}

.card {
  &.login-card {
    background: #f8fafb;
    border: 0rem;
    box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25),
      0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04),
      0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
    backdrop-filter: blur(0.3743654727935791rem);
    border-radius: 1.6rem;
    -webkit-border-radius: 1.6rem;
    -moz-border-radius: 1.6rem;
    -ms-border-radius: 1.6rem;
    -o-border-radius: 1.6rem;
    max-width: 48rem;
    width: 100%;
    margin: 0 auto;

    .card-body {
      form {
        padding: 2.4rem;
      }
    }

    form {
      h4 {
        color: #666d80;
        font-family: $font-inter;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 600;
        line-height: 200%;
        letter-spacing: 0.048rem;
        text-transform: uppercase;
        margin-bottom: 0.8rem;
      }

      .form-group {
        margin-bottom: 1.6rem;

        .form-label {
          color: $color_2;
          font-family: $font-inter;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          margin-bottom: 0.4rem;
        }

        .form-control {
          background: $color_5;
          box-shadow: 0rem 0rem 0rem 0.1rem rgba(9, 25, 72, 0.13),
            0rem 0.1rem 0.2rem 0rem rgba(18, 55, 105, 0.08);
          border: 0rem;
          border-radius: 0.6rem;
          -webkit-border-radius: 0.6rem;
          -moz-border-radius: 0.6rem;
          -ms-border-radius: 0.6rem;
          -o-border-radius: 0.6rem;
          padding: 0.8rem 1rem;
          color: #818898;
          font-family: $font-inter;
          font-size: 1.4rem;
          font-style: normal;
          font-weight: 400;
          line-height: 142.857%;
          letter-spacing: -0.014rem;
        }
      }
    }

    .login-new-acc {
      padding: 1.6rem 2.4rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 0.8rem;

      p {
        color: #666d80;
        font-family: $font-inter;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: 400;
        line-height: 157.143%;
        letter-spacing: -0.014rem;
        margin-bottom: 0rem;
      }
    }
  }
}

/* ========================================================================================================================= */
/*=================================================== LoginPage css end ==================================================*/
/* ======================================================================================================================= */