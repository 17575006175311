.main-content {
  margin-top: 8.38rem;
}

.main-side-content {
  border-right: 1px solid $border-neutral-solid;
  width: 22rem;
  height: calc(100vh - 8rem);
  position: fixed;
  z-index: 99;
  background: $color_5;

  .side-content {
    padding: 1.6rem 1.6rem 1.6rem 1.6rem;
    height: 100%;
    overflow: auto;
    scrollbar-color: $Neutral-Solid-50 $bg-White;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      width: 0px;
    }

    h5 {
      color: $color_4;

      font-size: $text_1;
      font-style: $font_style_1;
      font-weight: $font_weight_bold;
      line-height: $lh_1;
      margin-bottom: 1.6rem;
    }

    ul {
      row-gap: 0.6rem;
      margin-bottom: 1.6rem;

      li {
        display: block;
        width: 100%;

        a {
          padding: $padding-sm;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 1rem;
          color: $color_3;
          text-align: center;
          text-transform: capitalize;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          text-decoration: none;
          border-radius: $radius_1;
          -webkit-border-radius: $radius_1;
          -moz-border-radius: $radius_1;
          -ms-border-radius: $radius_1;
          -o-border-radius: $radius_1;
          border: 1px solid transparent;
          width: 100%;

          svg {
            path {
              transition: stroke 0.3s ease;
              -webkit-transition: stroke 0.3s ease;
              -moz-transition: stroke 0.3s ease;
              -ms-transition: stroke 0.3s ease;
              -o-transition: stroke 0.3s ease;
            }
          }

          &:hover {
            color: $color_5;
            background: $bg_linear;
            box-shadow: $shadow-1;

            svg {
              path {
                stroke: $color_5;
              }
            }
          }

          &.active {
            color: $color_5;
            background: $bg_linear;
            box-shadow: $shadow-1;

            svg {
              path {
                stroke: $color_5;
              }
            }
          }
        }
      }
    }

    .accordion {
      .accordion-button {
        padding: 0.25rem 0rem;
        color: $color_4;

        font-size: $text_1;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: $lh_1;
        box-shadow: none;
        background: none;
        margin-bottom: 0.8rem;

        &::after {
          width: 2rem;
          height: 2rem;
          background-position: center;
        }
      }
    }

    .side-footer {
      position: absolute;
      bottom: 0%;
      padding: 1.6rem;
      width: 100%;
      left: 0%;
      background: $color_5;

      ul {
        li {
          a {
            .userName {
              border-radius: var(--radius-full, 9999px);
              background: var(--Background-White, #fff);
              box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
                0px 1px 2px 0px rgba(18, 55, 105, 0.08);
              min-width: 3.2rem;
              height: 3.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              color: $color_6;
              text-align: center;

              font-size: 1rem;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: $lh_1;
            }

            p {
              overflow: hidden; // Hide overflowing text
              text-overflow: ellipsis; // Display ellipsis (...) for truncated text
              white-space: nowrap; // Prevent text from wrapping to the next line
              max-width: 300px;
            }
          }
        }
      }
    }
  }

  &.customer-side {
    .accordion {
      .accordion-header {
        padding-bottom: 1.6rem;
        border-bottom: 0.1rem solid $border-neutral-solid;

        button {
          color: $text-loud-900;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          font-family: $font-inter;
          gap: 0.8rem;
        }
      }

      .accordion-collapse {
        margin-top: 1.6rem;
      }
    }
  }
}

@media screen and (max-width: 991px) {

  .main-content {
    .main-side-content {
      display: none;
    }
  }

  .main-side-content {
    border-right: 0px;
    width: 100%;
    height: calc(100dvh - 8rem);
    position: relative;
    z-index: 99;
    background: $color_5;
    padding: 0rem 0.6rem;

    &.customer-side {
      padding: 0.8rem;

      .accordion {
        .accordion-header {
          padding-bottom: 0rem;
          border-bottom: 0rem solid #eceff3;

          button {
            border-radius: 0.8rem;
            padding: 0.8rem 1.2rem;
            border: 0.1rem solid rgb(164 172 185 / 44%);
          }
        }
      }
    }

    .side-content {
      padding: 0rem;
      height: 100%;
      overflow: auto;
    }
  }
}