section {
  &.contactsec {
    padding-top: 0px;
    padding-bottom: 0px;

    .max-width-container {
      padding-top: 12.8rem;
      position: relative;
      padding-bottom: 44.3rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(65.8% 62.46% at 97.64% 48.25%, #FFDDBA 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(120.82% 115.83% at 72.19% 74.45%, #EEF9FD 0%, #EEF9FD 24.36%, rgba(255, 255, 255, 0.00) 100%), #FFF;
      border-radius: 2.4rem;

      .container {
        p {
          margin-bottom: 1.6rem;
          color: #666D80;
          font-size: 1.2rem;
          font-weight: 600;
          line-height: 133.333%;
          letter-spacing: 0.48px;
          text-transform: uppercase;
        }

        h1 {
          color: $color_6;
          font-size: 80px;
          font-style: normal;
          font-weight: $font_weight_bold;
          line-height: 110%;
          letter-spacing: -1.6px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0px;
        top: 0px;
        height: 100%;
        width: 100%;
        z-index: 0;
        background: url('../../images/Background-pattern.png') right center;
        background-position: right center;
      }
    }
  }

  &.conformsec {
    margin-top: -39.3rem;
    position: relative;
    padding-top: 0px;
    padding-bottom: 10.6rem;

    .max-width-container {
      padding-top: 0rem;

      .container {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 8.7rem;

        .connect-title {
          color: $color_2;
          font-family: $font-inter;
          font-size: $text_4;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: 155.556%;
          letter-spacing: -0.036rem;
          margin-bottom: 0rem;
        }

        .edit-card {
          padding: 2.4rem;
          position: relative;
          margin-top: 1px;

          h4 {
            color: $color_6;
            font-size: 2.4rem;
            line-height: 110%;
            letter-spacing: -0.48px;
            margin-bottom: 2.4rem;
          }

          form {
            .form-group {
              margin-bottom: 1.6rem;

              &.country-phn {
                position: relative;

                .country-selector {
                  display: flex;
                  align-items: center;
                  justify-content: flex-start;
                }

                .sorted {
                  position: absolute;
                  bottom: 13%;
                  left: 2%;

                  .dropdown {
                    position: relative;

                    .btn {
                      display: flex;
                      align-items: center;
                      column-gap: 0.6rem;
                      color: $color_4;
                      font-family: $font-inter;
                      font-size: $text_2;
                      font-style: $font_style_1;
                      font-weight: $font_weight_normal;
                      line-height: 142.857%;
                      letter-spacing: -0.014rem;
                      padding: 0rem;
                      border: 0;

                      span {
                        width: 2rem;
                        height: 2rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }

                      &::after {
                        content: none;
                      }

                      &:focus,
                      &:focus-visible {
                        outline: 0;
                        border: 0;
                        box-shadow: none;
                      }
                    }

                    .dropdown-menu {
                      max-height: 21rem;
                      overflow: hidden auto;
                      scrollbar-color: $Neutral-Solid-50 $bg-White;
                      scrollbar-width: thin;

                      &::-webkit-scrollbar-track {
                        background-color: $bg-White;
                        border-radius: 9rem;
                        -webkit-border-radius: 9rem;
                        -moz-border-radius: 9rem;
                        -ms-border-radius: 9rem;
                        -o-border-radius: 9rem;
                      }

                      &::-webkit-scrollbar {
                        background-color: $bg-White;
                        width: 0.8rem;
                      }

                      &::-webkit-scrollbar-thumb {
                        background-color: $Neutral-Solid-50;
                        border-radius: 9rem;
                        -webkit-border-radius: 9rem;
                        -moz-border-radius: 9rem;
                        -ms-border-radius: 9rem;
                        -o-border-radius: 9rem;
                      }
                    }
                  }
                }

                .form-control {
                  padding-left: 10rem;
                }
              }

              textarea {
                resize: none;
                min-height: 8rem;
              }

              label {
                &.form-label {
                  color: $color_2;
                  font-size: 1.4rem;
                  font-weight: $font_weight_bold;
                  line-height: 142.857%;
                }
              }
            }

            .btn-primary {
              margin-top: 0.8rem;
            }
          }
        }

        .rightsec {
          margin-top: 1px;

          .cardssec {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 2.4rem;
            margin-bottom: 2.4rem;
          }

          .bottommd {
            margin-bottom: 5.7rem;
          }

          .visitsocial {
            display: flex;
            align-items: center;
            justify-content: space-between;

            p {
              color: #818898;
              font-size: 1.2rem;
              font-weight: 600;
              line-height: 133.333%;
              letter-spacing: 0.48px;
              text-transform: uppercase;
            }

            ul {
              display: flex;
              align-items: center;
              justify-content: center;
              grid-gap: .8rem;

              li {
                &:nth-child(1) {
                  &:hover {
                    a {
                      background: #2c26e1;
                    }

                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                &:nth-child(2) {
                  &:hover {
                    a {
                      background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
                    }

                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                &:nth-child(3) {
                  &:hover {
                    a {
                      background: #000000;
                    }

                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                &:nth-child(4) {
                  &:hover {
                    a {
                      background: #0077b5;
                    }

                    svg {
                      path {
                        fill: #fff;
                      }
                    }
                  }
                }

                a {
                  height: 3.6rem;
                  width: 3.6rem;
                  overflow: hidden;
                }
              }
            }
          }

          .itemn {
            border-radius: 12px;
            padding: 2.4rem;
            background: $color_5;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
            // min-height: 22.4rem;
            row-gap: 2.4rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            min-width: 30.1rem;

            .tpicon {
              .btn {
                cursor: unset;
              }
            }

            .visues {
              color: #666D80;
              font-size: 1.2rem;
              font-weight: 600;
              line-height: 133.333%;
              letter-spacing: 0.48px;
              text-transform: uppercase;
              margin-bottom: .8rem;
            }

            .masw {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .cssecs {
                .visues {
                  color: #666D80;
                  font-size: 1.2rem;
                  font-weight: 600;
                  line-height: 133.333%;
                  letter-spacing: 0.48px;
                  text-transform: uppercase;
                  margin-bottom: .8rem;
                }

                h5 {
                  color: $color_6;
                  font-family: $suisse_medium;
                  font-size: 1.6rem;
                  line-height: 110%;
                  letter-spacing: -0.2px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.ourlocationsec {
    padding-top: 0;

    .max-width-container {
      border-radius: 16px;
      background: #F8FAFB;
      padding-top: 12rem;
      padding-bottom: 12rem;

      .container {
        h2 {
          color: $color_6;
          font-size: 5.6rem;
          line-height: 128.571%;
          letter-spacing: -1.12px;
          margin-bottom: 6.4rem;

          span {
            color: #818898;
          }
        }

        .location-info {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-gap: 2.4rem;

          .info {
            .office-loc {
              margin: 2.4rem 0 1.6rem 0;
              color: #666D80;
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: 133.333%;
              letter-spacing: 0.48px;
              text-transform: uppercase;

              &.active {
                color: #F79631;
              }
            }

            h5 {
              color: $color_6;
              font-size: 2rem;
              line-height: 150%;
              letter-spacing: -0.4px;
              margin-bottom: .4rem;
            }

            p {
              color: #666D80;
              font-size: 1.8rem;
              font-weight: 400;
              line-height: 155.556%;
              letter-spacing: -0.36px;
            }

            .img-box {
              img {
                max-height: 256px;
                height: 100%;
                object-fit: cover;
                width: 100%;
                border-radius: 1.6rem;
              }
            }
          }
        }

        .hrline {
          margin: 6.4rem 0;
          overflow: hidden;
        }

        .mapinfo {
          iframe {
            max-width: 100%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
            border-radius: 1.6rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  section {
    &.conformsec {
      .max-width-container {
        .container {
          grid-gap: 2.4rem;

          .rightsec {
            .itemn {
              padding: 1.4rem;
              min-width: auto;

              .masw {
                .cssecs {
                  h5 {
                    font-size: 1.6rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


}

@media only screen and (max-width: 992px) {
  section {
    &.conformsec {
      margin-top: -41.3rem;
      padding-bottom: 4rem;

      .max-width-container {
        padding-bottom: 0;

        .container {
          grid-template-columns: 1fr;

          .rightsec {
            .bottommd {
              margin-bottom: 4rem;
            }
          }
        }
      }
    }

    &.ourlocationsec {
      .max-width-container {
        padding-top: 4rem;
        padding-bottom: 4rem;

        .container {
          .location-info {
            .info {
              p {
                font-size: 1.6rem;
              }

              h5 {
                font-size: 1.8rem;
              }
            }
          }

          h2 {
            font-size: 4rem;
            margin-bottom: 4rem;
          }

          .hrline {
            margin: 4rem 0;
          }
        }
      }
    }

    &.contactsec {
      .max-width-container {
        padding-top: 4rem;

        .container {
          h1 {
            font-size: 5.6rem;
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  section {
    &.conformsec {
      .max-width-container {
        padding-left: 0;
        padding-right: 0;

        .container {
          .edit-card {
            form {
              .btn-primary {
                width: 100%;
              }
            }
          }

          .rightsec {
            .cardssec {
              grid-template-columns: 1fr;
            }
          }
        }
      }
    }

    &.ourlocationsec {
      .max-width-container {
        .container {
          .location-info {
            grid-template-columns: 1fr;
          }

          .mapinfo {
            iframe {
              height: 300px;
            }
          }
        }
      }
    }
  }
}