/* ======================== */
/* footer section css start here... */
/* ======================== */
.footer {
  padding: 0 2.4rem 2.4rem;

  .max-width-container {
    background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
        rgba(255, 255, 255, 0.6) 0%,
        rgba(255, 255, 255, 0) 100%),
      radial-gradient(65.8% 62.46% at 97.64% 48.25%,
        #ffddba 0%,
        rgba(255, 255, 255, 0) 100%),
      radial-gradient(120.82% 115.83% at 72.19% 74.45%,
        #eef9fd 0%,
        #eef9fd 24.36%,
        rgba(255, 255, 255, 0) 100%),
      #fff;
    padding: 0;
    overflow: hidden;
  }

  .transback {
    background-image: url("../../images/Background-pattern.png");
    padding: 8rem 2.4rem;
    background-size: cover;
    background-repeat: no-repeat;

    .tpfoot {
      display: flex;
      grid-gap: 4rem;
    }
  }

  h6 {
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0.48px;
  }

  .foot {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 17rem;
    margin: 7.2rem 0 5.6rem;

    .flinks {
      h6 {
        margin-bottom: 1.6rem;
      }

      ul {
        li {
          margin-bottom: 0.8rem;

          .footer-link {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: $lh_1;
            position: relative;

            &:hover,
            &:active,
            &.active {
              color: #000000;
            }

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              left: 0;
              background-color: $color_6;
              height: 0.01rem;
              top: 100%;
              z-index: 1;
              transform-origin: 100% 50%;
              transform: scaleX(0);
              transition: transform .3s cubic-bezier(0.76, 0, 0.24, 1);
            }

            &:hover,
            &:active,
            &.active {
              &::before {
                transform-origin: 0% 50%;
                transform: scaleX(1);
              }
            }
          }
        }
      }
    }
  }

  .sociallinks {
    max-width: 18rem;

    a {
      width: 4rem;
      height: 4rem;
      background: $color_5;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.24);
      border-radius: 50%;
      overflow: hidden;


      &:hover {
        svg {
          path {
            fill: #ffffff;
          }
        }

        &:nth-child(1) {
          background: #2c26e1;
        }

        &:nth-child(2) {
          background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);
        }

        &:nth-child(3) {
          background: #000000;
        }

        &:nth-child(4) {
          background: #0077b5;
        }
      }
    }
  }

  .imgc {
    border: 1px solid #eceff3;
    height: 60px;
    width: 60px;
    backdrop-filter: blur(2.5px);
  }

  .coprightsec {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    .inner {
      display: flex;
      align-items: center;
      grid-gap: 0.8rem;

      ul {
        &.dropdown-menu {
          a {
            font-size: 1.6rem;
          }
        }
      }
    }
  }

  .coundrop {
    .dropdown-toggle {
      &::after {
        border: none !important;
      }
    }
  }
}

/* ======================== */
/* footer section css end here... */
/* ======================== */

@media only screen and (max-width: 1199px) {
  .footer {
    .transback {
      padding: 4rem 2.4rem;
    }

    .foot {
      margin: 4rem 0 5.6rem;
    }
  }
}

@media only screen and (max-width: 992px) {
  .footer {
    .foot {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.5rem;
      margin-top: 4rem;
      margin: 2rem 0;

      .flinks {
        ul {
          li {
            a {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .sociallinks {
      a {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .footer {
    padding: 0 1.6rem 1.6rem;

    .max-width-container {
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(70.49% 73.04% at 31.2% 67.01%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(120.82% 115.83% at 72.19% 74.45%,
          #eef9fd 0%,
          #eef9fd 24.36%,
          rgba(255, 255, 255, 0) 100%),
        #fff;
    }

    .foot {
      grid-template-columns: repeat(auto-fit, minmax(49%, 1fr));
      /* First four child divs with 50% width each, last child div with 100% width */
      gap: 0.8rem;

      >:last-child {
        grid-column: span 2
          /* Last child spans 2 columns for 100% width */
        ;
      }
    }

    .coprightsec {
      justify-content: center;
      justify-content: center;
      flex-direction: column;
      gap: 2.4rem;

      .inner {
        font-size: 1.4rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    .flinks {
      .sociallinks {
        grid-gap: 0.8rem;
      }
    }
  }
}

@media only screen and (max-width: 420px) {

  .max-w {
    max-width: 12rem;
  }
}