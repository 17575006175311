.manageUser {
  .manage-top {
    margin-bottom: 1.6rem;
  }

  .title {
    color: $color_6;
    font-size: $text_5;
    font-style: $font_style_1;
    font-weight: $font_weight_bold;
    line-height: 140%;
    margin-bottom: 2.4rem;
    display: flex;
    align-items: center;
    column-gap: 0.8rem;

    span {
      width: 3.6rem;
      height: 3.6rem;
      border: 0.2rem solid $bg-White;
      background: $color_8;
      border-radius: 5.6rem;
      -webkit-border-radius: 5.6rem;
      -moz-border-radius: 5.6rem;
      -ms-border-radius: 5.6rem;
      -o-border-radius: 5.6rem;
      min-width: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0.2rem;

      img {
        mix-blend-mode: multiply;
        object-fit: contain;
      }
    }
  }

  .title-desc {
    color: $color_3;
    font-family: $font-inter;
    font-size: $text_2;
    font-style: $font_style_1;
    font-weight: $font_weight_normal;
    line-height: 157.143%;
    letter-spacing: -0.014rem;
    max-width: 64.5rem;
  }

  .manage-user-list {
    .manage-table {
      margin-bottom: 2.4rem;
    }

    .add-products {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 1.6rem;
      background: $color_9;
      padding: 2.4rem;
      margin-bottom: 2.4rem;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;

      .ng-select {

        .ng-select-container {
          padding: 0.8rem 1rem;
          background-color: $bg-White;
          box-shadow: $shadow-4;
          border-radius: 0.6rem;
          -webkit-border-radius: 0.6rem;
          -moz-border-radius: 0.6rem;
          -ms-border-radius: 0.6rem;
          -o-border-radius: 0.6rem;
          color: $color_4;
          font-family: $font-inter;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: 142.857%;
          letter-spacing: -0.14px;

          &:focus {
            box-shadow: $shadow-4;
            border-color: #c1c1c1;
          }
        }
      }
    }



    .detail-head {
      display: flex;
      align-items: center;
      column-gap: 1.6rem;
      margin-bottom: 1.6rem;

      .title {
        color: $color_6;

        font-size: $text_3;
        font-style: $font_style_1;
        font-weight: $font_weight_normal;
        line-height: 150%;
        letter-spacing: -0.016rem;
        margin-bottom: 0rem;
      }

      .btn {
        color: $color_4;
        box-shadow: none;
      }
    }

    .edit-users {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 2.4rem;
      align-items: flex-start;

      &.edit-groups {
        grid-template-columns: 1fr;
      }

      &.edit-allocation {
        grid-template-columns: 55% auto;

        .edit-card {
          .card-body {
            .form-group {
              margin-bottom: 2.4rem;
            }
          }
        }
      }

      &.user-profile {
        grid-template-columns: 1fr 1fr;
      }

      &.products {
        grid-template-columns: 64% auto;
      }

      &.order-cards {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        margin-bottom: 2rem;
      }

      &.customer-order-card {
        grid-template-columns: 1fr 1fr 1fr;
        margin-bottom: 2rem;
        align-items: stretch;
      }

      &.order-ship {
        grid-template-columns: 1fr 3fr;
        margin-bottom: 2rem;
      }

      .no-content {
        background: $color_9;
        box-shadow: $shadow-2;
        padding: 0.65rem;
        height: 14.2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        margin-bottom: 1.6rem;
        row-gap: 0.392rem;

        .no-content-icon {
          border: 0.1rem solid $border-neutral-solid;
          background: $bg-linear3;
          backdrop-filter: blur(0.25rem);
          padding: 1.2rem;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          span {
            background: $bg_linear2;
            box-shadow: $shadow-6;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.6rem;
            height: 3.6rem;
            padding: 0.8rem;
            border-radius: 99rem;
            -webkit-border-radius: 99rem;
            -moz-border-radius: 99rem;
            -ms-border-radius: 99rem;
            -o-border-radius: 99rem;
          }
        }

        .no-content-title {
          color: $color_3;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 171.429%;
          letter-spacing: -0.014rem;
          margin-bottom: 0rem;
        }
      }

      .size-card {
        background: $bg_linear2;
        box-shadow: $shadow-2;
        border-radius: 1.2rem;
        -webkit-border-radius: 1.2rem;
        -moz-border-radius: 1.2rem;
        -ms-border-radius: 1.2rem;
        -o-border-radius: 1.2rem;
        border: 0rem;
        overflow: hidden;
        width: fit-content;
        margin-bottom: 1.6rem;

        .card-body {
          padding: 0rem;

          >div:first-child {
            background: $color_9;
          }

          >div:not(:first-child) {
            border-bottom: 0.1rem solid $border-neutral-solid;
          }

          >div:not(:last-child) {
            border-bottom: 0.1rem solid $border-neutral-solid;
          }

          .row-chart {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            > :not(:last-child) {
              border-right: 0.1rem solid $border-neutral-solid;
            }

            .adds {
              padding: 0.8rem 1.2rem;
              width: 19rem;
              min-height: 8.8rem;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 0.8rem;

              .head-title {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 171.429%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
              }
            }
          }
        }
      }

      .employee-detail-order {

        .table {

          thead,
          tbody {
            tr {

              td,
              th {
                white-space: nowrap;
              }

              td {
                p {
                  max-width: 100%;
                }
              }
            }
          }
        }
      }

      &.order-report-card {
        grid-template-columns: 1fr;
      }
    }

    .notification-cards {
      background: $bg_linear2;
      box-shadow: $shadow-2;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;

      .notification-card {
        .card-body {
          padding: 2.4rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          column-gap: 0.8rem;

          .card-titles {
            display: flex;
            align-items: center;
            column-gap: 0.8rem;
            justify-content: flex-start;
            position: relative;

            .title {
              color: $text-loud-900;
              font-family: $font-inter;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: 157.143%;
              letter-spacing: -0.014rem;
              margin-bottom: 0rem;
            }

            .noti-icon {
              width: 4.4rem;
              min-width: 4.4rem;
              height: 4.4rem;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 5.6rem;
              -webkit-border-radius: 5.6rem;
              -moz-border-radius: 5.6rem;
              -ms-border-radius: 5.6rem;
              -o-border-radius: 5.6rem;
            }

            .noti-icon.icon-warn {
              background: $secondary-orange-25;
              color: $secondary-orange-300;

              svg {
                circle {
                  fill: $secondary-orange-300;
                }

                path {
                  stroke: $secondary-orange-300;
                }
              }
            }

            .noti-icon.icon-info {
              background: $Accent-Aqua-25;
              color: $Accent-Aqua-300;

              svg {
                circle {
                  fill: $Accent-Aqua-300;
                }

                path {
                  stroke: $Accent-Aqua-300;
                }
              }
            }

            &::before {
              content: "";
              position: absolute;
              top: -18%;
              left: -2%;
              width: 0.8rem;
              height: 0.8rem;
              border-radius: 5rem;
              -webkit-border-radius: 5rem;
              -moz-border-radius: 5rem;
              -ms-border-radius: 5rem;
              -o-border-radius: 5rem;
              background: $secondary-orange-300;
            }
          }
        }
      }

      >div:not(:last-child) {
        border-bottom: 0.1rem solid $border-neutral-solid;
      }

      > :first-child {
        border-radius: 1.2rem 1.2rem 0rem 0rem;
        -webkit-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -moz-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -ms-border-radius: 1.2rem 1.2rem 0rem 0rem;
        -o-border-radius: 1.2rem 1.2rem 0rem 0rem;
      }

      > :last-child {
        border-radius: 0rem 0rem 1.2rem 1.2rem;
        -webkit-border-radius: 0rem 0rem 1.2rem 1.2rem;
        -moz-border-radius: 0rem 0rem 1.2rem 1.2rem;
        -ms-border-radius: 0rem 0rem 1.2rem 1.2rem;
        -o-border-radius: 0rem 0rem 1.2rem 1.2rem;
      }
    }

    .no-content-ship {
      position: relative;
      background: $bg-White;
      box-shadow: $shadow-2;
      padding: 0.65rem;
      height: 14.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 1.2rem;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      -ms-border-radius: 1.2rem;
      -o-border-radius: 1.2rem;
      margin-bottom: 1.6rem;
      row-gap: 0.392rem;

      .no-content-icon {
        border: 0.1rem solid $border-neutral-solid;
        background: $bg-linear3;
        backdrop-filter: blur(0.25rem);
        padding: 1.2rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        span {
          background: $bg_linear2;
          box-shadow: $shadow-6;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 3.6rem;
          height: 3.6rem;
          padding: 0.8rem;
          border-radius: 99rem;
          -webkit-border-radius: 99rem;
          -moz-border-radius: 99rem;
          -ms-border-radius: 99rem;
          -o-border-radius: 99rem;
        }
      }

      .no-content-title {
        color: $color_3;

        font-size: $text_2;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 171.429%;
        letter-spacing: -0.014rem;
        margin-bottom: 0rem;
      }

      &.ship-h {
        height: 100vh;
      }

      &::before {
        content: "";
        background: url("../../images/Background-pattern.png");
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: -1;
      }
    }

    .wizard-tabs {

      .nav {
        column-gap: 2.4rem;
        align-items: center;
        margin-bottom: 2.4rem;

        .nav-item {
          .border-top-item {
            width: 1rem;
            border: 0.1rem dashed #a4acb9;
            border-radius: 0.2rem;
          }

          .nav-link {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            column-gap: 1.2rem;
            padding: 0rem;
            border-radius: 0rem;
            //&:not(.active);
            -webkit-border-radius: 0rem;
            -moz-border-radius: 0rem;
            -ms-border-radius: 0rem;
            -o-border-radius: 0rem;

            span {
              width: 3.6rem;
              height: 3.6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background: $bg-White;
              box-shadow: $shadow-6;
              border-radius: 9.9rem;
              -webkit-border-radius: 9.9rem;
              -moz-border-radius: 9.9rem;
              -ms-border-radius: 9.9rem;
              -o-border-radius: 9.9rem;

              svg {
                path {
                  stroke: $color_3;
                }

                &.incompleted {
                  display: block;
                }

                &.completed {
                  display: none;
                }
              }
            }

            h6 {
              color: $color_4;

              font-size: $text_1;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: 166.667%;
              letter-spacing: -0.024rem;
              margin-bottom: 0rem;
              text-align: start;
            }

            h5 {
              color: $color_3;

              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: 171.429%;
              letter-spacing: -0.014rem;
              margin-bottom: 0rem;
              text-align: start;
            }

            &.active {
              background: transparent;

              span {
                svg {
                  path {
                    stroke: $color_6;
                  }

                  &.incompleted {
                    display: block;
                  }

                  &.completed {
                    display: none;
                  }
                }
              }

              h6 {
                color: $color_3;
              }

              h5 {
                color: $color_6;
              }
            }

            &.complete {

              span {
                border-radius: 99px;
                border: 1px solid #9CD1C7;
                background: linear-gradient(180deg, #DDF3EF 0%, rgba(221, 243, 239, 0.00) 120%);
                box-shadow: none;

                svg {
                  path {
                    stroke: #184E44;
                  }

                  &.incompleted {
                    display: none;
                  }

                  &.completed {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }

    .customer-wizard {
      padding-bottom: 8rem;

      .wizard-footer {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3.2rem;
        background: $bg-White;
        position: fixed;
        right: 0;
        bottom: 0;
        z-index: 11;
        border-top: 0.1rem solid $border-neutral-solid;
        width: 100%;
        padding-left: 25.5rem;
      }
    }

    .overview-list {
      .overview-content {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 6.4rem 2rem;

        ul {
          li {
            p {
              color: $color_6;
              font-size: $text_2;
              font-style: $font_style_1;
              font-weight: $font_weight_normal;
              line-height: 157.143%;
              letter-spacing: -0.014rem;
              margin-bottom: 0rem;
              display: flex;
              align-items: center;
              column-gap: 1.2rem;

              span {
                color: $color_3;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
                width: 13.5rem;
                display: inline-block;
              }
            }
          }

          > :not(:last-child) {
            margin-bottom: 1.2rem;
          }
        }
      }

      .customer-overview {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;

        span.badge {
          padding: 0.2rem 1rem 0.2rem 0.2rem;
          align-items: center;
          margin-bottom: 0.8rem;
        }

        p {
          color: $color_6;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: 157.143%;
          letter-spacing: -0.014rem;
          margin-bottom: 0.4rem;
        }

        .small-content {
          color: $color_3;
          // @extend p;
        }

        &.grid-row {
          grid-template-columns: 1fr;
          row-gap: 2rem;
        }
      }
    }

    .products-cards {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 2.4rem;
      margin-bottom: 6.4rem;
    }

    .form-Image-label {
      background-image: url("../../images/Background-pattern.png");
      background-origin: border-box;
      background-position: center;
      background-size: cover;
      cursor: pointer;
      background-color: $bg-White;
      box-shadow: 0rem 0rem 0rem 0.0327rem rgba(18, 55, 105, 0.08),
        0px 0.327px 0.653px 0px rgba(164, 172, 185, 0.24);
      border-radius: 0.3918rem;
      -webkit-border-radius: 0.3918rem;
      -moz-border-radius: 0.3918rem;
      -ms-border-radius: 0.3918rem;
      -o-border-radius: 0.3918rem;
      width: 100%;
      display: flex;
      height: 16.6rem;
      padding: 0.6531rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.3918rem;
      position: relative;

      .uploader-position {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 0.3918rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        cursor: pointer;
        pointer-events: all;

        .Image-upload-icon {
          border: 0.1rem solid $Neutral-Solid-50;
          background: $bg-linear3;
          -webkit-backdrop-filter: blur(0.25rem);
          backdrop-filter: blur(0.25rem);
          padding: 1.2rem;
          border-radius: 50%;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -ms-border-radius: 50%;
          -o-border-radius: 50%;

          span {
            background: $bg_linear2;
            box-shadow: $shadow-6;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 3.6rem;
            height: 3.6rem;
            padding: 0.8rem;
            border-radius: 99rem;
            -webkit-border-radius: 99rem;
            -moz-border-radius: 99rem;
            -ms-border-radius: 99rem;
            -o-border-radius: 99rem;
          }
        }

        .Image-upload-title {
          color: $color_3;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 171.429%;
          letter-spacing: -0.014rem;
          margin-bottom: 0rem;
        }
      }

      .preview-content {
        padding: 1.6rem;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $color_8;
        border-radius: 1.6rem;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        .dlt-top {
          position: absolute;
          right: 11%;
          top: 9%;
        }
      }

      &.images {
        border: 0.1rem solid $border-neutral-solid;
        background-color: $bg-White;
        border-radius: 0.8rem;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        -ms-border-radius: 0.8rem;
        -o-border-radius: 0.8rem;
        padding: 1.6rem;
        height: 36.8rem;
        justify-content: flex-end;
        gap: 2.4rem;
        cursor: auto;

        .badge-front {
          color: $color_3;
          text-align: center;
          font-family: $font-inter;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_bold;
          line-height: 142.857%;
          border: 0.1rem solid $border-neutral-solid;
          background: $bg-White;
          border-radius: 9.6rem;
          -webkit-border-radius: 9.6rem;
          -moz-border-radius: 9.6rem;
          -ms-border-radius: 9.6rem;
          -o-border-radius: 9.6rem;
          padding: 0.6rem 1.2rem;
        }

        .preview-content {
          max-height: 100%;

          img {
            max-width: 24.5rem;
            max-height: 24.5rem;
            border-radius: 1.6rem;
            object-fit: cover;
            mix-blend-mode: multiply;
          }
        }
      }

      .default-image-checkbox {
        position: absolute;
        right: 5%;
        bottom: 7%;
      }
    }

    .shipment-accord {
      .accordion {
        .accordion-item {
          background: $bg_linear2;
          box-shadow: $shadow-2;
          border-radius: 1.2rem;
          -webkit-border-radius: 1.2rem;
          -moz-border-radius: 1.2rem;
          -ms-border-radius: 1.2rem;
          -o-border-radius: 1.2rem;
          margin-bottom: 1.6rem;

          .accordion-header {
            .accordion-button {
              border-radius: 1.2rem 1.2rem 0rem 0rem;
              -webkit-border-radius: 1.2rem 1.2rem 0rem 0rem;
              -moz-border-radius: 1.2rem 1.2rem 0rem 0rem;
              -ms-border-radius: 1.2rem 1.2rem 0rem 0rem;
              -o-border-radius: 1.2rem 1.2rem 0rem 0rem;
              transition: all 0.2s ease-in;
              -webkit-transition: all 0.2s ease-in;
              -moz-transition: all 0.2s ease-in;
              -ms-transition: all 0.2s ease-in;
              -o-transition: all 0.2s ease-in;
              border-bottom: 0.1rem solid $border-neutral-solid;
              background: transparent;
              box-shadow: none;
              display: grid;
              grid-template-columns: 1fr 33rem auto;
              align-items: center;
              height: 5.6rem;

              h4 {
                color: $color_3;
                font-family: $font-inter;
                font-size: $text_1;
                font-style: $font_style_1;
                font-weight: 600;
                line-height: 133.333%;
                letter-spacing: 0.048rem;
                text-transform: uppercase;
                margin-bottom: 0rem;
              }

              p {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: $lh_1;
                margin-bottom: 0rem;

                span {
                  color: $color_3;
                  font-family: $font-inter;
                  font-size: $text_2;
                  font-style: $font_style_1;
                  font-weight: $font_weight_normal;
                  line-height: $lh_1;
                  margin-right: 0.6rem;
                }
              }

              &.collapsed {
                border-radius: 1.2rem;
                -webkit-border-radius: 1.2rem;
                -moz-border-radius: 1.2rem;
                -ms-border-radius: 1.2rem;
                -o-border-radius: 1.2rem;
                border-bottom: 0rem;
              }

              &::after {
                content: none;
              }
            }
          }

          .accordion-body {
            padding: 0rem;

            .manage-table {
              margin-bottom: 0rem;
              padding: 0rem;

              table {
                background: transparent;
                box-shadow: none;
                border-radius: 1.2rem;
                -webkit-border-radius: 0rem;
                -moz-border-radius: 1.2rem;
                -ms-border-radius: 1.2rem;
                -o-border-radius: 1.2rem;
                overflow: visible;
                margin-bottom: 0rem;

                tr {
                  th {

                    &:first-child,
                    &:last-child {
                      border-radius: 0rem;
                      -webkit-border-radius: 0rem;
                      -moz-border-radius: 0rem;
                      -ms-border-radius: 0rem;
                      -o-border-radius: 0rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .edit-btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    column-gap: 1.6rem;

    .status {
      color: $color_3;
      font-size: $text_2;
      font-style: $font_style_1;
      font-weight: $font_weight_normal;
      line-height: $lh_1;
      margin-bottom: 0rem;
      @extend .edit-btns;
      column-gap: 0.8rem;
    }
  }

  .order-top-detail {
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 2.2rem;
      margin-bottom: 3.2rem;

      li {
        p {
          color: $color_6;
          font-size: $text_2;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: $lh_1;
          margin-bottom: 0rem;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          column-gap: 0.8rem;

          span {
            color: $color_3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1299px) {

  .manageUser {
    .manage-user-list {
      .edit-users {
        &.edit-allocation {
          grid-template-columns: 70%;
        }

        &.order-cards {
          grid-template-columns: 1fr 1fr;
        }

        &.products {
          grid-template-columns: 80% auto;
        }
      }
    }
  }
}

@media screen and (max-width: 1190px) {

  .manageUser {
    .manage-user-list {

      &.employee-table {

        .table-head {
          flex-direction: column;
          align-items: center;
          gap: 1rem;

          .position-relative {
            width: 100%;
          }

          .sorted {
            width: 100%;
            justify-content: space-between;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {

  .manageUser {

    .manage-user-list {
      .edit-users {
        &.edit-allocation {
          grid-template-columns: 100%;
        }
      }
    }

  }

}

@media screen and (max-width: 991px) {
  .manageUser {

    .backs {
      margin-bottom: 1.6rem;
    }

    .edit-btns {
      flex-direction: column;
      gap: 1.6rem;
      margin-bottom: 1.6rem;
    }

    .order-top-detail {

      ul {
        flex-direction: column;
        align-items: flex-start;
        gap: 1.2rem;
        margin-bottom: 2rem;

        li {

          p {
            align-items: flex-start;

            span {
              white-space: nowrap;
            }
          }
        }
      }
    }

    .manage-top {

      .btn-primary {
        width: 100%;
      }
    }

    .title {
      margin-bottom: 1.6rem;
      font-family: $suisse_medium;
    }

    .manage-user-list {
      .edit-users {
        grid-template-columns: 1fr;

        .edit-card {

          .card-head {

            .head-title {
              flex-wrap: wrap;
              gap: 1.2rem;
            }
          }

          .uploader {

            .preview-content {
              height: 10rem;
            }
          }

          .form-group {

            .btn.btn-primary,
            .btn.btn-secondary {
              width: 100%;
            }
          }

          .auths {
            gap: 0.4rem;
          }

          .desc-btns {
            overflow: auto hidden;

            .btns {
              overflow: visible;

              .NgxEditor__MenuBar {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: flex-start;
              }
            }
          }

          &.order-card {
            margin-bottom: 0rem;
          }
        }

        &.edit-allocation {
          grid-template-columns: 100%;
        }

        &.order-cards {
          grid-template-columns: 1fr;
          gap: 1.6rem;
        }

        &.order-ship {
          grid-template-columns: 1fr;
          margin-bottom: 0rem;
          gap: 1.6rem;
        }

        &.products {
          grid-template-columns: 100%;
        }

        &.customer-order-card {
          grid-template-columns: 1fr;
          margin-bottom: 1.6rem;
          gap: 1.6rem;
        }

        .size-card {
          width: 100%;

          .card-body {
            padding: 0rem;

            >div:not(:first-child) {
              background: $bg_linear2;
              box-shadow: $shadow-2;
              border-radius: 1.2rem;
              -webkit-border-radius: 1.2rem;
              -moz-border-radius: 1.2rem;
              -ms-border-radius: 1.2rem;
              -o-border-radius: 1.2rem;
              border: 0rem;
              margin: 1rem;
            }

            .row-chart {
              flex-direction: column;

              > :not(:last-child) {
                border-right: 0rem solid $border-neutral-solid;
                border-bottom: 0.1rem solid $border-neutral-solid;
              }

              .adds {
                width: 100%;
                min-height: auto;

                .form-group {
                  width: 100%;
                }

              }
            }
          }
        }
      }

      .no-content-ship {
        &.ship-h {
          height: 70vh;
        }
      }

      .add-products {
        grid-template-columns: 1fr;
        margin-bottom: 1.6rem;
      }

      .wizard-tabs {

        .nav {
          column-gap: 1.2rem;
          align-items: center;
          margin-bottom: 1.6rem;
          flex-wrap: nowrap;
          white-space: nowrap;
          background: $bg-White;
          box-shadow: $shadow-4;
          padding: 0.8rem;
          overflow: auto hidden;
          border-radius: 0.6rem;
          -webkit-border-radius: 0.6rem;
          -moz-border-radius: 0.6rem;
          -ms-border-radius: 0.6rem;
          -o-border-radius: 0.6rem;
        }
      }

      .customer-wizard {
        padding-bottom: 18rem;

        .wizard-footer {
          width: 100%;
          flex-direction: column;
          padding: 2.4rem 1.6rem;
          gap: 2.4rem;
          align-items: stretch;

          .btn {
            width: 100%;
          }
        }
      }

      .products-cards {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.4rem 1.7rem;
      }

      .overview-list {
        .overview-content {
          display: grid;
          grid-template-columns: 1fr;
          gap: 2rem;

          ul {

            li {

              p {
                span {
                  width: auto;
                }
              }
            }
          }
        }

        .customer-overview {
          grid-template-columns: 1fr;
          gap: 2rem;
        }
      }

      .notification-cards {

        .notification-card {

          .card-body {
            flex-direction: column;
            gap: 1.2rem;
            align-items: flex-start;

            .btn {
              width: 100%;
            }
          }
        }
      }

      .ship-order-head {
        display: flex;
        gap: 1.2rem;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 2.4rem;

        .title {
          color: $color_1;
          font-family: $font-inter;
          font-size: $text_4;
          font-weight: $font_weight_bold;
          line-height: $lh_1;
          letter-spacing: -0.018rem;
          margin-bottom: 0.8rem;
        }

        p {
          color: $color_3;
          font-family: $font-inter;
          font-size: $text_2;
          font-weight: $font_weight_normal;
          line-height: $lh_1;

          span {
            color: $color_6;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .manageUser {
    .w-sm-100 {
      width: 100% !important;
    }

    .manage-user-list {

      .edit-users {

        .employee-detail {

          .titles {

            .name-icon {
              transform: translate(0%, -83%);
              -webkit-transform: translate(0%, -83%);
              -moz-transform: translate(0%, -83%);
              -ms-transform: translate(0%, -83%);
              -o-transform: translate(0%, -83%);
            }
          }
        }

      }

      .products-cards {
        grid-template-columns: 1fr 1fr;
        gap: 2.4rem 1.7rem;
      }
    }
  }

  .pageBreadcrumb {
    margin-bottom: 0.6rem;
  }
}

@media screen and (max-width: 576px) {

  .add-products {

    .ng-select {
      max-width: 79vw;
    }
  }
}