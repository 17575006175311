section {
  &.industries {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(65.8% 62.46% at 97.64% 48.25%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(120.82% 115.83% at 72.19% 74.45%,
          #eef9fd 0%,
          #eef9fd 24.36%,
          rgba(255, 255, 255, 0) 100%),
        #fff;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      padding-bottom: 36rem;

      p {
        color: $color_2;
        font-size: 1.8rem;
        max-width: 588px;
        line-height: 155.556%;
        letter-spacing: -0.36px;
      }

      h1 {
        line-height: 110%;
        letter-spacing: -1.6px;
        margin: 1.6rem 0 0.8rem 0;
      }

      .text-wrapper {
        color: $color_3;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 133.333%;
        letter-spacing: 0.48px;
        text-transform: uppercase;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url("../../images/Background-pattern.png");
        z-index: 0;
        background-position: center right;
      }
    }
  }

  &.indusbottom {
    margin-top: -36rem;
    position: relative;

    .items {
      border-radius: 24px;
      background: $color_5;
      margin-bottom: 2.4rem;
      display: grid;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.24);
      grid-template-columns: 1fr 1fr;
      min-height: 38.8rem;

      .content {
        padding: 4.8rem 2.4rem 2.4rem 4.8rem;

        .button {
          display: inline-block;
          border-radius: 999px;
          background: var(--Form-Button-Secondary-Normal,
              linear-gradient(180deg,
                rgba(255, 255, 255, 0) 0%,
                rgba(193, 199, 208, 0.02) 100%),
              #fff);
          box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
            0px 1px 2px 0px rgba(164, 172, 185, 0.24),
            0px 9px 16px -4px rgba(0, 0, 0, 0.13),
            0px -7px 10px -5px rgba(0, 0, 0, 0.06) inset;
          padding: 0.8rem;
          height: 3.6rem;
          width: 3.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .info {
          margin: 2.4rem 0;

          .textwrapper {
            margin-bottom: 0.8rem;
            font-family: $suisse_medium;
            color: $color_6;
            font-size: 2.4rem;
            line-height: 110%;
            letter-spacing: -0.48px;
          }

          p {
            color: $color_2;
            font-size: 1.8rem;
            line-height: 155.556%;
            letter-spacing: -0.36px;
          }
        }

        .brands {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          grid-gap: 2.4rem;

          .clients {
            max-width: 13rem;
            min-height: 4rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 100%;
              height: 100%;
            }

            &.max-8 {
              max-width: 8rem;
            }
          }
        }
      }

      .img-box {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: url("../../images/tranpagebg1.svg");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: -1;
        }

        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 0;
          height: 100%;
          width: 100%;
          background: url(../../images/tranpagebg2.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
          z-index: -1;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  section {
    &.indusbottom {
      .items {
        min-height: auto;

        .content {

          .brands {
            .clients {
              max-width: 10rem;
              min-height: 4rem;

              &.max-8 {
                max-width: 6rem;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  section {
    &.industries {
      .max-width-container {
        padding-bottom: 8.2rem;
        padding-top: 4rem;

        h1 {
          font-size: 4rem;
        }
      }
    }

    &.indusbottom {
      margin-top: -8.2rem;

      .max-width-container {
        padding: 0;
      }

      .items {
        grid-template-columns: 1fr;

        .content {
          padding: 3.2rem 2.4rem;

          .brands {
            grid-gap: 2rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  section {
    &.indusbottom {
      .items {
        min-height: auto;

        .content {

          .brands {
            grid-gap: 1.5rem;

            .clients {
              max-width: 8rem;
              min-height: 4rem;

              &.max-8 {
                max-width: 5rem;
              }
            }
          }
        }
      }
    }
  }
}