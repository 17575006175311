/* ======================== */
/* transforming section css start here... */
/* ======================== */
section {
  &.transforming {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%,
          rgba(255, 255, 255, 0.6) 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(36.52% 34.86% at 82.15% 31.8%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        linear-gradient(180deg, #eef9fd 18.07%, rgba(255, 255, 255, 0) 100%);

      .transback {
        background-image: url("../../images/Background-pattern.png");
        min-height: 90rem;

        .ourclientsec {
          margin-top: 7.5rem;

          h5 {
            margin-bottom: 1.8rem;
          }
        }
      }
    }

    .ourclientsec {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        flex-wrap: wrap;

        img {
          mix-blend-mode: multiply;
        }
      }
    }

    h1 {
      margin-right: -5rem;
    }

    .contents {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;

      .items {
        .hpba {
          bottom: 10%;
          left: 21%;

          .btn {
            padding: 0.8rem 1.4rem;
          }
        }

        .button-grps {
          display: flex;
          grid-gap: 20px;

          a {
            padding: 8px 20px;
            display: inline-block;
          }
        }
      }

      p {
        margin: 14px 0px 20px;
      }
    }

    .allocation-box {
      top: 20%;
      right: 12%;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.16),
        0px 24px 32px -12px rgba(54, 57, 74, 0.24);
      backdrop-filter: blur(5px);
      border-radius: 5rem;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      -ms-border-radius: 5rem;
      -o-border-radius: 5rem;

      .alocbox {
        display: flex;
        padding: 0.8rem;
        align-items: center;

        a {
          height: 3.2rem;
          display: flex;
          align-items: center;
          margin-right: 0.6rem;
        }
      }
    }

    a {
      &.cart-box {
        right: 17%;
        bottom: 22%;
        height: 5.6rem;
        width: 5.6rem;
        box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
          0px 1px 2px 0px rgba(164, 172, 185, 0.24);
      }
    }
  }
}

/* ======================== */
/* transforming section css end here... */
/* ======================== */

/* ======================== */
/* Hivis section css start here... */
/* ======================== */
section {
  &.hivis {
    margin-top: -19rem;
    padding-top: 0;
    margin-bottom: 0.4rem;

    .position-absolute {
      bottom: 2rem;
      left: 2rem;

      h4 {
        margin-bottom: 0;
      }
    }

    .contents {
      display: grid;
      grid-gap: 2.4rem;
      grid-template-columns: 1fr 1fr;

      .newitems {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.6rem;

        .bg-warning {
          background-color: #F68C1E !important;
        }
      }
    }

    .bag {
      position: absolute;
      top: 2rem;
      left: 2rem;
    }
  }
}

/* ======================== */
/* Hivis section css end here... */
/* ======================== */

/* ======================== */
/* weKnow section css start here... */
/* ======================== */
section {
  &.weKnow {
    padding-bottom: 7.2rem;

    .max-width-container {
      background: #06273b;
      padding-top: 12rem;
      padding-bottom: 12rem;
    }

    .contents {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
    }

    .shild {
      grid-gap: 2.4rem;
      display: grid;
      grid-template-columns: 5.6rem 1fr;

      span {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.1);
        width: 56px;
        height: 56px;
      }

      p {
        opacity: 0.8;
      }
    }

    h2 {
      margin-bottom: 6.4rem;

      span {
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
}

/* ======================== */
/* weKnow section css end here... */
/* ======================== */

/* ======================== */
/* about section css start here... */
/* ======================== */
section {
  &.about {
    padding: 14.4rem 0;

    .max-width-container {
      padding-top: 0;
      padding-bottom: 1rem;
    }

    .contentsb {
      margin-top: 7.2rem;

      .minhead {
        margin-bottom: 1.6rem;
      }

      h3 {
        margin-bottom: 1.6rem;
      }
    }

    .container {
      h5 {
        max-width: 58%;
        margin-bottom: 2.4rem;
        font-size: 2rem;
        line-height: 3.2rem;
      }

      .items {
        ul {
          &.brns {
            display: flex;
            align-items: center;
            justify-content: center;
            grid-gap: 4rem;
            margin-top: 2.4rem;
            flex-wrap: wrap;
          }
        }
      }

      .hrline {
        margin: 7.2rem 0;
      }
    }
  }
}

/* ======================== */
/* about section css end here... */
/* ======================== */

/* ======================== */
/* sumsProduct section css start here... */
/* ======================== */
section {
  &.sumsProduct {
    .max-width-container {
      overflow: hidden;
      padding: 0 2rem;
    }

    .contents {
      display: grid;
      grid-template-columns: 7fr 6fr;
      align-items: center;

      .items {
        margin-left: -14rem;
        position: relative;
        z-index: 111 !important;

        .btngrps {
          display: flex;
          grid-gap: 16px;

          .btn {
            padding: 0.8rem 1.6rem;
          }
        }
      }
    }

    .orders {
      background-color: #f8fafb;
      z-index: 11;
      min-width: 48.5rem;
      top: 73%;
      left: 5%;

      h4 {
        padding: 1.6rem 2.4rem;
        margin: 0 0.8rem;
      }

      .itembox {
        background: $color_5;
        padding: 1.6rem 2.4rem;
        margin: 0 0.8rem;

        ul {
          li {
            font-size: 1.4rem;
          }
        }
      }

      .accordion-flush {
        .accordion-item {
          border: none;

          .accordion-button {
            background-color: #f8fafb;
            box-shadow: none;
            font-size: 1.4rem;
            border-radius: 1.2rem;
            -webkit-border-radius: 1.2rem;
            -moz-border-radius: 1.2rem;
            -ms-border-radius: 1.2rem;
            -o-border-radius: 1.2rem;

            span {
              height: 2.4rem;
              width: 2.4rem;
            }
          }
        }
      }
    }

    .frm {
      display: flex;
      grid-gap: 2.4rem;
    }

    .sizes {
      border-radius: 5rem;
      top: 11%;
      z-index: 1;
      left: 4%;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.24);

      input {
        display: none;

        &:checked {
          &+label {
            background: #06273b;
            color: #fff;
          }
        }
      }

      label {
        width: 4.8rem;
        cursor: pointer;
        height: 4.8rem;
        border-radius: 50%;
        background: #f6f8fa;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .sumbgsvg {
      top: 40%;
      transform: translateY(-50%);
      left: 0;
    }

    .size-selector {
      position: absolute;
      background: $color_5;
      top: 26%;
      right: 32%;
      padding: 1.6rem;
      border-radius: 5rem;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      -ms-border-radius: 5rem;
      -o-border-radius: 5rem;

      input {
        display: none;

        &:checked {
          &+label {
            &::before {
              content: "";
              background: transparent;
              height: 48px;
              width: 48px;
              position: absolute;
              border: 1px solid #06273b;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              border-radius: 50%;
              -webkit-transform: translate(-50%, -50%);
              -moz-transform: translate(-50%, -50%);
              -ms-transform: translate(-50%, -50%);
              -o-transform: translate(-50%, -50%);
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }

            &::after {
              content: "";
              position: absolute;
              width: 16px;
              top: -6px;
              right: -10px;
              height: 16px;
              background: url(../../images/checkimgright.svg);
              background-repeat: no-repeat;
            }
          }
        }
      }

      label {
        width: 32px;
        height: 32px;
        cursor: pointer;
        position: relative;
        border: 0px solid #ccc;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;

        &.labelA {
          background: #f89543;
        }

        &.labelB {
          background: #f8d043;
        }
      }
    }

    .img-box {
      position: relative;
      z-index: 11;
    }

    button {
      &.addbutton {
        z-index: 11;
        bottom: 42%;
        left: 5%;
      }
    }

    .alloca {
      top: 50%;
      right: 32%;
      z-index: 11;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.16),
        0px 24px 32px -12px rgba(54, 57, 74, 0.24);
      backdrop-filter: blur(5px);
      border-radius: 5rem;
      -webkit-border-radius: 5rem;
      -moz-border-radius: 5rem;
      -ms-border-radius: 5rem;
      -o-border-radius: 5rem;
    }
  }
}

/* ======================== */
/* sumsProduct section css end here... */
/* ======================== */

/* ================================== */
/* BMD-Testimonial css start here... */
/* ================================ */
section {
  &.whatcl {
    background: radial-gradient(34.31% 29.62% at 70.03% 55.11%,
        #ffddba 0%,
        rgba(255, 255, 255, 0) 100%),
      radial-gradient(41.09% 31.18% at 68.33% 40.83%,
        #eef9fd 0%,
        #eef9fd 24.36%,
        rgba(255, 255, 255, 0) 100%),
      #fff;
    padding: 12.2rem 2.4rem;

    .container {
      overflow: hidden;

      h2 {
        max-width: 50%;
        line-height: 7.2rem;
        margin-bottom: 5.6rem;
      }
    }

    .testimonials {
      .contents {
        display: grid;
        grid-template-columns: 42.2rem 1fr;
        align-items: flex-start;
        gap: 8.8rem;
        overflow: hidden;

        .items {
          p {
            color: $color_11;
            font-family: $suisse_regular;
            font-size: $text_5;
            font-style: $font_style_1;
            font-weight: $font_weight_normal;
            line-height: 160%;
            letter-spacing: -0.02rem;

            strong {
              font-family: $suisse_medium;
              color: $color_11;
              font-size: $text_5;
              font-style: $font_style_1;
              font-weight: $font_weight_bold;
              line-height: 160%;
              letter-spacing: -0.02rem;
            }
          }

          .img-box {
            margin-bottom: 3.2rem;
          }
        }

        .client {
          display: flex;
          gap: 1.6rem;
          align-items: flex-start;

          .client-item {
            border-radius: 1.6rem;
            -webkit-border-radius: 1.6rem;
            -moz-border-radius: 1.6rem;
            -ms-border-radius: 1.6rem;
            -o-border-radius: 1.6rem;
            max-width: 18.6rem;
            min-width: 18.6rem;
            transition: all 0.3s linear;
            -webkit-transition: all 0.3s linear;
            -moz-transition: all 0.3s linear;
            -ms-transition: all 0.3s linear;
            -o-transition: all 0.3s linear;

            img {
              border-radius: inherit;
              -webkit-border-radius: inherit;
              -moz-border-radius: inherit;
              -ms-border-radius: inherit;
              -o-border-radius: inherit;
              object-fit: cover;
              height: 22.5rem;
            }

            &:first-child {
              max-width: 28.6rem;
              min-width: 28.6rem;

              img {
                height: 42.9rem;
              }
            }
          }
        }
      }

      .slick-arrow {
        width: 3.8rem;
        height: 3.8rem;
        background: $bg_linear2;
        box-shadow: $shadow-2;
        border-radius: 9.6rem;
        -webkit-border-radius: 9.6rem;
        -moz-border-radius: 9.6rem;
        -ms-border-radius: 9.6rem;
        -o-border-radius: 9.6rem;
        display: flex !important;
        align-items: center;
        justify-content: center;
        z-index: 999;

        &.slick-next {
          transform: translateY(-50%);
          top: 72%;
          right: 0%;

          &::before {
            background: url("../../images/slick-arrow-left.svg");
            content: "";
            width: 2rem;
            height: 2rem;
            display: flex;
            background-position: center;
            background-size: auto;
            background-repeat: no-repeat;
            transform: rotate(180deg);
            -webkit-transform: rotate(180deg);
            -moz-transform: rotate(180deg);
            -ms-transform: rotate(180deg);
            -o-transform: rotate(180deg);
          }
        }

        &.slick-prev {
          left: 93%;
          transform: translateY(-50%);
          top: 72%;

          &:before {
            background: url("../../images/slick-arrow-left.svg");
            content: "";
            width: 2rem;
            height: 2rem;
            display: flex;
            background-position: center;
            background-size: auto;
            background-repeat: no-repeat;
            transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -ms-transform: rotate(0deg);
            -o-transform: rotate(0deg);
          }
        }

        &:focus {
          box-shadow: $shadow-2 !important;
        }
      }
    }
  }
}

/* ================================ */
/* BMD-Testimonial css end here... */
/* =============------=========== */

/* ======================== */
/* sumsProduct section css start here... */
/* ======================== */
section {
  &.sumsProduct {
    .max-width-container {
      background: #ebeff1;
    }

    p {
      color: #4b6372;
      line-height: 3.6rem;
      font-size: 2rem;
      max-width: 76%;
      margin-bottom: 2.4rem;
      letter-spacing: -0.2px;
    }

    .contents {
      .itemsb {
        &:hover {
          .size-selector {
            z-index: 11;
          }

          .sizes {
            z-index: 11;
          }
        }
      }

      h2 {
        margin-bottom: 2.4rem;
        line-height: 7.2rem;
      }

      .minhead {
        margin-bottom: 1.6rem;
      }
    }
  }
}

/* ======================== */
/* sumsProduct section css end here... */
/* ======================== */

/* ======================== */
/* access section css start here... */
/* ======================== */

section {
  &.access {
    padding-bottom: 12.2rem;

    p {
      font-size: 1.6rem;
    }

    .contents {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 3.2rem;

      .img-box {
        margin-bottom: 1.2rem;
      }
    }
  }
}

/* ======================== */
/* access section css end here... */
/* ======================== */

/* ======================== */
/* what_clients section css start here... */
/* ======================== */
section {
  &.what_clients {
    padding: 12.2rem 2.4rem 11.2rem;

    .contents {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      .card {
        h6 {
          margin: 2rem 0 0.4rem;
          line-height: 1.6rem;
        }

        .wlcliconr {
          height: 4.8rem;
          background: #fff;
          position: absolute;
          width: 4.8rem;
          transition: .3s all;
          bottom: -4.8rem;
          right: 1.2rem;
          box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
        }

        .img-box {
          position: relative;
          overflow: hidden;
        }

        &:hover {
          .wlcliconr {
            bottom: 1.2rem;
          }
        }
      }
    }

    .card {
      border: none;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      -o-border-radius: 10px;

      &:hover {
        background: #f7f7f7;
      }
    }

    .top-heading {
      display: flex;
      align-items: flex-end;
      padding-bottom: 5.6rem;
      justify-content: space-between;
    }

    h2 {
      max-width: 60%;

      .btn {
        padding: 0.8rem 1.6rem;
      }
    }

    h6 {
      font-weight: $font_weight_bold;
      line-height: 1.6rem;
      letter-spacing: 0.96px;
    }

    h5 {
      margin-bottom: 0.4rem;
      line-height: 3.2rem;
    }

    p {
      font-size: 1.4rem;
      display: -webkit-box;
      max-width: 100%;
      height: 4.2rem;
      margin: 0 auto;
      line-height: 2rem;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      letter-spacing: -0.14px;
    }
  }
}

/* ======================== */
/* what_clients section css end here... */
/* ======================== */
section {
  &.remove-user {
    &::after {
      content: '';
      position: absolute;
      opacity: 0.3;
      z-index: -1;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: radial-gradient(86.35% 86.35% at 50% 0%, rgba(225, 42, 77, 0.30) 0%, rgba(255, 255, 255, 0.00) 100%);
    }

    .max-width-container {
      padding: 2.4rem 0;

      &::after {
        content: "";
        position: absolute;
        width: 40rem;
        left: 50%;
        height: 15rem;
        transform: translateX(-50%);
        background: url('../../images/Background-pattern.png');
        top: 2rem;
        opacity: 0.6;
        z-index: -1;
        background-repeat: no-repeat;
        background-position: center;
      }

      .delbutton {
        .toprspd {
          display: inline-block;
          height: 6rem;
          width: 6rem;
          border-radius: 99999px;
          border: 1px solid #ECEFF3;
          background: linear-gradient(180deg, #F8FAFB 0%, rgba(248, 250, 251, 0.00) 120%);
          backdrop-filter: blur(2.5px);
          display: flex;
          align-items: center;
          justify-content: center;
          margin: auto;
          margin-bottom: 1.6rem;

          span {
            &.btn {
              height: 3.6rem;
              width: 3.6rem;

              svg {
                height: 2rem;
                width: 2rem;
              }
            }
          }
        }

        .removeut {
          color: #272835;
          margin-bottom: .4rem;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 133.333%;
          letter-spacing: -0.36px;
        }

        p {
          color: #666D80;
          text-align: center;
          margin-bottom: 2.4rem;
          font-size: 1.4rem;
          font-weight: 400;
          line-height: 157.143%;
          letter-spacing: -0.14px;

          .namec {
            color: #272835;
            font-weight: 500;
          }
        }
      }

      .row {
        button {
          &.btn {
            &.btn-outline-secondary {
              border: none;
              background: (linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(193, 199, 208, 0.02) 100%), #FFF);
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
              color: #36394A;
              border-radius: 6px;
              -webkit-border-radius: 6px;
              -moz-border-radius: 6px;
              -ms-border-radius: 6px;
              -o-border-radius: 6px;

              &:hover {
                background: #c6c7c8;
              }
            }
          }
        }
      }
    }
  }
}

/* =================================== */
/* Shiping-delivery css start here... */
/* ================================= */
section {
  &.about-ship-delivery {

    .conditions {
      margin-bottom: 5rem;

      h2 {
        color: $color_6;
        font-size: 4rem;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 110%;
        letter-spacing: -0.08rem;
        margin-bottom: 2rem;
      }

      .desc {
        color: $color_2;
        font-size: $text_4;
        font-style: $font_style_1;
        line-height: 155.556%;
        letter-spacing: -0.036rem;
        margin-bottom: 1.8rem;
      }

      .list-condition {

        li {
          padding-left: 3rem;
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0%;
            top: 5%;
            background-image: url("../../images/category-check.svg");
            width: 2rem;
            height: 2rem;
            background-origin: content-box;
            background-position: center;
            background-repeat: no-repeat;
            background-size: contain;
          }
        }

        p {
          color: $color_2;
          font-family: $font-inter;
          font-size: $text_3;
          font-style: $font_style_1;
          font-weight: $font_weight_normal;
          line-height: 150%;
          letter-spacing: -0.016rem;
          margin-bottom: 1.8rem;

          span {
            font-size: $text_5;
          }
        }

      }

      .btn-link {
        padding: 0rem;
        display: inline-flex;
        text-decoration: none;
        line-height: 155.556%;
        letter-spacing: -0.036rem;
      }
    }
  }
}

/* ================================= */
/* Shiping-delivery css end here... */
/* =============================== */


@media only screen and (max-width: 1199px) {
  section {
    &.what_clients {
      padding: 4rem 1.6rem;

      h2 {
        font-size: 4.6rem;
        line-height: 5rem;
      }

      .contents {
        .card {
          h6 {
            margin: 1.5rem 0 0.4rem;
          }
        }
      }

      h5 {
        line-height: 2.2rem;
      }

      .top-heading {
        padding-bottom: 4rem;
      }
    }

    &.weKnow {
      padding: 0 2rem;

      h2 {
        font-size: 4.6rem;
      }

      .shild {
        grid-gap: 1rem;
      }

      .max-width-container {
        padding: 5rem 2.4rem;
      }
    }

    &.access {
      padding-bottom: 4rem;

      .max-width-container {
        padding: 0 2rem;
      }
    }

    &.whatcl {
      padding: 4.8rem 1.6rem;

      .container {
        h2 {
          line-height: 5rem;
          font-size: 4.6rem;
        }
      }

      .contents {

        .items {
          p {
            font-size: 1.6rem;
            line-height: 2.8rem;
          }

          .img-box {
            margin-bottom: 3.2rem;
          }
        }
      }
    }

    &.transforming {
      h1 {
        font-size: 5.6rem;
        line-height: 6rem;
      }

      .contents {
        .items {
          .hpba {
            .btn {
              font-size: 12px;
              padding: 0.6rem 0.8rem;
            }
          }
        }
      }

      .max-width-container {
        .transback {
          min-height: auto;
        }
      }
    }

    &.hivis {
      margin: 0;
    }

    &.about {
      padding: 4rem 1.6rem;

      h3 {
        font-size: 3.6rem;
        line-height: 5rem;
      }

      .max-width-container {
        padding: 0;
      }

      .contentsb {
        margin-top: 4rem;
      }

      .container {
        .hrline {
          margin: 4rem 0;
          overflow: hidden;
        }

        .items {
          ul {
            &.brns {
              grid-gap: 2rem;
            }
          }
        }
      }
    }

    &.sumsProduct {
      .contents {
        h2 {
          margin-bottom: 1rem;
          line-height: 5rem;
          font-size: 4.6rem;
        }

        .items {
          margin-left: -6rem;
          z-index: 1;
          padding: 20px 0;
        }
      }

      p {
        margin-bottom: 1rem;
        max-width: 100%;
        font-size: 1.6rem;
        line-height: 3rem;
      }

      .orders {
        min-width: 30rem;
      }

      .sizes {
        label {
          height: 3rem;
          width: 3rem;
        }
      }

      .size-selector {
        padding: 1.2rem;

        label {
          height: 2.6rem;
          width: 2.6rem;
        }

        input {
          &:checked {
            &+label {
              &::before {
                width: 3.5rem;
                height: 3.5rem;
              }
            }
          }
        }
      }

      .alloca {
        .p-3 {
          &.d-flex {
            &.align-items-center {
              &.gap-3 {
                padding: 0.6rem !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 993px) and (max-width: 1260px){

  section{

    &.whatcl {
      .testimonials {

        .slick-arrow {

          &.slick-next {
            right: 0%;
            transform: translateY(-50%);
            top: 72%;
          }

          &.slick-prev {
            left: 91%;
            transform: translateY(-50%);
            top: 72%;
          }

          &:focus {
            box-shadow: $shadow-2 !important;
          }
        }
      }
    }
  }
  
}

@media only screen and (max-width: 992px) {
  section {
    &.transforming {
      a {
        &.cart-box {
          height: 3rem;
          width: 3rem;
          padding: 0.9rem;
        }
      }

      .max-width-container {
        .transback {
          .ourclientsec {
            margin-top: 3.4rem;
          }
        }
      }

      .contents {
        .items {
          .hpba {
            button {
              font-size: 8px;
              padding: 0.6rem 0.8rem;
            }
          }
        }
      }

      .allocation-box {
        right: 12%;

        span {
          .fs-6 {
            font-size: 0.6rem !important;
          }
        }

        .alocbox {
          padding: 0.4rem 0.6rem;

          a {
            img {
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
      }
    }

    &.sumsProduct {
      p {
        font-size: 1.6rem;
      }

      .contents {
        grid-template-columns: 1fr;
        grid-gap: 4rem;

        .items {
          order: 1;
          margin-left: 0;
          margin-top: 2rem;
        }

        .itemsb {
          order: 2;

          .img-box {
            img {
              margin-left: auto;
            }
          }


        }
      }
    }

    &.access {
      .contents {
        grid-gap: 1rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &.what_clients {
      .contents {
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;

        .img-box {
          img {
            width: 100%;
          }
        }
      }

      .max-width-container {
        padding: 0;
      }
    }

    &.hivis {
      .contents {
        grid-template-columns: 1fr;
      }
    }

    &.weKnow {
      .contents {
        grid-template-columns: 1fr;
      }
    }

    &.about {
      .container {
        .items {
          ul {
            &.brns {
              grid-gap: 5rem;
            }
          }
        }
      }
    }

    &.whatcl {
      .testimonials {
        .contents {
          grid-template-columns: 1fr;
          gap: 5.8rem;

          .items {
            p {
              font-size: 1.4rem;
              line-height: 2.5rem;
            }
          }

          .client {
            flex-wrap: wrap;

            .client-item {
              max-width: 47%;
              min-width: 47%;

              img {
                height: 15.5rem;
              }

              &:first-child {
                max-width: 100%;

                img {
                  height: 25.9rem;
                }
              }
            }
          }
        }

        .slick-arrow {

          &.slick-next {
            transform: translateY(0%);
            top: 0%;
            right: 1%;
          }

          &.slick-prev {
            left: 74%;
            transform: translateY(0%);
            top: 0%;

          }

          &:focus {
            box-shadow: $shadow-2 !important;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  section {
    &.transforming {
      padding-bottom: 2.8rem;

      h1 {
        margin-right: 0;
        margin-top: 4rem;
        margin-bottom: 0;
        font-size: 4rem;
        line-height: 4.5rem;
        max-width: 29rem;
      }

      .max-width-container {
        padding: 1rem;

        .transback {
          min-height: auto;

          .ourclientsec {
            margin-top: 6.3rem;
          }

          .ourclientsec {
            ul {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 2rem;
              flex-wrap: wrap;
            }
          }
        }
      }

      .ourclientsec {
        ul {
          grid-gap: 2.4rem;

          li {
            img {
              min-width: 10.6rem;
            }
          }
        }
      }

      .contents {
        grid-template-columns: 1fr;

        p {
          margin: 1.6rem 0 2.4rem;
        }

        .items {
          .hpba {
            bottom: 5%;
          }

          .button-grps {
            display: block;

            .btn {
              display: block;
              width: 100%;
              margin-bottom: 2rem;
            }
          }
        }
      }

      a {
        &.cart-box {
          display: none !important;
        }
      }
    }

    &.hivis {
      margin-top: 0;
      padding-bottom: 0rem;

      .contents {
        grid-gap: 1.6rem;

        .newitems {
          grid-template-columns: 1fr;

          .boxes {
            overflow: hidden;
            border-radius: 1.6rem !important;
            -webkit-border-radius: 1.6rem !important;
            -moz-border-radius: 1.6rem !important;
            -ms-border-radius: 1.6rem !important;
            -o-border-radius: 1.6rem !important;

            &:last-child {
              h3 {
                font-size: 2rem;
                line-height: normal;
              }

              h4 {
                font-size: 1.4rem;
                line-height: normal;
              }
            }
          }
        }
      }

      .position-absolute {
        left: 1.2rem;
        bottom: 1.2rem;

        h4 {
          font-size: 1.4rem;
        }

        h3 {
          font-size: 2.2rem;
        }
      }

      .bag {
        top: 1.2rem;
        left: 1.2rem;
      }

      .max-width-container {
        padding: 0;
      }
    }

    &.weKnow {
      padding: 4.8rem 1.6rem;

      h2 {
        margin-bottom: 3.2rem;
        font-size: 4rem;
        line-height: 4.6rem;

        span {
          display: block;
        }
      }

      .contents {
        grid-gap: 4.8rem;
      }

      .shild {
        grid-template-columns: 1fr;
        grid-gap: 1.6rem;
      }
    }

    &.what_clients {
      .contents {
        grid-template-columns: 1fr;

        .card {
          .card-body {
            padding: 0;
          }
        }

        .card-body {
          .img-box {
            img {
              width: 100%;
            }
          }
        }
      }

      .top-heading {
        padding-bottom: 2rem;
        flex-direction: column;

        button {
          &.btn {
            &.btn-light {
              width: 100% !important;
              margin-top: 1.4rem;
            }
          }
        }
      }

      h2 {
        max-width: 100%;
        font-size: 4rem;
        line-height: 4.6rem;
      }
    }

    &.sumsProduct {
      .sizes {
        label {
          font-size: 0.8rem;
          height: 2.7rem;
          width: 2.7rem;
        }

        .d-flex {
          gap: 0.4rem !important;
          padding: 0.5rem 0.6rem !important;
        }
      }

      .size-selector {
        padding: 0.8rem;

        label {
          width: 1.8rem;
          height: 1.8rem;
        }

        input {
          &:checked {
            &+label {
              &::before {
                height: 2.7rem;
                width: 2.7rem;
              }

              &::after {
                height: 1.2rem;
                width: 1.2rem;
                background-size: contain;
                top: -4px;
                right: -8px;
              }
            }
          }
        }
      }

      .frm {
        grid-gap: 1.5rem;
      }

      .alloca {
        a {
          &.border {
            width: 1.7rem !important;
            padding: 0.3rem !important;
            height: 1.7rem;
          }
        }

        .p-3 {
          &.d-flex {
            &.align-items-center {
              &.gap-3 {
                gap: 0.5rem !important;

                span {
                  .fs-6 {
                    font-size: 0.6rem !important;
                  }
                }
              }
            }
          }
        }
      }

      .orders {
        min-width: 100%;
        left: 50%;
        transform: translateX(-50%);

        h4 {
          font-size: 1.2rem;
          padding: 1rem 1.4rem;
        }

        .itembox {
          padding: 0.7rem 0.7rem 0.2rem;

          ul {
            li {
              font-size: 0.6rem;
            }
          }
        }

        .accordion-flush {
          .accordion-item {
            .accordion-button {
              font-size: 0.7rem;
              padding: 1rem;

              span {
                width: 1.4rem;
                height: 1.4rem;
              }

              &::after {
                height: 0.5rem;
                width: 0.5rem;
                background-size: contain;
              }
            }
          }
        }
      }

      .max-width-container {
        padding: 0 2.4rem;
      }

      p {
        font-size: 2rem;
        margin-bottom: 2.4rem;
      }

      .contents {
        h2 {
          margin-bottom: 2.4rem;
          font-size: 4rem;
          line-height: 4.6rem;
        }

        .items {
          margin-top: 3.2rem;

          .btngrps {
            display: block;

            .btn {
              width: 100%;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    &.access {
      .max-width-container {
        padding: 2.4rem 0;
      }

      p {
        font-size: 1.6rem;
      }

      .contents {
        grid-template-columns: 1fr;
        grid-gap: 3.2rem;
      }
    }

    &.whatcl {
      background: radial-gradient(47.2% 27.99% at 92.8% 80.63%,
          #ffddba 0%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(47.85% 67.11% at 90.93% 70.37%,
          #eef9fd 0%,
          #eef9fd 24.36%,
          rgba(255, 255, 255, 0) 100%),
        #fff;

      .container {
        h2 {
          max-width: 100%;
          font-size: 4rem;
          line-height: 4.6rem;
          margin-bottom: 5.6rem !important;
        }
      }

      .contents {
        .items {
          margin-bottom: 0rem;

          p {
            font-size: 2rem;
            line-height: 3.2rem;
          }
        }
      }

      .sliderbutton {
        top: -5.8rem;
        left: 0;
        width: fit-content;
        height: fit-content;
      }

      .max-width-container {
        padding: 0;
      }
    }

    &.about {
      padding: 4.8rem 1.6rem;

      h3 {
        font-size: 3.2rem;
        line-height: 4.6rem;
      }

      .container {
        h5 {
          max-width: 100%;
        }

        .items {
          a.btn {
            width: 100%;
          }

          ul {
            &.brns {
              grid-gap: 2.4rem;
            }
          }
        }
      }
    }
  }
}