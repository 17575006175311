section {
  &.inhousecus {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: linear-gradient(0deg, #FFF 0%, rgba(255, 255, 255, 0.00) 80.25%), radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.60) 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(65.8% 62.46% at 97.64% 48.25%, #FFDDBA 0%, rgba(255, 255, 255, 0.00) 100%), radial-gradient(120.82% 115.83% at 72.19% 74.45%, #EEF9FD 0%, #EEF9FD 24.36%, rgba(255, 255, 255, 0.00) 100%), #FFF;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      padding-bottom: 27.2rem;

      h1 {
        color: $color_6;
        line-height: 110%;
        letter-spacing: -1.6px;
        margin-bottom: .8rem;
      }

      p {
        color: $color_2;
        font-size: 1.8rem;
        font-weight: 400;
        line-height: 155.556%;
        letter-spacing: -0.36px;
        max-width: 67.8rem;
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('../../images/Background-pattern.png');
        z-index: 0;
        background-position: center right;
      }
    }
  }

  &.inhcusinner {
    margin-top: -18.2rem;
    position: relative;
    padding-top: 0;

    .max-width-container {
      .container {
        display: grid;
        grid-gap: 2.4rem;
        grid-template-columns: 1fr 1fr;

        .inner {
          padding: 5.6rem 4.8rem;
          border-radius: 24px;
          position: relative;
          overflow: hidden;

          h3 {
            color: $color_6;
            font-family: $suisse_medium;
            font-size: 3.2rem;
            line-height: 110%;
            letter-spacing: -0.64px;
            margin-top: 3.2rem;
          }
        }

        .sec-a {
          background: linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          .top-box {
            display: grid;
            grid-template-columns: 15.3rem 1fr;
            grid-gap: 6.8rem;
            min-height: 32.4rem;
            padding: 1.2rem 1.2rem 1.7rem 1.2rem;

            ul {
              position: relative;

              li {
                position: relative;
                margin-bottom: .771rem;

                .img-box {
                  display: flex;
                  align-items: center;
                  position: relative;
                  justify-content: center;
                  border-radius: 16px;
                  margin-bottom: 0.771rem;
                }

                &:nth-child(1) {
                  .img-box {
                    min-height: 8rem;
                    background: $color_5;
                    box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
                  }

                  img {
                    width: 10.4rem;
                  }
                }

                &:nth-child(2) {
                  padding: 0 .9rem;

                  .img-box {
                    min-height: 7rem;
                    display: flex;
                    align-items: center;
                    position: relative;
                    justify-content: center;
                    border-radius: 16px;
                    margin-bottom: 0.771rem;
                    opacity: 0.7;
                    background: $color_5;
                    box-shadow: 0px 0px 0px 0.882px rgba(18, 55, 105, 0.12), 0px 10.588px 12.353px -6.176px rgba(164, 172, 185, 0.16);
                  }

                  img {
                    width: 5.73rem;
                  }
                }

                &:nth-child(3) {
                  margin-bottom: 0;
                  padding: 0 1.9rem;

                  .img-box {
                    min-height: 6rem;
                    margin-bottom: 0;
                    opacity: 0.7;
                    background: $color_5;
                    box-shadow: 0px 0px 0px 0.663px rgba(18, 55, 105, 0.12), 0px 7.958px 9.285px -4.642px rgba(164, 172, 185, 0.16);
                  }

                  img {
                    width: 6.8rem;
                  }
                }

                &:nth-child(2)::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: -2.1rem;
                  width: 1.4rem;
                  height: 1.4rem;
                  background: url('../../images/bgcustomnew2.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                  transform: translateY(-50%);
                }

                &:nth-child(3):after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  right: -2.1rem;
                  width: 1.4rem;
                  height: 1.4rem;
                  background: url('../../images/bgcustomnew2.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: contain;
                  transform: translateY(-50%);
                }
              }

              &::after {
                content: "";
                position: absolute;
                width: 27.9rem;
                height: 8.3rem;
                top: 2.7rem;
                left: 16rem;
                background: url('../../images/bgcustomnew1.svg');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: left;
              }
            }

            .rgbox {
              display: flex;
              align-items: flex-end;

              .rigis {
                position: relative;
                z-index: 11;
                overflow: hidden;
                border-radius: 1.6rem;

                img {
                  border-radius: 1.6rem;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  transform: scale(1.2);
                }

                &::after {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  height: 115.48%;
                  width: 65.59%;
                  z-index: -1;
                  border-radius: 16px;
                  border: 1px solid #ecece7;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }

        .sec-b {
          background: $color_5;
          box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);

          .brdbox {
            padding: 1.4rem 3.2rem;
            position: relative;
            min-height: 32.4rem;

            .brdicon {
              height: 3.6rem;
              width: 3.6rem;
              z-index: 12;
              border-radius: 50%;
              background: $color_5;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 7.7rem;
              top: 0;
              transform: translateY(-50%);

              img {
                height: 2rem;
                width: 2rem;
                object-fit: contain;
              }
            }

            .inboximgs {
              position: relative;
              min-height: 29.6rem;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 1.8rem 1.3rem;
              position: relative;
              z-index: 11;
              max-width: 34rem;
              width: 100%;
              margin: auto;
              text-align: center;
              border-radius: 16px;
              background: $color_5;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);

              .main-img {
                text-align: center;

                &>img {
                  max-width: 20rem;
                  max-height: 20rem;
                  height: 100%;
                  width: 100%;
                  object-fit: contain;
                }
              }

              .bgdot {
                border-radius: 8px;
                background-color: #F6F8FA;
                position: absolute;
                right: 8px;
                width: 100%;
                height: 94.6%;
                z-index: -1;
                max-width: 16.1rem;
                top: 50%;
                background-image: url('../../images/Background-pattern.png');
                transform: translateY(-50%);
                background-position: center top;
                background-repeat: no-repeat;
                background-size: cover;
              }
            }
          }

          .lftrht-box {
            border-radius: 16px;
            width: 100%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
            height: 162px;
            max-width: 42.9rem;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);

            .lft-icon {
              height: 3.6rem;
              width: 3.6rem;
              z-index: 12;
              border-radius: 50%;
              background: $color_5;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              left: 0;
              top: 3.2rem;
              transform: translateX(-50%);

              img {
                height: 2rem;
                width: 2rem;
                object-fit: contain;
              }
            }

            .rht-icon {
              height: 3.6rem;
              width: 3.6rem;
              z-index: 12;
              border-radius: 50%;
              background: $color_5;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
              display: flex;
              align-items: center;
              justify-content: center;
              position: absolute;
              top: 10rem;
              transform: translateX(50%);
              right: 0;

              img {
                height: 2rem;
                width: 2rem;
                object-fit: contain;
              }
            }
          }
        }

        .sec-c {
          background: linear-gradient(180deg, #E9F3FD 0%, #EBFCF9 100%), linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          .uvbox {
            position: relative;
            min-height: 32.4rem;

            .top-box {
              display: flex;
              align-items: center;
              grid-gap: 1.6rem;
              justify-content: center;
              position: relative;
              z-index: 11;

              .lftimg {
                border-radius: 16px;
                display: flex;
                height: 17rem;
                width: 17rem;
                background: $color_5;
                box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
                align-items: center;
                justify-content: center;

                img {
                  width: 13.8rem;
                  height: 13.8rem;
                  object-fit: cover;
                  border-radius: 0.8rem;
                }
              }

              .rgtimg {
                border-radius: 16px;
                width: 24rem;
                height: 17rem;
                display: flex;
                background: $color_5;
                box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
                align-items: center;
                justify-content: center;

                img {
                  border-radius: 8px;
                  width: 20.8rem;
                  height: 13.8rem;
                  object-fit: cover;
                }
              }
            }

            .btmbxs {
              position: absolute;
              bottom: 0;
              width: 100%;
            }
          }
        }

        .sec-d {
          background: linear-gradient(180deg, #EEF5FF 0%, #D9E8FF 100%), linear-gradient(180deg, #EAF0FF 0%, #EEEAFF 100%), linear-gradient(180deg, #FFF9F3 0%, #FFFDF3 100%);

          .img-box {
            min-height: 32.4rem;
            position: relative;
            left: -4.8rem;

            .uploadbtn {
              position: absolute;
              top: 3rem;
              left: 20%;

              input#artWorkimg {
                display: none;
              }

              label {
                &.form-label {
                  color: $color_2;
                  cursor: pointer;
                  text-align: center;
                  font-size: 1.4rem;
                  padding: 7px 12px;
                  font-weight: $font_weight_bold;
                  line-height: 142.857%;
                  border-radius: 6px;
                  background: $color_5;
                  box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.12), 0px 12px 14px -7px rgba(164, 172, 185, 0.16);
                }
              }
            }

            img {
              transform: scale(1.4) translate(-11%, 20%);
              -webkit-transform: scale(1.4) translate(-11%, 20%);
              -moz-transform: scale(1.4) translate(-11%, 20%);
              -ms-transform: scale(1.4) translate(-11%, 20%);
              -o-transform: scale(1.4) translate(-11%, 20%);
            }
          }
        }
      }
    }
  }

  &.inhcbottom {
    user-select: none;

    .max-width-container {
      .container {
        h3 {
          color: $color_11;
          text-align: center;
          font-size: 3.2rem;
          line-height: 150%;
          letter-spacing: -0.64px;
          max-width: 100rem;
          margin: auto;
          margin-bottom: 1.6rem;
        }

        p {
          color: $color_2;
          text-align: center;
          font-size: 1.6rem;
          line-height: 150%;
          letter-spacing: -0.16px;
          max-width: 79.6rem;
          margin: auto;
        }
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  section {
    &.inhcusinner {
      .max-width-container {
        .container {
          .sec-a {
            .top-box {
              grid-gap: 3rem;
              min-height: 24rem;
              padding: 0;
              grid-template-columns: 12.3rem 1fr;

              ul {
                &::after {
                  width: 20rem;
                  top: 2rem;
                  left: 13rem;
                }

                li {
                  &:nth-child(1) {
                    img {
                      width: 10rem;
                      margin: 1rem;
                    }
                  }

                  &:nth-child(2) {
                    img {
                      width: 4rem;
                    }
                  }

                  &:nth-child(3) {
                    img {
                      width: 4.8rem;
                    }
                  }

                  &:nth-child(2)::after {
                    width: 1rem;
                    height: 1rem;
                    right: -1.67rem;
                    width: 1.09rem;
                    height: 1.09rem;
                  }

                  &:nth-child(3):after {
                    right: -1.67rem;
                    width: 1.09rem;
                    height: 1.09rem;
                  }
                }
              }
            }
          }

          .sec-b {
            .brdbox {
              min-height: 24rem;
              padding: 0rem 3.2rem;

              .inboximgs {
                min-height: auto;

                .bgdot {
                  max-width: 50%;
                }
              }
            }
          }

          .sec-c {
            .uvbox {
              min-height: 24rem;

              .top-box {
                .lftimg {
                  height: auto;
                  width: auto;
                  padding: 1.6rem;

                  img {
                    height: 100%;
                    width: 100%;
                    max-width: 10.8rem;
                    max-height: 9.8rem;
                  }
                }

                .rgtimg {
                  height: auto;
                  width: auto;
                  padding: 1.6rem;

                  img {
                    height: 100%;
                    width: 100%;
                    max-width: 14.8rem;
                    max-height: 12.8rem;
                  }
                }
              }
            }
          }

          .sec-d {
            .img-box {
              min-height: 24rem;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 992px) {
  section {
    &.inhousecus {
      .max-width-container {
        padding-top: 4rem;
        padding-bottom: 21.2rem;

        h1 {
          font-size: 5.6rem;
        }
      }
    }

    &.inhcusinner {
      .max-width-container {
        padding-right: 0;
        padding-left: 0;

        .container {
          grid-template-columns: 1fr;

          .sec-a {
            .top-box {
              grid-gap: 4rem;
              min-height: 43rem;
              padding: 0;
              grid-template-columns: 18.3rem 1fr;
              max-width: 37rem;
              margin: auto;
              grid-gap: 3rem;
              min-height: 25rem;
              grid-template-columns: 13.3rem 1fr;

              ul {
                &::after {
                  width: 17rem;
                  top: 2rem;
                  left: 15rem;
                }
              }
            }
          }

          .sec-c {
            .uvbox {
              min-height: 25rem;
              max-width: 40rem;
              margin: auto;

              .btmbxs {
                text-align: center;
              }
            }
          }

          .sec-d {
            .img-box {
              &>img {
                max-height: 14rem;
              }

              .uploadbtn {
                left: 10%;
              }
            }

            h3 {
              margin-top: 7.2rem;
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  section {
    &.inhcusinner {
      .max-width-container {
        .container {
          .inner {
            padding: 3.2rem 2.4rem;
          }

          .sec-a {
            .top-box {
              grid-gap: 2rem;
              min-height: 19rem;
              grid-template-columns: 11.3rem 1fr;

              ul {
                li {
                  &:nth-child(1) {
                    .img-box {
                      min-height: 6rem;
                    }

                    img {
                      width: 7rem;
                      margin: 1rem;
                    }
                  }

                  &:nth-child(2) {
                    .img-box {
                      min-height: 5rem;
                    }
                  }

                  &:nth-child(3) {
                    .img-box {
                      min-height: 5rem;
                    }
                  }

                  &:nth-child(2)::after {
                    width: 0.8rem;
                    height: 0.8rem;
                    right: -1.47rem;
                  }

                  &:nth-child(3):after {
                    width: 0.8rem;
                    height: 0.8rem;
                    right: -1.47rem;
                  }
                }

                &::after {
                  width: 15rem;
                  top: 1rem;
                  height: 7rem;
                  left: 12rem;
                }
              }
            }
          }

          .sec-c {
            .uvbox {
              min-height: 21rem;
            }
          }

          .sec-d {
            .img-box {
              min-height: auto;
              left: -2.4rem;
            }
          }
        }
      }
    }

    &.inhousecus {
      .max-width-container {
        h1 {
          font-size: 4.5rem;
        }
      }
    }
  }
}