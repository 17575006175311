/* Example CSS customization */
@import "~@ng-select/ng-select/themes/default.theme.css";

$color_1: #000000;
$color_2: #1ea97c;
$color_3: #cc8925;
$color_4: #ff5757;

.simple-notification-wrapper {
  z-index: 1060 !important;

  .simple-notification {
    width: 100%;
    padding: 1rem 1rem 1rem 4.5rem;
    box-sizing: border-box;
    position: relative;
    float: left;
    margin-bottom: 1rem;
    color: $color_1;
    cursor: pointer;
    transition: all .5s;
    min-height: 6.9rem;
    margin: 0 0 1rem 0;
    box-shadow: 0 0.2rem 1.2rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.6rem;
    backdrop-filter: blur(1rem);

    .icon {
      width: 3rem;
      height: 3rem;
      top: 14%;
      left: 2%;
      padding: 0rem;

      svg {
        color: $color_1;
      }
    }

    .sn-title {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 140% !important;
      text-transform: capitalize;
      padding: 0rem;
    }

    .sn-content {
      font-size: 1.2rem !important;
      line-height: 140% !important;
      text-transform: capitalize;
      font-style: normal !important;
      font-weight: 500 !important;
    }

    .sn-progress-loader {
      height: 0.2rem;
    }
  }

  .simple-notification.success {
    background: rgba(228, 248, 240, 0.7);
    border: solid $color_2;
    border-width: 0 0 0 0.6rem;
    color: $color_2;

    .icon {
      svg {
        fill: $color_2 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_2 !important;
    }

    .sn-content {
      color: $color_2 !important;
    }
  }

  .simple-notification.warn {
    background: rgba(255, 242, 226, 0.7);
    border: solid $color_3;
    border-width: 0 0 0 0.6rem;
    color: $color_3;

    .icon {
      svg {
        fill: $color_3 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_3 !important;
    }

    .sn-content {
      color: $color_3 !important;
    }
  }

  .simple-notification.error {
    background: rgba(255, 231, 230, 0.7) !important;
    border: solid $color_4;
    border-width: 0 0 0 0.6rem;
    color: $color_4 !important;

    .icon {
      svg {
        fill: $color_4 !important;
        width: 100% !important;
        height: 100% !important;
      }
    }

    .sn-title {
      color: $color_4 !important;
    }

    .sn-content {
      color: $color_4 !important;
    }
  }
}

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/scss/bootstrap';

ngx-intl-tel-input input {
  height: 44px;
  margin-bottom: 20px;
  padding: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: #c7cace;
  border-radius: 4px;
  font-size: 18px;
}

ngx-intl-tel-input.ng-invalid.ng-touched input {
  border: 1px solid #c0392b;
}

ngx-intl-tel-input input:hover {
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.24);
}

ngx-intl-tel-input input:focus {
  outline: none !important;
  border-color: #3498db;
  box-shadow: 0 0 0 0 #000;
}

ngx-intl-tel-input input::-webkit-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input:-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::-ms-input-placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input::placeholder {
  color: #bac2c7;
}

ngx-intl-tel-input input[disabled] {
  background-color: #e5eaf1;
}

.iti {
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08);
  border-radius: 0.6rem;
  -webkit-border-radius: 0.6rem;
  -moz-border-radius: 0.6rem;
  -ms-border-radius: 0.6rem;
  -o-border-radius: 0.6rem;
  display: flex !important;
  align-items: center;
  justify-content: start;
  gap: 0rem;

  .iti__flag-container {
    display: flex;
    padding: 0.8rem 1rem;
  }
}

.iti .dropdown-menu.country-dropdown {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-color: #c7cace;
  margin-top: -1px;
}

.iti .iti__country-list {
  box-shadow: none;
  font-size: 1.4rem;
  margin-left: 0;
  width: 24.4rem;
  max-height: 21rem;
  overflow: hidden auto;
  scrollbar-color: #eceff3 #ffffff;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 9rem;
    -webkit-border-radius: 9rem;
    -moz-border-radius: 9rem;
    -ms-border-radius: 9rem;
    -o-border-radius: 9rem;
  }

  &::-webkit-scrollbar {
    background-color: #ffffff;
    width: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eceff3;
    border-radius: 9rem;
    -webkit-border-radius: 9rem;
    -moz-border-radius: 9rem;
    -ms-border-radius: 9rem;
    -o-border-radius: 9rem;
  }
}

.iti__flag-container.open+input {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.iti .search-container {
  margin: 0rem 0.8rem;
}

.iti .search-container input {
  background: url("../src/assets/images/search.svg");
  background-repeat: no-repeat;
  background-position: center left 0.8rem;
  padding: 0.8rem 1rem 0.8rem 3.2rem !important;
  background-color: #ffffff;
  border-radius: 0.6rem;
  box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13), 0px 1px 2px 0px rgba(18, 55, 105, 0.08);
  color: #818898;
  font-family: "inter";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 142.857%;
  letter-spacing: -0.14px;
  margin-bottom: 0.8rem;
}

.iti .dropdown-menu.country-dropdown {
  background: rgba(255, 255, 255, 0.95);
  box-shadow: 0px 16px 32px -12px rgba(7, 32, 69, 0.25), 0px 1px 2px 0px rgba(29, 79, 129, 0.04), 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
  backdrop-filter: blur(0.5rem);
  padding: 0.8rem 0rem;
  border: 0rem;
  border-radius: 1rem;
  -webkit-border-radius: 1rem;
  -moz-border-radius: 1rem;
  -ms-border-radius: 1rem;
  -o-border-radius: 1rem;
  min-width: 14rem;
}

.iti .search-container input:focus {
  outline: none;
}

.iti .iti__country-list .iti__country {
  color: #666d80;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 133.333%;
  padding: 0.8rem 1.2rem;
  margin-bottom: 0rem;
  display: block;
  white-space: break-spaces;
  cursor: pointer;
}

.iti .iti__country-list .iti__country .iti__dial-code {
  color: #666d80;
}

.iti .iti__country-list .iti__country:hover,
.iti .iti__country-list .iti__country:active {
  background: #f68c1e;
  color: #ffffff;
}

.iti .iti__country-list .iti__country:hover .iti__dial-code,
.iti .iti__country-list .iti__country:active .iti__dial-code {
  color: #ffffff;
}

.rc-anchor-error-msgP {
  color: yellow;
}

.g-recaptcha {
  position: relative;
  width: 100%;
  background: #f9f9f9;
  overflow: hidden;
}
