@media only screen and (min-width: 992px) {
  .header {
    background-color: $bg-1;
    padding: 1.4rem 4rem;

    &.fixed-top {
      border-bottom: 1px solid $border-bottom-neutral-solid;
      z-index: 1055;
    }

    .loginbox {
      li {
        cursor: pointer;

        a {
          height: 3.6rem;
          width: 3.6rem;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #000;

          &:hover {
            svg {
              path {
                stroke: #000;
              }
            }
          }
        }

        .usermenu {
          right: -10px;
          top: 5.4rem;
          min-width: 28rem;
          z-index: 1055;

          ul {
            border-radius: 1.2rem;
            -webkit-border-radius: 1.2rem;
            -moz-border-radius: 1.2rem;
            -ms-border-radius: 1.2rem;
            -o-border-radius: 1.2rem;

            li {
              font-size: 1.4rem;

              .badge {
                color: #5c3d1f;
                background: #faedcc;
                font-size: 1.2rem;
              }
            }
          }

          .avat {
            width: 3.6rem;
            height: 3.6rem;
            font-size: 1.2rem;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
              0px 1px 2px 0px rgba(164, 172, 185, 0.24);
          }

          .avatname {
            font-size: 1.4rem;
          }

          .avatemail {
            font-size: 1.4rem;
          }
        }

        .existuser {
          right: -10px;
          top: 5.4rem;
          min-width: 40rem;

          form {
            padding: 2.4rem;

            h4 {
              color: #666d80;
              font-family: $font-inter;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 600;
              line-height: 200%;
              letter-spacing: 0.048rem;
              text-transform: capitalize;
              margin-bottom: 0.8rem;
            }

            .form-group {
              margin-bottom: 1.6rem;
            }

            .form-label {
              color: $color_2;
              font-family: $font-inter;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: $font_weight_bold;
              line-height: 142.857%;
              margin-bottom: 0.4rem;
            }

            .form-control {
              background: $color_5;
              box-shadow: 0rem 0rem 0rem 0.1rem rgba(9, 25, 72, 0.13),
                0rem 0.1rem 0.2rem 0rem rgba(18, 55, 105, 0.08);
              border-radius: 0.6rem;
              -webkit-border-radius: 0.6rem;
              -moz-border-radius: 0.6rem;
              -ms-border-radius: 0.6rem;
              -o-border-radius: 0.6rem;
              padding: 0.8rem 1rem;
              border: 0rem;
              color: #818898;
              font-family: $font-inter;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 400;
              line-height: 142.857%;
              letter-spacing: -0.014rem;

              &.form-code {
                padding-left: 6rem;
              }
            }

            .form-text {
              font-family: $font-inter;
              font-size: 1.2rem;
              font-style: normal;
              font-weight: 400;
              line-height: 133.333%;
              margin: 0.8rem 0rem 0rem;
            }

            .btn {
              &.btn-link {
                width: fit-content;
                height: auto;
                padding: 0.8rem 1rem;
                color: $color_2;
                text-align: center;
                font-family: $font-inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                text-decoration: none;
              }

              &.btn-primary {
                width: 100%;
              }
            }
          }

          .login-new-acc {
            padding: 1.6rem 2.4rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            row-gap: 0.8rem;

            p {
              color: #666d80;
              text-align: center;
              font-family: $font-inter;
              font-size: 1.4rem;
              font-style: normal;
              font-weight: 400;
              line-height: 157.143%;
              letter-spacing: -0.014rem;
              margin-bottom: 0rem;
            }

            .btn {
              &.btn-light {
                width: 100%;
                height: auto;
                padding: 0.8rem 1rem;
                color: $color_2;
                text-align: center;
                font-family: $font-inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
              }
            }
          }

          .btn {
            &.btn-back {
              width: 3.2rem;
              height: 3.2rem;
              display: flex;
              align-items: center;
              justify-content: center;
              background: linear-gradient(180deg,
                  rgba(255, 255, 255, 0) 0%,
                  rgba(193, 199, 208, 0.02) 100%),
                #fff;
              box-shadow: 0rem 0rem 0rem 0.1rem rgba(18, 55, 105, 0.08),
                0rem 0.1rem 0.2rem 0rem rgba(164, 172, 185, 0.24);
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
              margin-bottom: 0.8rem;

              img {
                transform: rotate(90deg);
                -webkit-transform: rotate(90deg);
                -moz-transform: rotate(90deg);
                -ms-transform: rotate(90deg);
                -o-transform: rotate(90deg);
              }
            }
          }
        }

        .garmentmenu {
          right: 0;
          top: 5.4rem;
          min-width: 48.5rem;

          .cardcircle {
            height: 3.2rem;
            display: flex;
            align-items: center;
          }

          .max-h-account {
            max-height: 11rem;
          }

          button {
            &.addbutton {
              height: 3.2rem;
              width: 3.2rem;
              border-radius: 0.8rem;

              img {
                width: 1.4rem;
              }
            }
          }

          .btngrpss {
            margin-bottom: 2.4rem;
            max-height: 10rem;
            overflow: auto;
            scrollbar-width: none;
            padding: 1rem;

            button {
              span {
                background: #f6f8fa;
                width: 2.4rem;
                height: 2.4rem;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 0.6rem;
              }
            }
          }

          .grmac {
            button {
              line-height: normal;
            }
          }

          .accordion-button {
            &:focus {
              box-shadow: none;
              border: none;
            }
          }

          .councled {
            display: grid;
            grid-template-columns: 9.6rem 1fr;
            margin-bottom: 1.6rem;
            grid-gap: 1.6rem;
            align-items: center;

            .img-box {
              display: flex;
              align-items: center;
              height: 9.6rem;
              border: 0.1rem solid #dfe1e7;
              background: #f8fafb;
              justify-content: center;
              border-radius: 0.8rem;
              -webkit-border-radius: 0.8rem;
              -moz-border-radius: 0.8rem;
              -ms-border-radius: 0.8rem;
              -o-border-radius: 0.8rem;


              img {
                width: 6.7rem;
                height: 6.7rem;
                object-fit: contain;
                mix-blend-mode: multiply;
              }
            }

            .product-color {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 1.6rem;
              height: 1.6rem;
              border-radius: 0.4rem;
              -webkit-border-radius: 0.4rem;
              -moz-border-radius: 0.4rem;
              -ms-border-radius: 0.4rem;
              -o-border-radius: 0.4rem;
              background: $color_13;
            }
          }

          .esc {
            margin-bottom: 2.4rem;
          }

          .mkpo {
            padding: 1.2rem;
            background: #f6f8fa;
            border-radius: 0.6rem;

            p {
              font-size: 1.4rem;
            }
          }

          .clrbx {
            position: relative;
            margin-left: 20px;

            &::after {
              content: "";
              background: #000;
              border-radius: 0.4rem;
              height: 1.6rem;
              width: 1.6rem;
              position: absolute;
              left: -19px;
              top: 50%;
              transform: translateY(-50%);
            }
          }
        }

        &:hover {
          .hovsh {
            box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
              0px 1px 2px 0px rgba(18, 55, 105, 0.08);
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
          }
        }
      }
    }

    li {
      &.nav-item {
        padding: 1.2rem 0;

        &:hover,
        &.active {
          a {
            &.nav-link {
              border-radius: 6px;
              color: #0d0d12;
              box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
                0px 1px 2px 0px rgba(18, 55, 105, 0.08);
              border-radius: 6px;
              color: #0d0d12;
              box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
                0px 1px 2px 0px rgba(18, 55, 105, 0.08);

              img {
                transform: rotate(-180deg);
                transform: rotate(-180deg);
              }
            }
          }
        }

        a {
          &.nav-link {
            img {
              transition: 0.3s all;
            }
          }

          &.active {
            border-radius: 6px;
            color: #0d0d12;
            box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
              0px 1px 2px 0px rgba(18, 55, 105, 0.08);
            border-radius: 6px;
            color: #0d0d12;
            box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
              0px 1px 2px 0px rgba(18, 55, 105, 0.08);

            img {
              transform: rotate(-180deg);
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    .max-width-container {
      padding: 0;
      margin-bottom: 0px;
    }

    .logo-box {
      min-width: 12rem;

      .navbar-brand {
        height: 4rem;
      }

      .webheader-logo {
        display: inline-block;
        min-width: 27rem;

        img {
          height: 4rem;
        }
      }
    }

    a {
      &.nav-link {
        margin: 0 0.8rem;
        color: $color_2;
      }
    }

    .showmega {
      .megamenu {
        position: absolute;
        transition: opacity 200ms ease-in, transform 200ms ease-in,
          visibility 200ms ease-in;
        transform: translate(0, 20px);
        visibility: hidden;
        opacity: 0;
        z-index: 1040;
        border-radius: 1.6rem;
        box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25),
          0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04),
          0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
        backdrop-filter: blur(3.743654727935791px);
        overflow: hidden;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;
      }

      &:hover,
      &.active {
        .megamenu {
          transform: translate(0, 0px);
          visibility: visible;
          opacity: 1;
        }

        .aboutmenu {
          transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }

        .allpmega {
          transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }

        .solmenu {
          transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }

        .indusmenu {
          transform: translate(-50%, 0);
          -webkit-transform: translate(-50%, 0);
          -moz-transform: translate(-50%, 0);
          -ms-transform: translate(-50%, 0);
          -o-transform: translate(-50%, 0);
        }
      }

      .allpmega {
        width: 1200px;
        max-width: 100%;
        padding: 2.4rem;
        top: 6.2rem;
        transform: translate(-50%, 20px);
        left: 50%;
        -webkit-transform: translate(-50%, 20px);
        -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
        -o-transform: translate(-50%, 20px);

        .title {
          font-family: $font-inter;
          font-size: $text_1;
          color: $color_3;
          font-weight: 600;
          line-height: $lh-2;
          letter-spacing: 0.04em;
          text-align: left;
          margin-bottom: 1.2rem;
        }

        &.collection {
          padding: 0rem;

          .allshowcontent {
            display: block;

            .leftul{
              border: none;
              padding: 2rem;

              ul{
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                column-gap: 0.8rem;
              }

              .btn-light.w-100{
                width: fit-content !important;
              }
            }
          }
        }

        &.category {

          .allshowcontent {
            display: block;
          }
        }
      }

      .solmenu {
        transform: translate(-50%, 20px);
        left: 50%;
        -webkit-transform: translate(-50%, 20px);
        -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
        -o-transform: translate(-50%, 20px);
      }

      .aboutmenu {
        transform: translate(-50%, 20px);
        left: 50%;
        -webkit-transform: translate(-50%, 20px);
        -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
        -o-transform: translate(-50%, 20px);
      }

      .indusmenu {
        transform: translate(-50%, 20px);
        left: 50%;
        -webkit-transform: translate(-50%, 20px);
        -moz-transform: translate(-50%, 20px);
        -ms-transform: translate(-50%, 20px);
        -o-transform: translate(-50%, 20px);
      }
    }

    .hleftline {
      &::after {
        content: "";
        position: absolute;
        width: 0.5px;
        height: 3rem;
        background: #dee2e6;
        left: -0.8rem;
      }
    }

    .aboutmenu {
      width: 65.7rem;
      left: 0;
      top: 6.2rem;
      max-width: 100%;
      padding: 2.4rem;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.8rem;

        li {
          padding: 0.8rem 1.2rem;
          display: grid;
          align-items: center;
          cursor: pointer;
          grid-template-columns: 3.6rem 1fr;
          grid-gap: 1.2rem;
          position: relative;
          overflow: hidden;

          p {
            font-size: 1.4rem;
          }

          .img-box {
            height: 3.6rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
          }

          &:hover {
            background: #f6f8fa;
            border-radius: 1.2rem;

            .img-box {
              background: linear-gradient(180deg,
                  rgba(255, 255, 255, 0.12) 0%,
                  rgba(255, 255, 255, 0) 100%),
                #f79631;
              box-shadow: 0px 0px 0px 1px #f79631,
                0px 1px 2px 0px rgba(159, 88, 15, 0.5);
            }

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    .solmenu {
      width: 52.7rem;
      left: 0;
      top: 6.2rem;
      max-width: 100%;
      padding: 2.4rem;

      ul {
        display: grid;
        grid-gap: 0.8rem;

        li {
          padding: 0.8rem 1.2rem;
          display: grid;
          align-items: center;
          cursor: pointer;
          border-radius: 1.2rem;
          grid-template-columns: 3.6rem 1fr;
          grid-gap: 1.2rem;
          position: relative;
          overflow: hidden;

          p {
            font-size: 1.4rem;
          }

          .img-box {
            height: 3.6rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
          }

          &:hover {
            background: #f6f8fa;
            border-radius: 1.2rem;

            .img-box {
              background: linear-gradient(180deg,
                  rgba(255, 255, 255, 0.12) 0%,
                  rgba(255, 255, 255, 0) 100%),
                #f79631;
              box-shadow: 0px 0px 0px 1px #f79631,
                0px 1px 2px 0px rgba(159, 88, 15, 0.5);
            }

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    .indusmenu {
      width: 65.7rem;
      left: 0;
      top: 6.2rem;
      max-width: 100%;
      padding: 2.4rem;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.8rem;

        li {
          padding: 0.8rem 1.2rem;
          display: grid;
          align-items: center;
          cursor: pointer;
          grid-template-columns: 3.6rem 1fr;
          grid-gap: 1.2rem;
          position: relative;
          overflow: hidden;

          p {
            font-size: 1.4rem;
          }

          .img-box {
            height: 3.6rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
          }

          &:hover {
            background: #f6f8fa;
            border-radius: 1.2rem;

            .img-box {
              background: linear-gradient(180deg,
                  rgba(255, 255, 255, 0.12) 0%,
                  rgba(255, 255, 255, 0) 100%),
                #f79631;
              box-shadow: 0px 0px 0px 1px #f79631,
                0px 1px 2px 0px rgba(159, 88, 15, 0.5);
            }

            svg {
              path {
                stroke: #fff;
              }
            }
          }
        }
      }
    }

    .megamenu {
      ul {
        li {
          .aricon {
            position: absolute;
            right: 2rem;
            top: 50%;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            transition: 0.3s all;
            -webkit-transition: 0.3s all;
            -moz-transition: 0.3s all;
            -ms-transition: 0.3s all;
            -o-transition: 0.3s all;
          }

          &:hover {
            .aricon {
              right: 3rem;
              opacity: 1;
              visibility: visible;
            }
          }
        }
      }
    }

    .allpmega {
      .leftul {
        padding: 1.6rem;
        border: 0.1rem solid $border-neutral-solid;
        border-radius: .8rem;
        -webkit-border-radius: .8rem;
        -moz-border-radius: .8rem;
        -ms-border-radius: .8rem;
        -o-border-radius: .8rem;

        .svgline {
          display: flex;
          margin: 1rem 0 1.6rem 0;

          svg{
            width: 100%;
          }
        }

        li {
          display: grid;
          grid-gap: 1.2rem;
          position: relative;
          font-size: 1.4rem;
          padding: 0.8rem 4.4rem 0.8rem 1.2rem;
          align-items: center;
          margin-bottom: 0.8rem;
          grid-template-columns: 3.6rem 1fr;
          cursor: pointer;
          border-radius: 0.8rem;
          -webkit-border-radius: 0.8rem;
          -moz-border-radius: 0.8rem;
          -ms-border-radius: 0.8rem;
          -o-border-radius: 0.8rem;

          &:hover {
            background: #f6f8fa;

            .aricon {
              right: 1.2rem;
            }

            .img-box {
              box-shadow: 0px 0px 0px 1px #f79631,
                0px 1px 2px 0px rgba(159, 88, 15, 0.5);
            }
          }

          .img-box {
            height: 3.6rem;
            width: 3.6rem;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
            overflow: hidden;
          }
        }
      }

      .allshowcontent {
        display: grid;
        grid-gap: 2.4rem;
        grid-template-columns: 22.7rem 1fr;
      }

      .rightmenus {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 1.6rem;

        ul {
          &.subcalist {
            li {
              margin-bottom: 0rem;
              font-size: 1.4rem;

              .nav-link {
                color: $color_6;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                padding: 0.7rem 0rem;
                display: inline-block;
                box-shadow: none;
              }

              .showall {
                font-weight: $font_weight_bold;
              }
            }
          }
        }

        .tmitem {
          .hbox {
            position: relative;
            margin-bottom: 0.8rem;
            overflow: hidden;
            padding: 1.6rem 1.2rem;
            background: #f6f8fa;
            border-radius: 1.2rem;
            -webkit-border-radius: 1.2rem;
            -moz-border-radius: 1.2rem;
            -ms-border-radius: 1.2rem;
            -o-border-radius: 1.2rem;
            cursor: pointer;

            .imgab {
              position: absolute;
              top: 4.63px;
              right: 0;
              transform: rotate(6.006deg);
            }

            .subheadname {
              font-weight: $font_weight_bold;
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .mob {
      display: none;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .header .logo-box .webheader-logo {
    min-width: auto;
  }

  .header {
    padding: 1.4rem 2rem;

    .logo-box {
      min-width: auto;
    }

    .max-width-container {
      padding: 0;
    }

    .navbar-toggler {
      padding: 0.6rem;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
      box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
        0px 1px 2px 0px rgba(164, 172, 185, 0.24);
      border: none;

      span {
        display: inline-block;
        height: 2rem;
        width: 2rem;
      }
    }
  }
}

@media only screen and (max-width: 992px) {
  .header {
    .mobilemenu {
      display: block;
      position: fixed;
      top: 0;
      left: -100%;
      padding: 1.6rem 1.6rem 3.4rem 1.6rem;
      width: 100%;
      height: 100%;
      background: $color_5;
      z-index: 11111;
      transition: 0.3s all;
      -webkit-transition: 0.3s all;
      -moz-transition: 0.3s all;
      -ms-transition: 0.3s all;
      -o-transition: 0.3s all;

      &.active {
        left: 0;
      }
    }

    .mobilemain-menu {
      height: 100%;

      .submenubox {
        height: 100%;
        width: 100%;
        left: 0;
        background: $color_5;
        padding: 1.6rem;
        overflow: auto;
        top: 56px;
        border-top: 1px solid #eceff3;

        .lumni {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
            0px 1px 2px 0px rgba(164, 172, 185, 0.24);
          padding: 0.8rem 1.2rem;
          margin-bottom: 1.6rem;
          border-radius: 0.8rem;
          -webkit-border-radius: 0.8rem;
          -moz-border-radius: 0.8rem;
          -ms-border-radius: 0.8rem;
          -o-border-radius: 0.8rem;
        }

        .tpuld {
          margin-bottom: 1.6rem;

          ul {
            li {
              &.active {
                border-radius: 6px;
                background: linear-gradient(180deg,
                    rgba(255, 255, 255, 0.12) 0%,
                    rgba(255, 255, 255, 0) 100%),
                  #f68c1e;
                box-shadow: 0px 0px 0px 1px #f68c1e,
                  0px 1px 2px 0px rgba(174, 95, 12, 0.5);
                color: #fff;

                svg {
                  path {
                    stroke: white;
                  }
                }
              }
            }
          }
        }

        ul {
          li {
            &:last-child {
              margin-bottom: 0;
            }
          }
        }

        .infoul {
          margin-bottom: 1.6rem;
        }

        .uniformsect,
        .allpcategory,
        .subabs {
          overflow: auto;
          max-height: calc(100dvh - 8rem)
        }

        .allpcategory {
          padding: 1.6rem;
          border: 1px solid #eceff3;
          border-radius: 0.8rem;

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.4rem;

            .left-b {
              display: flex;
              align-items: center;
              grid-gap: 1.2rem;

              .img-box {
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
              }
            }

            .left-b,
            .aricon {
              cursor: pointer;
            }
          }
        }

        .uniformsect {
          .hbox {
            position: relative;
            margin-bottom: 1.6rem;
            overflow: hidden;
            padding: 1.6rem 1.2rem;
            background: #f6f8fa;
            border-radius: 1.2rem;
            -webkit-border-radius: 1.2rem;
            -moz-border-radius: 1.2rem;
            -ms-border-radius: 1.2rem;
            -o-border-radius: 1.2rem;

            .imgab {
              position: absolute;
              top: 4.63px;
              right: 0;
              transform: rotate(6.006deg);
            }
          }

          .tmitem {
            margin-bottom: 2.4rem;

            li {
              line-height: 2.2rem;
              font-size: 1.4rem;
              margin-bottom: 0.8rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .subabs {
          .img-box {
            height: 3.6rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08);
          }

          li {
            display: flex;
            align-items: center;
            margin-bottom: 2.4rem;
            grid-gap: 1.2rem;
            padding: 0.8rem 1.2rem;
            cursor: pointer;

            p {
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .mainmenubox {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      ul {
        &.mvul {
          overflow: auto;
          max-height: calc(100dvh - 18rem);
          padding: 0.6rem;

          li {
            display: flex;
            font-weight: $font_weight_bold;
            cursor: pointer;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 2.4rem;
            font-size: 2rem;

            &.fontmainmenu {
              font-size: 1.2rem;
              margin-bottom: 1.2rem;
            }

            &:last-child {
              margin-bottom: 0;
            }

            .srbox {
              .form-control {
                background: url("../../images/search.svg") #ffffff;
                background-repeat: no-repeat;
                background-position: center left 0.8rem;
                padding: 0.8rem 1rem 0.8rem 3.2rem;
              }
            }

            .hrdashline {
              height: 3px;
              width: 100%;
              background: url("../../images/hrdashline.svg");
            }

            .mpname {
              display: flex;
              align-items: center;
              grid-gap: 0.5rem;

              a {
                font-size: 1rem;
                width: 3rem;
                height: 3rem;
                border-radius: 50%;
                box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
                  0px 1px 2px 0px rgba(18, 55, 105, 0.08);
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: normal;
                font-weight: $font_weight_bold;
                color: #000;
              }
            }
          }

          &.btul {
            li {
              margin-bottom: 1.2rem;

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        &.noAccess {
          overflow: auto;
          max-height: calc(100dvh - 8rem);
        }
      }

      .bottombar {
        padding: 1.2rem;
        border-radius: 0.8rem;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        -ms-border-radius: 0.8rem;
        -o-border-radius: 0.8rem;
      }
    }

    .logo-box {
      min-width: auto;
    }

    &.fixed-top {
      background-color: $color_5;
    }
  }

  .header .mobilemain-menu .submenubox .tpuld ul li,
  .header .mobilemain-menu .submenubox .descdiv ul li {
    display: flex;
    align-items: center !important;
    grid-gap: 1rem;
    margin-bottom: 0.6rem;
    padding: 1rem 0.8rem;
    border-radius: 6px;
    color: #666d80;
  }

  .mob {
    display: block;

    a {
      height: 3.6rem;
      width: 3.6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000;

      &:hover {
        svg {
          path {
            stroke: #000;
          }
        }
      }
    }
  }

  section {
    &.transforming {
      a {
        &.cart-box {
          height: 3rem;
          width: 3rem;
          padding: 0.9rem;
        }
      }

      .max-width-container {
        .transback {
          .ourclientsec {
            margin-top: 3.4rem;
          }
        }
      }

      .contents {
        .items {
          .hpba {
            button {
              font-size: 8px;
              padding: 0.6rem 0.8rem;
            }
          }
        }
      }

      h1 {
        line-height: 5rem;
      }

      .allocation-box {
        right: 12%;

        span {
          .fs-6 {
            font-size: 0.6rem !important;
          }
        }

        .alocbox {
          padding: 0 0.6rem;

          a {
            img {
              width: 1.6rem;
              height: 1.6rem;
            }
          }
        }
      }
    }

    &.sumsProduct {
      p {
        font-size: 1.6rem;
      }

      .contents {
        grid-template-columns: 1fr;
        grid-gap: 4rem;

        .items {
          order: 1;
          margin-left: 0;
          margin-top: 2rem;
        }

        .itemsb {
          order: 2;
        }
      }
    }

    &.access {
      .contents {
        grid-gap: 1rem;
      }

      p {
        font-size: 1.4rem;
      }
    }

    &.what_clients {
      .contents {
        grid-template-columns: 1fr 1fr;
        grid-gap: 4rem;

        .img-box {
          img {
            width: 100%;
          }
        }
      }

      .max-width-container {
        padding: 0;
      }
    }

    &.hivis {
      .contents {
        grid-template-columns: 1fr;
      }
    }

    &.weKnow {
      .contents {
        grid-template-columns: 1fr;
      }
    }

    &.about {
      .container {
        .items {
          ul {
            &.brns {
              grid-gap: 5rem;
            }
          }
        }
      }
    }

    &.whatcl {
      .contents {
        grid-template-columns: 1fr;

        .items {
          p {
            font-size: 1.4rem;
            line-height: 2.5rem;
          }
        }
      }
    }
  }

  .btn {
    padding: 0.8rem;
    font-size: 1.2rem;
  }

  .footer {
    .foot {
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 0.5rem;
      margin-top: 4rem;
      margin: 2rem 0;

      .flinks {
        ul {
          li {
            a {
              font-size: 1.4rem;
            }
          }
        }
      }
    }

    .sociallinks {
      a {
        width: 4rem;
        height: 4rem;
      }
    }
  }
}

@media only screen and (max-width: 991px) {
  .header {

    .mob {
      .loginbox {
        li {
          cursor: pointer;

          a {
            height: 3.6rem;
            width: 3.6rem;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;

            &:hover {
              svg {
                path {
                  stroke: #000;
                }
              }
            }
          }

          .usermenu {
            right: -10px;
            top: 5.4rem;
            min-width: 27rem;

            ul {
              border-radius: 1.2rem;
              -webkit-border-radius: 1.2rem;
              -moz-border-radius: 1.2rem;
              -ms-border-radius: 1.2rem;
              -o-border-radius: 1.2rem;

              li {
                font-size: 1.4rem;

                .badge {
                  color: #5c3d1f;
                  background: #faedcc;
                  font-size: 1.2rem;
                }
              }
            }

            .avat {
              width: 3.6rem;
              height: 3.6rem;
              font-size: 1.2rem;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08),
                0px 1px 2px 0px rgba(164, 172, 185, 0.24);
            }

            .avatname {
              font-size: 1.4rem;
            }

            .avatemail {
              font-size: 1.4rem;
            }
          }

          .existuser {
            right: -10px;
            top: 5.4rem;
            max-width: 100%;
            width: 100%;

            form {
              padding: 2.4rem;

              h4 {
                color: #666d80;
                font-family: $font-inter;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 600;
                line-height: 200%;
                letter-spacing: 0.048rem;
                text-transform: capitalize;
                margin-bottom: 0.8rem;
              }

              .form-group {
                margin-bottom: 1.6rem;
              }

              .form-label {
                color: $color_2;
                font-family: $font-inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: $font_weight_bold;
                line-height: 142.857%;
                margin-bottom: 0.4rem;
              }

              .form-control {
                background: $color_5;
                box-shadow: 0rem 0rem 0rem 0.1rem rgba(9, 25, 72, 0.13),
                  0rem 0.1rem 0.2rem 0rem rgba(18, 55, 105, 0.08);
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
                padding: 0.8rem 1rem;
                border: 0rem;
                color: #818898;
                font-family: $font-inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 142.857%;
                letter-spacing: -0.014rem;

                &.form-code {
                  padding-left: 6rem;
                }
              }

              .form-text {
                font-family: $font-inter;
                font-size: 1.2rem;
                font-style: normal;
                font-weight: 400;
                line-height: 133.333%;
                margin: 0.8rem 0rem 0rem;
              }

              .btn {
                &.btn-link {
                  width: fit-content;
                  height: auto;
                  padding: 0.8rem 1rem;
                  color: $color_2;
                  text-align: center;
                  font-family: $font-inter;
                  font-size: 1.4rem;
                  font-style: normal;
                  font-weight: $font_weight_bold;
                  line-height: 142.857%;
                  text-decoration: none;
                }

                &.btn-primary {
                  width: 100%;
                }
              }
            }

            .login-new-acc {
              padding: 1.6rem 2.4rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              row-gap: 0.8rem;

              p {
                color: #666d80;
                text-align: center;
                font-family: $font-inter;
                font-size: 1.4rem;
                font-style: normal;
                font-weight: 400;
                line-height: 157.143%;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
              }

              .btn {
                &.btn-light {
                  width: 100%;
                  height: auto;
                  padding: 0.8rem 1rem;
                  color: $color_2;
                  text-align: center;
                  font-family: $font-inter;
                  font-size: 1.4rem;
                  font-style: normal;
                  font-weight: $font_weight_bold;
                  line-height: 142.857%;
                }
              }
            }

            .btn {
              &.btn-back {
                width: 3.2rem;
                height: 3.2rem;
                display: flex;
                align-items: center;
                justify-content: center;
                background: linear-gradient(180deg,
                    rgba(255, 255, 255, 0) 0%,
                    rgba(193, 199, 208, 0.02) 100%),
                  #fff;
                box-shadow: 0rem 0rem 0rem 0.1rem rgba(18, 55, 105, 0.08),
                  0rem 0.1rem 0.2rem 0rem rgba(164, 172, 185, 0.24);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
                margin-bottom: 0.8rem;

                img {
                  transform: rotate(90deg);
                  -webkit-transform: rotate(90deg);
                  -moz-transform: rotate(90deg);
                  -ms-transform: rotate(90deg);
                  -o-transform: rotate(90deg);
                }
              }
            }
          }

          .garmentmenu {
            right: 0;
            top: 5.4rem;
            min-width: 48.5rem;

            .cardcircle {
              height: 3.2rem;
              display: flex;
              align-items: center;
            }

            button {
              &.addbutton {
                height: 3.2rem;
                width: 3.2rem;
                border-radius: 0.8rem;

                img {
                  width: 1.4rem;
                }
              }
            }

            .btngrpss {
              margin-bottom: 2.4rem;

              button {
                span {
                  background: #f6f8fa;
                  width: 2.4rem;
                  height: 2.4rem;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-left: 0.6rem;
                }
              }
            }

            .grmac {
              button {
                line-height: normal;
              }
            }

            .accordion-button {
              &:focus {
                box-shadow: none;
                border: none;
              }
            }

            .councled {
              display: grid;
              grid-template-columns: 9.6rem 1fr;
              margin-bottom: 1.6rem;
              grid-gap: 1.6rem;
              align-items: center;

              .img-box {
                display: flex;
                align-items: center;
                height: 9.9rem;
                border-radius: 8px;
                border: 1px solid #dfe1e7;
                background: #f8fafb;
                justify-content: center;

                img {
                  width: 5.7rem;
                  height: 5.7rem;
                  object-fit: contain;
                }
              }
            }

            .esc {
              margin-bottom: 2.4rem;
            }

            .mkpo {
              padding: 1.2rem;
              background: #f6f8fa;
              border-radius: 0.6rem;

              p {
                font-size: 1.4rem;
              }
            }

            .clrbx {
              position: relative;
              margin-left: 20px;

              &::after {
                content: "";
                background: #000;
                border-radius: 0.4rem;
                height: 1.6rem;
                width: 1.6rem;
                position: absolute;
                left: -19px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

          &:hover {
            .hovsh {
              box-shadow: 0px 0px 0px 1px rgba(9, 25, 72, 0.13),
                0px 1px 2px 0px rgba(18, 55, 105, 0.08);
              border-radius: 50%;
              -webkit-border-radius: 50%;
              -moz-border-radius: 50%;
              -ms-border-radius: 50%;
              -o-border-radius: 50%;
            }
          }
        }
      }
    }

    .showmega {
      .megamenu {
        position: absolute;
        transition: opacity 200ms ease-in, transform 200ms ease-in,
          visibility 200ms ease-in;
        transform: translate(0, 20px);
        visibility: hidden;
        opacity: 0;
        z-index: 1040;
        border-radius: 1.6rem;
        box-shadow: 0px 11.98px 23.959px -8.985px rgba(7, 32, 69, 0.25),
          0px 0.749px 1.497px 0px rgba(29, 79, 129, 0.04),
          0px 0px 0px 0.749px rgba(18, 55, 105, 0.08);
        backdrop-filter: blur(3.743654727935791px);
        overflow: hidden;
        -webkit-border-radius: 1.6rem;
        -moz-border-radius: 1.6rem;
        -ms-border-radius: 1.6rem;
        -o-border-radius: 1.6rem;
      }

      &:hover,
      &.active {
        .megamenu {
          transform: translate(0, 0px);
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .header {
    padding: 2.4rem 1.6rem;

    .logo-box {
      min-width: auto;
    }

    .backs {

      .btn-back {
        font-size: $text_1;

        span {
          width: 2rem;
          height: 2rem;
        }
      }

      .logo-box {
        margin-left: 4rem;
      }
    }
  }
}

.max-h-account {
  max-height: 20rem;
  overflow: hidden auto;
  scrollbar-color: $Neutral-Solid-50 $bg-White;
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: $bg-White;
    border-radius: 9rem;
    -webkit-border-radius: 9rem;
    -moz-border-radius: 9rem;
    -ms-border-radius: 9rem;
    -o-border-radius: 9rem;
  }

  &::-webkit-scrollbar {
    background-color: $bg-White;
    width: 0.8rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $Neutral-Solid-50;
    border-radius: 9rem;
    -webkit-border-radius: 9rem;
    -moz-border-radius: 9rem;
    -ms-border-radius: 9rem;
    -o-border-radius: 9rem;
  }
}