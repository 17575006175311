.product-list-content {
    display: grid;
    grid-template-columns: auto 28.2rem;
    align-items: flex-start;
    gap: 2.4rem;

    .main-title {
        color: $color_6;
        font-family: $suisse_medium;
        font-size: $text_5;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 140%;
        margin-bottom: 1.6rem;
        letter-spacing: 0.01rem;
    }

    .title {
        color: $color_6;
        font-family: $font-inter;
        font-size: $text_3;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 150%;
        letter-spacing: -0.032rem;
    }

    .selected-product {
        display: flex;
        align-items: center;
        gap: 0.8rem;
        padding: 0.5rem 0.8rem;

        p {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_2;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 150%;
            letter-spacing: -0.014rem;

            span {
                color: $color_3;
                font-weight: $font_weight_normal;
            }
        }

        .selected {
            display: flex;
            align-items: center;
            gap: 0.4rem;

            span {
                background: $border-neutral-solid;
                padding: 0.2rem 0.6rem;
                color: $color_2;
                font-family: $font-inter;
                font-size: 1rem;
                font-style: $font_style_1;
                font-weight: $font_weight_normal;
                line-height: 160%;
                letter-spacing: -0.01rem;
                border-radius: 0.4rem;
                -webkit-border-radius: 0.4rem;
                -moz-border-radius: 0.4rem;
                -ms-border-radius: 0.4rem;
                -o-border-radius: 0.4rem;
            }
        }
    }

    .products {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 2.4rem;
        align-items: flex-start;

        .product-card {

            .card-img {
                margin-bottom: 1.2rem;
                padding: 0.1rem 5.6rem;
                max-height: 27.3rem;
            }

            .card-content {

                .price {
                    font-size: $text_2;
                    font-weight: $font_weight_normal;
                    margin-bottom: 0.8rem;
                }

                .quick-select {

                    .row {
                        row-gap: 1.2rem;
                        margin-bottom: 0.8rem;

                        .qty-input {
                            height: 3.7rem;
                            justify-content: space-between;
                            border-radius: 0.6rem;
                            -webkit-border-radius: 0.6rem;
                            -moz-border-radius: 0.6rem;
                            -ms-border-radius: 0.6rem;
                            -o-border-radius: 0.6rem;
                        }

                        .no-more-content {
                            padding: 0.8rem 1rem;
                            background-color: $bg-White;
                            box-shadow: $shadow-4;
                            border-radius: 0.6rem;
                            -webkit-border-radius: 0.6rem;
                            -moz-border-radius: 0.6rem;
                            -ms-border-radius: 0.6rem;
                            -o-border-radius: 0.6rem;
                            height: 3.78rem;
                            display: flex;
                            align-items: center;

                            .no-more-title {
                                opacity: 0.5;
                                color: $color_6;
                                font-family: $font-inter;
                                font-size: $text_3;
                                font-style: $font_style_1;
                                font-weight: $font_weight_bold;
                                line-height: 150%;
                                letter-spacing: -0.032rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .added-product-content {
        .edit-card {

            .accordion {
                border: 0rem;
                overflow: hidden;
                border-radius: 1.2rem;
                -webkit-border-radius: 1.2rem;
                -moz-border-radius: 1.2rem;
                -ms-border-radius: 1.2rem;
                -o-border-radius: 1.2rem;

                .accordion-item {
                    border: 0rem;

                    .accordion-button {
                        padding: 2rem;
                        color: $color_1;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_bold;
                        line-height: $lh_1;
                        letter-spacing: -0.014rem;
                        background-color: transparent;
                        display: flex;
                        align-items: center;
                        gap: 0.8rem;
                        border-bottom: 0.1rem solid $border-neutral-solid;

                        &:focus {
                            box-shadow: none;
                        }

                        span {
                            background: $color_9;
                            min-width: 2.6rem;
                            height: 2.6rem;
                            padding: 0.4rem;
                            border-radius: 9.9rem;
                            -webkit-border-radius: 9.9rem;
                            -moz-border-radius: 9.9rem;
                            -ms-border-radius: 9.9rem;
                            -o-border-radius: 9.9rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $color_2;
                            font-family: $font-inter;
                            font-size: $text_1;
                            font-style: $font_style_1;
                            font-weight: 600;
                            line-height: 100%;
                            letter-spacing: 0.012rem;
                            text-transform: uppercase;
                        }

                        &::after {
                            background-image: url("../../images/chevron-down.svg");
                            background-origin: border-box;
                            box-shadow: $shadow-2;
                            border-radius: 99rem;
                            -webkit-border-radius: 99rem;
                            -moz-border-radius: 99rem;
                            -ms-border-radius: 99rem;
                            -o-border-radius: 99rem;
                            width: 3rem;
                            height: 3rem;
                            background-position: center;
                            background-size: auto;
                        }
                    }

                    .accordion-body {
                        padding: 2rem;
                    }
                }
            }
        }
    }

    .added-product-list {
        max-height: 32rem;
        overflow: hidden auto;
        scrollbar-color: $Neutral-Solid-50 $bg-White;
        scrollbar-width: thin;

        &::-webkit-scrollbar-track {
            background-color: $bg-White;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }

        &::-webkit-scrollbar {
            background-color: $bg-White;
            width: 0.8rem;
        }

        &::-webkit-scrollbar-thumb {
            background-color: $Neutral-Solid-50;
            border-radius: 9rem;
            -webkit-border-radius: 9rem;
            -moz-border-radius: 9rem;
            -ms-border-radius: 9rem;
            -o-border-radius: 9rem;
        }

        .add-product {
            display: grid;
            grid-template-columns: 6.7rem auto;
            align-items: flex-start;
            gap: 1.6rem;

            .card-img {
                background: $color_9;
                border-radius: 0.6rem;
                -webkit-border-radius: 0.6rem;
                -moz-border-radius: 0.6rem;
                -ms-border-radius: 0.6rem;
                -o-border-radius: 0.6rem;
                padding: 1rem;

                img {
                    mix-blend-mode: multiply;
                }
            }

            .content {

                .title {
                    color: $color_6;
                    font-family: $font-inter;
                    font-size: $text_1;
                    font-style: $font_style_1;
                    font-weight: $font_weight_normal;
                    line-height: 166.667%;
                    letter-spacing: -0.024rem;
                    margin-bottom: 0.4rem;
                }

                .price {
                    @extend .title;
                    letter-spacing: normal;
                    color: $color_1;
                    font-weight: $font_weight_bold;
                }

                .featured {
                    display: flex;
                    flex-wrap: wrap;
                    gap: 0.6rem;
                    align-items: flex-start;

                    p {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 0.6rem;
                        background: $border-neutral-solid;
                        padding: 0.2rem 0.6rem;
                        border-radius: 0.4rem;
                        -webkit-border-radius: 0.4rem;
                        -moz-border-radius: 0.4rem;
                        -ms-border-radius: 0.4rem;
                        -o-border-radius: 0.4rem;
                        color: $color_2;
                        font-family: $font-inter;
                        font-size: 1rem;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: 160%;
                        letter-spacing: -0.01rem;

                        .color {
                            background: $color_13;
                            width: 1rem;
                            height: 1rem;
                            border-radius: 2.8rem;
                            -webkit-border-radius: 2.8rem;
                            -moz-border-radius: 2.8rem;
                            -ms-border-radius: 2.8rem;
                            -o-border-radius: 2.8rem;
                        }
                    }
                }
            }
        }

        > :not(:last-child) {
            padding-bottom: 1.6rem;
            margin-bottom: 1.6rem;
            border-bottom: 0.1rem solid $border-neutral-solid;
        }
    }
}

.product-list-head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .main-title {
        color: $color_6;
        font-family: $suisse_medium;
        font-size: $text_5;
        font-style: $font_style_1;
        font-weight: $font_weight_bold;
        line-height: 140%;
        display: flex;
        align-items: flex-end;
        gap: 0.8rem;

        span.badge {
            font-size: $text_3;
            font-weight: $font_weight_bold;
            line-height: 150%;
            letter-spacing: -0.032rem;
        }
    }

    .head-filter {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 4rem;
    }
}

.category-product-list {
    display: flex;
    align-items: flex-start;

    .category-filter {
        height: 0;
        opacity: 0;
        min-width: 0%;
        width: 0%;
        position: relative;
        left: -25%;
        transition: all 0.3s ease-in;
        -webkit-transition: all 0.3s ease-in;
        -moz-transition: all 0.3s ease-in;
        -ms-transition: all 0.3s ease-in;
        -o-transition: all 0.3s ease-in;


        &.active {
            height: auto;
            opacity: 1;
            width: 23.5%;
            min-width: 23.5%;
            left: 0%;
            transition: all 0.3s ease-in;
            -webkit-transition: all 0.3s ease-in;
            -moz-transition: all 0.3s ease-in;
            -ms-transition: all 0.3s ease-in;
            -o-transition: all 0.3s ease-in;
            margin-right: 2.6rem;
        }

        .title {
            color: $color_6;
            font-family: $font-inter;
            font-size: $text_3;
            font-style: $font_style_1;
            font-weight: $font_weight_bold;
            line-height: 150%;
            letter-spacing: -0.032rem;
            margin-bottom: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            span {
                display: none;
                cursor: pointer;
            }
        }

        .type {
            color: $color_3;
            font-family: $font-inter;
            font-size: $text_1;
            font-style: $font_style_1;
            font-weight: 600;
            line-height: 200%;
            letter-spacing: 0.048rem;
            text-transform: uppercase;
            margin-bottom: 0.8rem;
        }

        .filters {

            .accordion {
                display: flex;
                flex-direction: column;
                gap: 1.6rem;

                .accordion-item {
                    border-bottom: 0.1rem dashed #DFE1E7 !important;

                    .accordion-button {
                        padding: 0.8rem 0rem;
                        color: $color_6;
                        font-family: $font-inter;
                        font-size: $text_2;
                        font-style: $font_style_1;
                        font-weight: $font_weight_normal;
                        line-height: 157.143%;
                        letter-spacing: -0.014rem;
                        gap: 0.8rem;
                        background-color: transparent;
                        box-shadow: none;

                        span {
                            padding: 0.6rem 0.8rem;
                            width: auto;
                            height: 2.4rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $color_6;
                            font-family: $font-inter;
                            font-size: $text_2;
                            font-style: $font_style_1;
                            font-weight: $font_weight_bold;
                            line-height: 166.667%;
                            background: $border-neutral-solid;
                            border-radius: 4rem;
                            -webkit-border-radius: 4rem;
                            -moz-border-radius: 4rem;
                            -ms-border-radius: 4rem;
                            -o-border-radius: 4rem;
                        }

                        &:focus {
                            box-shadow: none;
                        }

                        &::after {
                            background-image: url("../../images/chevron-down.svg");
                            background-origin: border-box;
                            width: 1.6rem;
                            height: 1.6rem;
                            background-position: center;
                            background-size: auto;
                        }

                        &.no-sub {
                            &::after {
                                content: none;
                            }
                        }
                    }

                    .accordion-body {
                        padding: 0.4rem 0rem 0.8rem;
                    }
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 1.2rem;

                li {

                    .form-check {
                        margin-bottom: 0rem;

                        .form-check-input {
                            float: right;
                            border: 0rem;
                            border-radius: 0rem;
                            -webkit-border-radius: 0rem;
                            -moz-border-radius: 0rem;
                            -ms-border-radius: 0rem;
                            -o-border-radius: 0rem;
                            background-color: transparent;

                            &.active-check,
                            &:checked {
                                background-color: transparent;
                                background-image: url("../../images/category-check.svg");

                                +.form-check-label {
                                    color: #F68C1E;

                                    span {
                                        background: #F68C1E;
                                        color: $color_5;
                                    }
                                }

                            }
                        }

                        .form-check-label {
                            color: $color_6;
                            font-family: $font-inter;
                            font-size: $text_2;
                            font-style: $font_style_1;
                            font-weight: $font_weight_bold;
                            line-height: 157.143%;
                            letter-spacing: -0.014rem;
                            display: inline-flex;
                            align-items: center;
                            gap: 0.8rem;
                            cursor: pointer;
                            max-width: 22rem;

                            span {
                                padding: 0.6rem 0.8rem;
                                width: auto;
                                height: 2.4rem;
                                min-width: 2.4rem;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                color: $color_6;
                                font-family: $font-inter;
                                font-size: $text_1;
                                font-style: $font_style_1;
                                font-weight: $font_weight_bold;
                                line-height: 166.667%;
                                background: $border-neutral-solid;
                                border-radius: 4rem;
                                -webkit-border-radius: 4rem;
                                -moz-border-radius: 4rem;
                                -ms-border-radius: 4rem;
                                -o-border-radius: 4rem;
                            }
                        }
                    }
                }
            }

        }

        .filter-bottom {
            padding: 1.6rem 0rem 0rem 0rem;
            width: 100%;
            background-color: $bg-White;

            .btns {
                gap: 1.6rem;
            }

            .swipe-tilt {
                padding: 2.1rem 0rem 0.8rem 0rem;
                display: flex;
                align-items: center;
                justify-content: center;

                .tilt {
                    width: 39.35%;
                    height: 0.5rem;
                    background: $color_14;
                    border-radius: 10rem;
                    -webkit-border-radius: 10rem;
                    -moz-border-radius: 10rem;
                    -ms-border-radius: 10rem;
                    -o-border-radius: 10rem;
                }
            }
        }

        .main-filters-column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 1.6rem;
        }
    }

    .category-product {
        width: 100%;

        .category-product-items {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 2rem 1.4rem;

            &.filterOpen {
                grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .product-card .card-img {
        height: 27rem;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1099px){

    .product-list-content{

        .products{
            grid-template-columns: repeat(2, 1fr);
        }
    }

    .category-product-list {

        .category-filter {

            .filters {

                ul{

                    li{

                        .form-check{
                            padding-left: 1rem;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (min-width: 992px) {

    .product-list-content {

        .max-h {
            max-height: 57rem;
            overflow: hidden auto;
            scrollbar-color: $Neutral-Solid-50 $bg-White;
            scrollbar-width: thin;
            padding: 0rem 0.8rem;

            &::-webkit-scrollbar-track {
                background-color: $bg-White;
                border-radius: 9rem;
                -webkit-border-radius: 9rem;
                -moz-border-radius: 9rem;
                -ms-border-radius: 9rem;
                -o-border-radius: 9rem;
            }

            &::-webkit-scrollbar {
                background-color: $bg-White;
                width: 0.8rem;
            }

            &::-webkit-scrollbar-thumb {
                background-color: $Neutral-Solid-50;
                border-radius: 9rem;
                -webkit-border-radius: 9rem;
                -moz-border-radius: 9rem;
                -ms-border-radius: 9rem;
                -o-border-radius: 9rem;
            }
        }

    }
}

@media only screen and (max-width:992px) {
    .category-product-list .category-filter .title span {
        display: block;
    }

    .category-product-list {
        gap: 0;
    }

    .product-detail-container .max-width-container {
        padding: 0;
    }

    .category-product-list .category-filter {
        position: fixed;
        top: 0;
        left: -100%;
        bottom: 0;
        z-index: 111;
        width: 100%;
        height: 100dvh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ffffff;
        padding: 2.4rem;
        padding-bottom: 1rem;
    }

    .category-product-list .category-filter.active {
        left: 0;
        width: 100%;
        margin-right: 0rem;
    }

    .product-list-content {
        grid-template-columns: 1fr;

        .products {
            grid-template-columns: 1fr 1fr;
        }
    }

    .main-filters-column {
        max-height: calc(100dvh - 24rem);
        overflow: hidden auto;
        padding: 0rem 1rem 1rem 0rem;
    }

    .product-detail-container {
        .max-width-container {
            .quick-main-title {
                color: $color_6;
                font-family: $suisse_medium;
                font-size: $text_5;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: 140%;
                margin: 0.9rem 0rem;
                letter-spacing: 0.01rem;
            }
        }
    }

    .quick-order-md-collpase {
        background: $bg-White;
        padding: 1.2rem;
        box-shadow: $shadow-2;
        border-radius: 0.8rem;
        position: fixed;
        bottom: 3%;
        left: 50%;
        width: 91.5%;
        transform: translateX(-50%);
        z-index: 10;

        .btn-collapse {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            padding: 0rem;

            h4 {
                color: $color_1;
                font-family: $font-inter;
                font-size: $text_2;
                font-style: $font_style_1;
                font-weight: $font_weight_bold;
                line-height: $lh_1;
                letter-spacing: -0.014rem;
                margin-bottom: 0rem;
                display: flex;
                align-items: center;
                gap: 0.8rem;
            }

            .main-shadow {
                width: 3.2rem;
                height: 3.2rem;
            }

            &:focus,
            &:focus-visible {
                outline: 0;
                box-shadow: none;
            }

            &:active {
                border: transparent;
            }

        }

        .btn-collapse[aria-expanded="false"] {

            .main-shadow {
                transform: rotate(0deg);
                -webkit-transform: rotate(0deg);
                -moz-transform: rotate(0deg);
                -ms-transform: rotate(0deg);
                -o-transform: rotate(0deg);
                transition: all 0.3s ease-in;
                -webkit-transition: all 0.3s ease-in;
                -moz-transition: all 0.3s ease-in;
                -ms-transition: all 0.3s ease-in;
                -o-transition: all 0.3s ease-in;
            }
        }

        .btn-collapse[aria-expanded="true"] {

            .main-shadow {
                transform: rotate(180deg);
                -webkit-transform: rotate(180deg);
                -moz-transform: rotate(180deg);
                -ms-transform: rotate(180deg);
                -o-transform: rotate(180deg);
            }
        }

        .added-collapse {
            max-height: 73vh;
            overflow: auto;
            padding: 0rem 0.6rem;
        }

        .added-product-list {
            max-height: 20rem;
            overflow: hidden auto;
            margin: 1.2rem 0rem 1.6rem 0rem;
            padding: 2rem;
        }
    }


}

@media only screen and (max-width:767px) {

    .category-product-list .category-product .category-product-items,
    .category-product-list .category-product .category-product-items.filterOpen {
        grid-template-columns: 1fr 1fr;
        gap: 1.7rem;
    }

    .product-card .card-img {
        height: 22rem;
        padding: 2rem;
    }


    .product-list-head .main-title {
        font-size: 1.6rem;
        width: 100%;
    }

    .product-list-head .head-filter {
        gap: 2rem;
        width: 100%;
        justify-content: space-between;
    }

    .product-list-content {

        .products {
            grid-template-columns: 1fr;
        }
    }


    .product-list-head {
        flex-wrap: wrap;
        gap: 2rem;

        .head-filter {
            flex-direction: column;
            gap: 1.6rem;

            .sorted {
                width: 100%;
                justify-content: space-between;
            }
        }
    }
}