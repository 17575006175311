section {
  &.inhoused {
    padding-top: 0;
    padding-bottom: 0;

    .max-width-container {
      padding-top: 12.8rem;
      background: radial-gradient(100.59% 92.32% at 2.53% 21.83%, rgba(255, 255, 255, 0.6) 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(65.8% 62.46% at 97.64% 48.25%, #ffddba 0%, rgba(255, 255, 255, 0) 100%), radial-gradient(120.82% 115.83% at 72.19% 74.45%, #eef9fd 0%, #eef9fd 24.36%, rgba(255, 255, 255, 0) 100%), #fff;
      border-radius: 2.4rem;
      -webkit-border-radius: 2.4rem;
      -moz-border-radius: 2.4rem;
      -ms-border-radius: 2.4rem;
      -o-border-radius: 2.4rem;
      position: relative;
      padding-bottom: 12.8rem;

      .container {
        h1 {
          line-height: 110%;
        }

        p {
          color: $color_2;
          max-width: 67.8rem;
          font-size: 1.8rem;
          margin-top: .8rem;
          font-style: normal;
          font-weight: 400;
          line-height: 155.556%;
          letter-spacing: -0.36px;
        }
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: url('../../images/Background-pattern.png');
        z-index: 0;
        background-position: center right;
      }
    }
  }

  &.inhousemids {
    .max-width-container {
      .container {
        .parent {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(7, 1fr);
          grid-column-gap: 0px;
          grid-row-gap: 0px;
          grid-gap: 2.4rem;

          img {
            border-radius: 2.4rem;
            height: 100%;
            width: 100%;
            max-width: 58.8rem;
            max-height: 78.4rem;
          }

          .inner {
            position: relative;

            &.sec-a {
              padding: 5.6rem 4.8rem;
              border-radius: 2.4rem;
              box-shadow: 0px 0px 0px 1px rgba(18, 55, 105, 0.08), 0px 1px 2px 0px rgba(164, 172, 185, 0.24);
              grid-area: 1 / 1 / 4 / 2;

              h2 {
                color: $color_6;
                font-size: 4rem;
                line-height: 110%;
                font-family: $suisse_medium;
                letter-spacing: -0.8px;
                margin-bottom: 3.2rem;
              }
            }

            &.sec-b {
              grid-area: 4 / 1 / 8 / 2;
            }

            &.sec-c {
              grid-area: 1 / 2 / 5 / 3;
            }

            &.sec-d {
              grid-area: 5 / 2 / 8 / 3;
            }

            .cons {
              position: absolute;
              font-size: 2.4rem;
              font-family: $suisse_medium;
              color: $color_6;
              line-height: 133.333%;
              letter-spacing: -0.24px;
              border-radius: 5rem;
              background: $color_5;
              bottom: 4rem;
              left: 4rem;
              padding: 1.6rem 2.4rem 0.8rem;
              max-width: 35.7rem;
              display: flex;
              align-items: center;
              justify-content: center;
              text-align: center;
            }
          }
        }
      }
    }
  }

  &.inhousebtms {
    .max-width-container {
      .container {
        .img-box {
          text-align: center;

          img {
            margin-bottom: 1rem;
          }
        }

        h3 {
          color: $color_11;
          margin: 1.6rem 0;
          text-align: center;
          font-size: 3.2rem;
          line-height: 150%;
          letter-spacing: -0.64px;
        }

        p {
          color: $color_2;
          text-align: center;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 150%;
          letter-spacing: -0.16px;
          max-width: 79.6rem;
          margin: auto;
        }
      }
    }
  }
}


@media only screen and (max-width: 1199px) {
  section {
    &.inhoused {
      .max-width-container {
        padding: 4rem 1.6rem;

        .container {
          h1 {
            font-size: 4rem;
          }

          p {
            max-width: 100%;
          }
        }
      }
    }

    &.inhousemids {
      .max-width-container {
        padding-left: 0;
        padding-right: 0;

        .container {
          .parent {
            .inner {
              &.sec-a {
                padding: 2.4rem;

                h2 {
                  font-size: 3.2rem;
                }
              }

              .cons {
                right: 2.4rem;
                font-size: 2rem;
                left: 2.4rem;
                bottom: 2.4rem;
                max-width: fit-content;
              }
            }
          }
        }
      }
    }
  }

}

@media only screen and (max-width: 767px) {
  section {
    &.inhousemids {
      .max-width-container {
        .container {
          .parent {
            grid-template-columns: 1fr;
            grid-template-rows: none;

            .inner {
              &.sec-a {
                grid-area: unset;
              }

              &.sec-b {
                grid-area: unset;
              }

              &.sec-c {
                grid-area: unset;
              }

              &.sec-d {
                grid-area: unset;
              }

              .cons {
                padding: 1.6rem 1.6rem 1rem;
                left: 1.6rem;
                right: 1.6rem;
                bottom: 1.6rem;
              }
            }
          }
        }
      }
    }
  }

}