@media only screen and (max-width: 1199px) {
  section {
    padding: 4rem 1.6rem;
  }
}

@media only screen and (max-width: 767px) {
  section {
    padding: 2.8rem 1.6rem;
  }
  .mobviewn {
    display: block;
  }
  .desktopviewn {
    display: none;
  }
  .addbutton {
    width: 3rem;
    height: 3rem;
    padding: 0.8rem;
  }
  .btn {
    font-size: 1.4rem;
  }
  h3 {
    font-size: 3.2rem;
    line-height: 4.2rem;
    letter-spacing: -0.64px;
  }
}
